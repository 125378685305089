import React from 'react'

import {
  VStack,
  HStack,
  Button,
  Text,
  IconButton,
  Box,
  Tooltip,
  Tag,
} from '@chakra-ui/react'
import { Flex } from '@chakra-ui/layout'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import * as Yup from 'yup'

import {
  FormikRadioGroup,
  FormikField,
  FormStack,
  ImageContainer,
  ImageUploader,
} from 'src/core/components'
import { useToast } from 'src/core/hooks'

import { questionService } from '../question.service'
import { IQuestion, IQuestionType } from '../question.type'
import {
  FiCheckCircle,
  FiChevronDown,
  FiChevronUp,
  FiEdit2,
  FiEdit3,
  FiPlusCircle,
  FiTrash2,
} from 'react-icons/fi'
import { ISurvey } from 'src/packages/survey/survey.type'
import ChoiceFormList from 'src/packages/choice/components/ChoiceFormList'
import { uploadService } from 'src/packages/upload'
import Alert from 'src/core/components/Alert/Alert'

interface QuestionFormProps {
  type: 'create' | 'update'
  survey: ISurvey
  question?: IQuestion
  index: number
  rowIndex?: number
  onUpdate?: (payload: IQuestion) => void
  onDelete?: (payload: IQuestion) => void

  movingIndex?: boolean
  moveIndex?: (
    payload: IQuestion,
    index: number,
    direction: 'up' | 'down'
  ) => void

  questionCount: number
}

const QuestionForm: React.FC<QuestionFormProps> = ({
  type,
  survey,
  question,
  index,
  rowIndex,
  onUpdate,
  onDelete,
  movingIndex,
  moveIndex,
  questionCount,
}) => {
  const { addToast } = useToast()

  const [editing, setEditing] = React.useState<boolean>(false)
  const toggleEditing = (): void => {
    setEditing(!editing)
  }
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)
  const toggleConfirmDelete = (): void => {
    setConfirmDelete(!confirmDelete)
  }
  const [deleting, setDeleting] = React.useState<boolean>(false)

  const formConfig = {
    initialValues: {
      title: question?.title || '',
      type: question?.type || '',
      parent_id: question?.parent_id || survey?._id,
      index: question?.index || index || 0,
      status: question?.status || 'active',
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('Title field is required.')
        .min(3, 'Title must be at least 3 characters.'),
      type: Yup.mixed().required('Type field is required.'),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)

      const finalValues: any = {
        ...values,
      }

      if (type === 'update') {
        finalValues._id = question?._id
        finalValues.upload_id = question?.upload_id
      } else {
        finalValues.upload_id = '-'
      }

      questionService[type](finalValues)
        .then((questionId) => {
          if (type === 'create') {
            finalValues._id = questionId
          }

          addToast(
            `Question successfully ${
              type === 'create' ? 'created' : 'updated'
            }.`,
            {
              appearance: 'success',
            }
          )
          onUpdate && onUpdate(finalValues)
          if (type === 'update') {
            toggleEditing()
          }
          resetForm()
        })
        .catch((error) => {
          addToast(error.message, { appearance: 'error' })
          throw error
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
    onSubmitImage: (image): Promise<IQuestion> => {
      return new Promise((resolve, reject) => {
        const finalValues: any = {
          _id: question?._id,
          index: question?.index,
          title: question?.title,
          type: question?.type,
          parent_id: question?.parent_id,
          upload_id: question?.upload_id,
          status: question?.status,
        }

        if (type === 'update') {
          finalValues._id = question?._id
        }

        uploadService
          .upload(image, null)
          .then((upload_id) => {
            finalValues.upload_id = upload_id
            questionService
              .update(finalValues)
              .then(() => {
                questionService
                  .fetchById(`${question?._id}`)
                  .then((data) => {
                    onUpdate && onUpdate(data)
                    resolve(data)
                  })
                  .catch((error) => {
                    addToast(error.message, { appearance: 'error' })
                    reject(error)
                    throw error
                  })
              })
              .catch((error) => {
                addToast(error.message, { appearance: 'error' })
                reject(error)
                throw error
              })
          })
          .catch((error) => {
            addToast(error.message, { appearance: 'error' })
            reject(error)
            throw error
          })
      })
    },
  }

  const doDelete = (): void => {
    setDeleting(true)
    questionService
      .delete(`${question?._id}`)
      .then(() => {
        !!question && !!onDelete && onDelete(question)
      })
      .catch((error) => {
        addToast(error.message, { appearance: 'error' })
      })
      .finally(() => {
        setDeleting(false)
      })
  }

  return (
    <>
      {!editing && !question && (
        <Button
          mt={10}
          variant="outline"
          colorScheme="primary"
          justifyContent="left"
          aria-label="Add field"
          leftIcon={<FiPlusCircle />}
          onClick={toggleEditing}
        >
          Add field
        </Button>
      )}

      {!editing && question && (
        <>
          <HStack
            align="start"
            justify="flex-start"
            mt={5}
            opacity={deleting ? 0.4 : 1}
            cursor={deleting ? 'not-allowed' : undefined}
          >
            <VStack>
              {/* edit question */}
              <Tooltip label="Edit Question" size="sm" colorScheme="gray">
                <IconButton
                  size="xs"
                  colorScheme="primary"
                  aria-label="Edit Question"
                  icon={<FiEdit2 />}
                  disabled={deleting}
                  onClick={toggleEditing}
                />
              </Tooltip>
              {/* move up */}
              <Tooltip label="Move Up" size="sm" colorScheme="gray">
                <IconButton
                  size="xs"
                  color="gray.800"
                  aria-label="Move Up"
                  disabled={movingIndex || index < 1 || deleting}
                  icon={<FiChevronUp />}
                  onClick={() =>
                    question && index && moveIndex?.(question, index, 'up')
                  }
                />
              </Tooltip>
              {/* move down */}
              <Tooltip label="Move Down" size="sm" colorScheme="gray">
                <IconButton
                  size="xs"
                  color="gray.800"
                  aria-label="Move Down"
                  disabled={
                    movingIndex || index + 1 === questionCount || deleting
                  }
                  icon={<FiChevronDown />}
                  onClick={() =>
                    question && moveIndex?.(question, index, 'down')
                  }
                />
              </Tooltip>
              {/* delete */}
              <Tooltip label="Delete Question" size="sm" colorScheme="gray">
                <IconButton
                  size="xs"
                  variant="solid"
                  colorScheme="critical"
                  aria-label="Delete Question"
                  disabled={confirmDelete || deleting}
                  icon={<FiTrash2 />}
                  onClick={() => toggleConfirmDelete()}
                />
              </Tooltip>
            </VStack>
            <ImageContainer
              src={question?.upload ? question?.upload.path : null}
              width={190}
              rounded
            />
            <Box>
              <Flex alignItems="center">
                <Text color="primary.600">
                  {question.type === IQuestionType.MultiChoice ? (
                    <FiCheckCircle size={16} />
                  ) : (
                    <FiEdit3 size={16} />
                  )}
                </Text>
                <Text fontSize="14px" fontWeight="semibold" ml={2}>
                  {rowIndex ? rowIndex + '. ' : ''}
                  {question.title}
                  {question.status === 'inactive' && (
                    <Tag ml={2} colorScheme="critical">
                      Disabled
                    </Tag>
                  )}
                </Text>
              </Flex>
              {question.type === IQuestionType.MultiChoice && (
                <ChoiceFormList question={question} />
              )}
            </Box>
          </HStack>
        </>
      )}

      {editing && (
        <Formik
          enableReinitialize={true}
          initialValues={formConfig.initialValues}
          validationSchema={formConfig.validationSchema}
          onSubmit={formConfig.onSubmit}
        >
          {({ handleSubmit, ...formik }) => (
            <Form onSubmit={handleSubmit}>
              <VStack
                spacing={2}
                align="stretch"
                mt={5}
                p={5}
                borderRadius="md"
                bgColor="gray.50"
              >
                {/* Upload */}
                {type === 'update' && (
                  <ImageUploader
                    uploadPath={question?.upload?.path}
                    doUpload={formConfig.onSubmitImage}
                  />
                )}

                {/* Title */}
                <FormStack label="Title" orientation="vertical" isRequired>
                  <FormikField name="title" type="text" />
                </FormStack>

                <HStack align="center">
                  {/* Type */}
                  {type === 'create' && (
                    <FormStack
                      flexGrow={1}
                      label="Type"
                      orientation="vertical"
                      isRequired
                    >
                      <FormikRadioGroup
                        name="type"
                        options={[
                          {
                            value: IQuestionType.SingleEntry,
                            label: 'Single Entry',
                          },
                          {
                            value: IQuestionType.MultiChoice,
                            label: 'Multichoice',
                          },
                        ]}
                      />
                    </FormStack>
                  )}

                  {/* Status */}
                  {type === 'update' && (
                    <FormStack
                      flexGrow={1}
                      label="Status"
                      orientation="vertical"
                      isRequired
                    >
                      <FormikRadioGroup
                        name="status"
                        options={[
                          { value: 'active', label: 'Active' },
                          { value: 'inactive', label: 'Inactive' },
                        ]}
                      />
                    </FormStack>
                  )}
                </HStack>

                <HStack pt={5}>
                  <Button
                    type="button"
                    colorScheme="gray"
                    isDisabled={formik.isSubmitting}
                    onClick={() => {
                      formik.resetForm()
                      toggleEditing()
                    }}
                  >
                    Discard
                  </Button>
                  <Button
                    type="submit"
                    colorScheme="primary"
                    isDisabled={
                      formik.isSubmitting || !formik.dirty || !formik.isValid
                    }
                    isLoading={formik.isSubmitting}
                  >
                    {type === 'create' ? 'Add' : 'Update'}
                  </Button>
                </HStack>
              </VStack>
            </Form>
          )}
        </Formik>
      )}

      <Alert
        isOpen={confirmDelete}
        header="Delete question?"
        description={
          <VStack align="start">
            <Text>You are about to delete the following question</Text>
            <Text fontStyle="italic" fontWeight="semibold" pl={5}>
              {question?.title}
            </Text>
            <Text>This action may affect previous record.</Text>
            <Text fontWeight="semibold">Are you sure you want to delete?</Text>
          </VStack>
        }
        onClose={toggleConfirmDelete}
        buttons={[
          {
            label: 'Not sure',
            props: {
              size: 'sm',
            },
            isClose: true,
          },
          {
            label: 'Yes, delete',
            props: {
              size: 'sm',
              colorScheme: 'critical',
              onClick: () => {
                toggleConfirmDelete()
                doDelete()
              },
              autoFocus: false,
            },
          },
        ]}
      />
    </>
  )
}

QuestionForm.defaultProps = {}

export default QuestionForm
