import React from 'react'
import { SET_USER, UNSET_USER } from '../actionTypes'
import { IUser as IStoreState } from 'src/packages/user/types'

interface IAction {
  type: string
  payload: IStoreState
}

const initialState: IStoreState = {}

export const userReducer: React.Reducer<IStoreState, IAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.payload }
    case UNSET_USER:
      return { ...action.payload }
    default:
      return state
  }
}
