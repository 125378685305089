import { http, secureRandomString } from 'src/core/helpers'
import { CoreService } from 'src/core/services'
import pluralize from 'pluralize'
import { IUpload } from './upload.type'

export class UploadService extends CoreService<IUpload> {
  constructor() {
    super('upload')
  }
  fetchHttpBase(): Promise<string> {
    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_http_base`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          http.get(`${this.model}/http_base`).then(({ data }) => {
            if (data.code === 200) {
              this.cache.set(cacheKey, data.message)
              resolve(data.message)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  upload(uploadData, fileName): Promise<string> {
    fileName = fileName ? secureRandomString() : secureRandomString()

    const formData = new FormData()
    formData.append('file', uploadData.file)
    formData.append('tag', fileName)
    formData.append('title', fileName)
    formData.append('overwrite', 'true')

    return new Promise((resolve, reject) => {
      try {
        http.post(`${this.model}/upload`, formData).then(({ data }) => {
          if (data.code === 200) resolve(data.data)
          // upload ID
          else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default UploadService
export const uploadService = new UploadService()
export type UploadServiceProps = keyof UploadService
