import React from 'react'

import { Flex, Button, Input } from '@chakra-ui/react'
import { FiMinus, FiPlus } from 'react-icons/fi'

interface QuantityInputProps {
  size?: string
  value: number
  mpu: number
  onChange: (value: number) => void
}

export const QuantityInput: React.FC<QuantityInputProps> = ({
  size,
  value,
  mpu,
  onChange,
}) => {
  const increment = React.useCallback(
    (event) => {
      event.preventDefault()
      const newValue = value + mpu
      onChange(newValue)
    },
    [mpu, value, onChange]
  )

  const decrement = React.useCallback(
    (event) => {
      event.preventDefault()
      if (value !== mpu && value > mpu) {
        const newValue = value - mpu
        onChange(newValue)
      }
    },
    [mpu, value, onChange]
  )

  return (
    <Flex>
      <Button
        size="sm"
        variant="outline"
        borderRadius="6px 0 0 6px"
        mr="-1px"
        onClick={decrement}
        isDisabled={value === mpu || value < mpu}
      >
        <FiMinus size={size === 'sm' ? 18 : 20} />
      </Button>
      <Input
        size="sm"
        name="quantity"
        borderRadius={0}
        value={value || ''}
        width={size === 'sm' ? '6ch' : '8ch'}
        textAlign="center"
        background="#fff"
        fontWeight="bold"
        px="3px"
        readOnly
      />
      <Button
        size="sm"
        variant="outline"
        borderRadius="0 6px 6px 0"
        ml="-1px"
        onClick={increment}
      >
        <FiPlus size={size === 'sm' ? 18 : 20} />
      </Button>
    </Flex>
  )
}

QuantityInput.defaultProps = {
  size: 'sm',
}

export default QuantityInput
