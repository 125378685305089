import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useToast from './useToast'
import { StoreState } from 'src/bootstrap/store/types'
import { SET_CONFIG } from 'src/bootstrap/store/actionTypes'

const useShortcuts = (): void => {
  const dispatch = useDispatch()
  const { env, appVersion, sidebarOpen } = useSelector((state: StoreState) => ({
    env: state.global.env,
    appVersion: state.global.version,
    sidebarOpen: state.config.sidebarOpen,
  }))

  const { toast } = useToast()

  /**
   * ctrl + b = toggle sidebar
   */
  React.useEffect(() => {
    const handleKeydown = (event: KeyboardEvent): void => {
      if (event.metaKey && event.key === 'b') {
        dispatch({
          type: SET_CONFIG,
          payload: { sidebarOpen: !sidebarOpen },
        })
      }
    }

    document.addEventListener('keydown', handleKeydown, true)
    return () => {
      document.removeEventListener('keydown', handleKeydown, true)
    }
  })

  /**
   * ctrl + i = show version info
   */
  React.useEffect(() => {
    const handleKeydown = (event: KeyboardEvent): void => {
      if (event.metaKey && event.key === 'i') {
        toast({
          description: `DMS Dashboard ${env || ''} v${appVersion}`,
          status: 'info',
          isClosable: true,
        })
      }
    }

    document.addEventListener('keydown', handleKeydown, true)
    return () => {
      document.removeEventListener('keydown', handleKeydown, true)
    }
  })
}

export default useShortcuts
