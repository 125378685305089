import {
  Box,
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React from 'react'
import { FiShare2 } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { IStoreState } from 'src/bootstrap/store/types'
import { FormikField, FormStack } from 'src/core/components'
import { useToast } from 'src/core/hooks'
import { ISurvey } from 'src/packages/survey/survey.type'
import * as Yup from 'yup'
import { inviteService } from '../invite.service'

interface IProps extends ButtonProps {
  title?: string
  survey: ISurvey
}

const InviteButton: React.FC<IProps> = ({ title, survey, ...props }) => {
  const user = useSelector((state: IStoreState) => state.user)
  const { addToast } = useToast()
  const [visible, setVisible] = React.useState<boolean>(false)
  const toggleVisible = (): any => {
    setVisible(!visible)
  }

  const formConfig = {
    initialValues: {
      invitee: '',
    },
    validationSchema: Yup.object({
      invitee: Yup.string()
        .required('Invitee field is required.')
        .min(8, 'Invitee must be at least 8 characters.'),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)

      const payload: any = {
        ...values,
        type: 'email',
        survey_id: survey._id,
        profile_id: user._id,
      }

      inviteService
        .sendInvite(payload)
        .then(() => {
          addToast('Invitation sent to invitee(s).', { appearance: 'success' })
          resetForm()
          toggleVisible()
        })
        .catch((error) => {
          addToast(error.message, { appearance: 'error' })
          throw error
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  }

  return (
    <>
      <Modal isOpen={visible} onClose={toggleVisible}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Share Project: {survey.title}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Formik
              enableReinitialize={true}
              initialValues={formConfig.initialValues}
              validationSchema={formConfig.validationSchema}
              onSubmit={formConfig.onSubmit}
            >
              {({ handleSubmit, ...formik }) => (
                <Form onSubmit={handleSubmit}>
                  <VStack
                    divider={<StackDivider borderColor="gray.100" />}
                    spacing={5}
                    align="stretch"
                  >
                    <VStack align="start">
                      <Box>
                        <Text fontSize="sm" color="gray.600">
                          Project Title
                        </Text>
                        <Text fontSize="lg">{survey.title}</Text>
                      </Box>
                      <Box>
                        <Text fontSize="sm" color="gray.600">
                          Project Description
                        </Text>
                        <Text fontSize="lg">{survey.description}</Text>
                      </Box>
                      <Box>
                        <Text fontSize="sm" color="gray.600">
                          Created by
                        </Text>
                        <Text fontSize="lg">{survey.profile?.name}</Text>
                      </Box>
                    </VStack>

                    {/* Invitees */}
                    <FormStack
                      label="Invitees Email (CSV - comma separated values format)"
                      orientation="vertical"
                      isRequired
                    >
                      <FormikField name="invitee" type="textarea" />
                    </FormStack>
                  </VStack>

                  <Button
                    type="submit"
                    colorScheme="primary"
                    mt={5}
                    isDisabled={
                      formik.isSubmitting || !formik.dirty || !formik.isValid
                    }
                    isLoading={formik.isSubmitting}
                  >
                    Share Project
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Button
        size="md"
        leftIcon={<FiShare2 />}
        onClick={toggleVisible}
        {...props}
      >
        {title}
      </Button>
    </>
  )
}

InviteButton.defaultProps = {
  title: 'Share',
}

export default InviteButton
