import React from 'react'

import { Container, Flex, Box, Heading, Text, Image } from '@chakra-ui/react'

import NoCloudIcon from 'src/core/assets/images/icons/no-cloud.svg'
import EmptyFolderIcon from 'src/core/assets/images/icons/empty-folder-gray.svg'
import SecurityIcon from 'src/core/assets/images/icons/unauthorized.svg'

interface ErrorBoxProps {
  /**
   * Network error, react suspend error or authorization error
   * from permission check
   */
  type?: 'network' | 'suspense' | 'authorization'

  icon?: string

  title?: string

  summary?: string

  /**
   * Error reference. Usually the permission string
   */
  reference?: string

  /**
   * Vertical margin shortcut, so you don't need
   * to wrap component in a <Box /> or <Flex />
   */
  my?: number
}
/**
 * Display error message with icon
 *
 */
export const ErrorBox: React.FC<ErrorBoxProps> = ({ type, my, ...props }) => {
  let content = type ? defaults[type] : defaults.default

  content = {
    ...content,
    ...props, // override default content with props
  }

  return (
    <Container>
      <Flex justifyContent="center" my={my || 0}>
        <Box maxW="500px" textAlign="center">
          <Image src={content.icon} margin="0 auto" />

          {!!content.title && (
            <Heading
              as="h6"
              fontSize="xl"
              fontWeight="medium"
              mt={3}
              mb={1}
              data-testid="title"
            >
              {content.title}
            </Heading>
          )}

          <Text
            as="p"
            color="gray.600"
            mt={!content.title ? 3 : 0}
            data-testid="summary"
          >
            {content.summary}
          </Text>

          {!!props.reference && (
            <Text as="code" color="gray.500" fontSize="sm">
              Ref: {props.reference}
            </Text>
          )}
        </Box>
      </Flex>
    </Container>
  )
}

type Content = { icon?: string; title?: string; summary?: string }
const defaults: Record<string, Content> = {
  network: {
    icon: NoCloudIcon,
    title: `You're offline`,
    summary: `Seems like there's something wrong with your internet connection. Please reconnect and try again.`,
  },
  suspense: {
    icon: NoCloudIcon,
    title: `Ah! Something has gone wrong.`,
    summary: `If refreshing your browser doesn't fix the problem, kindly contact the tech team.`,
  },
  authorization: {
    icon: SecurityIcon,
    title: `Unauthorized Access!`,
    summary: `You don't have the permission to access this page. That's all we know.`,
  },
  default: {
    icon: EmptyFolderIcon,
  },
}

export default ErrorBox
