import React from 'react'

import { Box } from '@chakra-ui/react'
import { Layout } from 'src/core/components'
import TreeForm from '../components/TreeForm'
import { useHistory } from 'react-router'
import { route } from 'src/core/helpers'

const LocationCreate: React.FC = () => {
  const history = useHistory()
  return (
    <Layout
      header={{
        title: 'New Location',
        backText: 'Locations',
        onBack: () => history.push(route('location')),
      }}
      // permission="create_tree"
    >
      <Box maxW="sm" marginX="auto">
        <TreeForm type="create" />
      </Box>
    </Layout>
  )
}

export default LocationCreate
