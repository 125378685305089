import React from 'react'

import {
  Text,
  TextProps,
  Button,
  Box,
  Flex,
  ButtonProps,
} from '@chakra-ui/react'
import { Menu, MenuButton, MenuList } from '@chakra-ui/menu'
import { FiSliders } from 'react-icons/fi'

const FilterMenuLabel: React.FC<TextProps> = ({ children, ...props }) => {
  return (
    <Text as="label" fontSize="md" fontWeight="medium" mb={1} {...props}>
      {children}
    </Text>
  )
}

const FilterMenuSection: React.FC<{ label: string }> = ({
  children,
  label,
}) => {
  return (
    <Box mb={15}>
      <FilterMenuLabel>{label}</FilterMenuLabel>
      {children}
    </Box>
  )
}

interface FilterMenuProps {
  /**
   * Styles for menu list
   */
  menuListStyle?: any
  /**
   * Dropdown button props
   */
  toggleButtonProps?: any
  /**
   * Dropdown button inner content
   */
  toggleButtonContent?: any
  /**
   * Reset filters button props
   */
  resetButtonProps?: ButtonProps
  /**
   * Refresh filters button props
   */
  refreshButtonProps?: ButtonProps
}
/**
 * Renders dropdown menu for table filters.
 */
export class FilterMenu extends React.Component<FilterMenuProps, any> {
  static Label = FilterMenuLabel
  static Section = FilterMenuSection

  render(): JSX.Element {
    return (
      <Menu>
        <MenuButton
          as={Button}
          size="sm"
          variant="outline"
          leftIcon={<FiSliders size={16} />}
          textTransform="capitalize"
          _expanded={{ bg: 'primary.50' }}
          {...this.props.toggleButtonProps}
        >
          {this.props?.toggleButtonContent || 'Add filters'}
        </MenuButton>

        <MenuList {...this.props.menuListStyle} zIndex={90}>
          <Box my="15px" mx="20px" w="260px">
            {this.props?.children}

            <Flex mt={30} justifyContent="space-between">
              {this.props?.resetButtonProps && (
                <Button
                  size="sm"
                  variant="outline"
                  title="Reset Filters"
                  {...this.props.resetButtonProps}
                >
                  Reset
                </Button>
              )}

              {this.props?.refreshButtonProps && (
                <Button
                  size="sm"
                  variant="solid"
                  colorScheme="primary"
                  loadingText="Refreshing"
                  {...this.props.refreshButtonProps}
                >
                  Refresh
                </Button>
              )}
            </Flex>
          </Box>
        </MenuList>
      </Menu>
    )
  }
}

export default FilterMenu
