import { IRoute } from 'src/bootstrap/routes'
import DashboardMembership from './pages/DashboardMembership.page'

export const dashboardRoutes: IRoute[] = [
  {
    id: 'dashboard_membership',
    path: '/membership',
    requiresAuth: true,
    component: DashboardMembership,
    exact: true,
  },
]

export default dashboardRoutes
