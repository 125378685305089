import React from 'react'
import { refactorObjects } from 'src/core/helpers/filters.helper'

export const useRefactorObjects = <T = any, S = any>(
  data: T[],
  pairs?: [string, string][]
): S[] => {
  const options = React.useMemo(() => {
    return data?.length ? refactorObjects(data, pairs) : []
  }, [data, pairs])

  return options
}

export default useRefactorObjects
