import React from 'react'

import {
  Box,
  Text,
  useTheme,
  Flex,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Divider,
} from '@chakra-ui/react'
import { IUser } from 'src/packages/user'
import { FiToggleLeft, FiUser } from 'react-icons/fi'
import { route, trans } from 'src/core/helpers'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { doLogout } from 'src/bootstrap/store/auth/authActions'

interface SidebarHeaderProps {
  user?: IUser
  sidebarOpen?: boolean
  toggleSidebar?: VoidFunction
}

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({ user }) => {
  const { colors } = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <Box px="15px" bg={colors.primary['500']}>
      <Flex
        height="layout.headerHeight"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text color="white" fontSize="xl">
          Data Manager
        </Text>
        <Menu>
          <MenuButton
            as={IconButton}
            bgColor="transparent"
            variant="outline"
            colorScheme="white"
            color="whiteAlpha.900"
            aria-label="Profile"
            fontSize="20px"
            icon={<FiUser />}
            border="none"
          ></MenuButton>
          <MenuList>
            <MenuItem minH="48px" _hover={{ bg: 'white' }}>
              <Box w="100%">
                <Text fontWeight="medium" isTruncated>
                  {user?.name}
                </Text>
                <Text color="gray.600" fontSize="sm" isTruncated>
                  {user?.email}
                </Text>
                <Text color="gray.600" fontSize="sm" isTruncated>
                  {trans(`general.${user?.role}`)}
                </Text>
                <Divider color="gray.200" mt={3} />
              </Box>
            </MenuItem>
            <MenuItem
              minH="40px"
              icon={<FiUser size={16} />}
              onClick={() => {
                history.push(route('profile'))
              }}
            >
              My Profile
            </MenuItem>
            <MenuItem
              minH="40px"
              icon={<FiToggleLeft size={16} />}
              color="critical.600"
              onClick={() => dispatch(doLogout())}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  )
}

export default SidebarHeader
