import LRU from 'lru-cache'

const cache = new LRU({
  maxAge: 300000, // maxAge is in ms
  max: 500000000000,
  length: (n) => {
    return n.length * 100 // n = item passed in to be saved (value)
  },
})

export const set = (key: string, value: any): any => cache.set(key, value)
export const get = (key: string): any => cache.get(key)
export const del = (key: string): any => cache.del(key) // clear key from cache
export const reset = (): any => cache.reset() // clears entire cache

export default {
  get,
  set,
  del,
  reset,
}
