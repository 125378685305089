import { IRoute } from 'src/bootstrap/routes'
import BranchCreate from './pages/BranchCreate.page'
import BranchUpdate from './pages/BranchUpdate.page'

export const branchRoutes: IRoute[] = [
  {
    id: 'branch_create',
    path: '/branch/create',
    requiresAuth: true,
    component: BranchCreate,
    exact: true,
  },
  {
    id: 'branch_update',
    path: '/branch/:id',
    requiresAuth: true,
    component: BranchUpdate,
    exact: true,
  },
]

export default branchRoutes
