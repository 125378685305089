import React from 'react'

import { basicSearch } from 'src/core/helpers/search.helper'
import { IAnswerProfileTable } from '..'
import { IAnswer } from '../answer.type'

export const useFilterAnswers = (answers: IAnswer[]): any => {
  const [filters, setFilters] = React.useState<any>({
    status: '*',
    page: 1,
    per_page: 10,
    search: '',
  })
  const [activeFilters, setActiveFilters] = React.useState<string[]>()

  const filteredAnswers = React.useMemo(() => {
    let filtered = answers || []
    const active: string[] = []

    if (filtered.length) {
      if (filters.search) {
        filtered = filtered.filter((answer) => {
          return basicSearch(answer.title as any, filters.search)
        })
      }
    }

    setActiveFilters(active)
    return filtered
  }, [answers, filters.search])

  return {
    filters,
    setFilters: (newFilters) => {
      setFilters({ ...filters, ...newFilters, page: 1 })
    },
    setPage: (page) => setFilters({ ...filters, page }),
    filteredAnswers,
    activeFilters,
  }
}

export const useFilterProfileAnswers = (
  answerTableData: IAnswerProfileTable
): any => {
  const [filters, setFilters] = React.useState<any>({
    status: '*',
    page: 1,
    per_page: 10,
    search: '',
  })
  const [activeFilters, setActiveFilters] = React.useState<string[]>()

  const filteredAnswers = React.useMemo<Record<string, string>[]>(() => {
    let filtered = answerTableData.body || []
    const active: string[] = []

    if (filtered.length) {
      if (filters.search) {
        filtered = filtered.filter((answer) => {
          return answerTableData.header.find((key) =>
            basicSearch(answer[key], filters.search)
          )
        })
      }
    }

    setActiveFilters(active)
    return filtered
  }, [answerTableData, filters.search])

  return {
    filters,
    setFilters: (newFilters) => {
      setFilters({ ...filters, ...newFilters, page: 1 })
    },
    setPage: (page) => setFilters({ ...filters, page }),
    filteredAnswers,
    activeFilters,
  }
}
