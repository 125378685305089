import React from 'react'

import { Box, Flex, Spinner } from '@chakra-ui/react'
import { ErrorBox, Layout } from 'src/core/components'
import TreeForm from '../components/TreeForm'
import { useMounted } from 'src/core/hooks'
import { ITree } from '../tree.type'
import { treeService } from '../tree.service'
import { useHistory, useParams } from 'react-router'
import { route } from 'src/core/helpers'

const LocationUpdate: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const { id: param_id }: any = useParams()

  const [isLoading, setLoading] = React.useState<boolean>(false)
  const [tree, setTree] = React.useState<ITree>()
  const [error, setError] = React.useState<any>()

  React.useEffect(() => {
    if (param_id) {
      setLoading(true)
      setError(undefined)
      treeService
        .fetchById(param_id)
        .then((data) => {
          isMounted.current && setTree(data)
        })
        .catch((error) => {
          isMounted.current && setError(error)
        })
        .finally(() => {
          isMounted.current && setLoading(false)
        })
    } else {
      setError(undefined)
      setLoading(false)
    }
  }, [param_id, isMounted])

  return (
    <Layout
      header={{
        title: `Update Location`,
        backText: 'Locations',
        onBack: () => history.push(route('location')),
      }}
      // permission="update_tree"
    >
      {isLoading ? (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      ) : tree ? (
        <Box maxW="sm" marginX="auto">
          <TreeForm type="update" tree={tree} />
        </Box>
      ) : error ? (
        <ErrorBox summary={`${error?.message}`} />
      ) : (
        <ErrorBox summary={`Location not found.`} />
      )}
    </Layout>
  )
}

export default LocationUpdate
