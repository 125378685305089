import React from 'react'
import {
  Tr as ChakraTr,
  TableRowProps as ChakraTrProps,
} from '@chakra-ui/table'
import { useTheme } from '@chakra-ui/react'

interface TrProps extends ChakraTrProps {
  isActive?: boolean
}
export const Tr: React.FC<TrProps> = ({ children, isActive, ...props }) => {
  const { colors } = useTheme()

  return (
    <ChakraTr
      css={{
        td: {
          background: isActive ? colors.primary[50] : colors.white,
        },
        '&:hover td': {
          background: colors.gray[50],
        },
      }}
      {...props}
    >
      {children}
    </ChakraTr>
  )
}
