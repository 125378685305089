import React from 'react'
import { Box, BoxProps } from '@chakra-ui/layout'
import {
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
} from '@chakra-ui/system'

interface CardProps extends BoxProps {
  [value: string]: any
}
export const Card: React.FC<CardProps> = (props) => {
  const { children, ...rest } = props

  const styles = useMultiStyleConfig('Card', {})
  // Pass the computed styles into the `__css` prop
  return (
    <Box __css={styles.card} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  )
}

export const CardHeader: React.FC<BoxProps> = (props) => {
  const styles = useStyles()
  return <Box __css={styles.header} {...props} />
}

export const CardBody: React.FC<BoxProps> = (props) => {
  const styles = useStyles()
  return <Box __css={styles.body} {...props} />
}

export const CardFooter: React.FC<BoxProps> = (props) => {
  const styles = useStyles()
  return <Box __css={styles.footer} {...props} />
}
