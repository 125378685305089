import React from 'react'
import { Helmet } from 'react-helmet'
import { Tabs, TabList, Tab } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { IStoreState } from 'src/bootstrap/store/types'
import { useHistory } from 'react-router'
import { route } from 'src/core/helpers'

export const Navbar: React.FC = () => {
  const history = useHistory()

  const { auth } = useSelector((state: IStoreState) => state)
  const isAuthenticated = !!auth.token

  const tabIndices = React.useMemo(() => {
    return [
      { id: 'survey', path: '', label: 'Projects' },
      { id: 'location', path: 'locations', label: 'All Locations' },
      { id: 'dashboard_membership', path: 'membership', label: 'My Locations' },
    ]
  }, [])
  const [tabIndex, setTabIndex] = React.useState(-1)
  const currentTab = React.useMemo(() => {
    return tabIndices[tabIndex]
  }, [tabIndex, tabIndices])

  const handleTabChange = (index): void => {
    const goto = route(tabIndices[index].id)
    if (goto !== 'undefined') {
      history.push(goto)
    } else {
      history.push('/error_404')
    }
  }

  React.useEffect(() => {
    const evaluatePath = (): void => {
      const currentPath = location?.pathname.split('/')[1]
      const pathIndex = tabIndices.findIndex((tab) => tab.path === currentPath)
      if (pathIndex > -1) {
        setTabIndex(pathIndex)
      } else {
        setTabIndex(-1)
      }
    }

    evaluatePath()
    const stopListening = history.listen(evaluatePath)

    return () => {
      stopListening()
    }
  }, [history, tabIndex, tabIndices])

  return (
    <>
      {isAuthenticated && (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {currentTab?.label ? currentTab?.label : 'Home'} - DMS Dashboard
            </title>
          </Helmet>
          <Tabs index={tabIndex} onChange={handleTabChange}>
            <TabList
              height="layout.headerHeight"
              bg="white"
              px={5}
              borderBottom="1px solid"
              borderColor="gray.50"
            >
              {tabIndices.map((tab, index) => (
                <Tab key={index} fontSize="lg" fontWeight="semibold">
                  {tab.label}
                </Tab>
              ))}
            </TabList>
          </Tabs>
        </>
      )}
    </>
  )
}

export default Navbar
