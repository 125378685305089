import React from 'react'

import { VStack, HStack, Button, Text, IconButton, Tag } from '@chakra-ui/react'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import * as Yup from 'yup'

import { FormikRadioGroup, FormikField, FormStack } from 'src/core/components'
import { useToast } from 'src/core/hooks'

import { choiceService } from '../choice.service'
import { IChoice } from '../choice.type'
import { FiEdit2, FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { IQuestion } from 'src/packages/question'
import Alert from 'src/core/components/Alert/Alert'

interface ChoiceFormProps {
  type: 'create' | 'update'
  question: IQuestion
  choice?: IChoice
  index?: number
  rowIndex?: number
  onUpdate?: (payload: IChoice) => void
  onDelete?: (payload: IChoice) => void
}

const ChoiceForm: React.FC<ChoiceFormProps> = ({
  type,
  choice,
  question,
  index,
  onUpdate,
  onDelete,
}) => {
  const { addToast } = useToast()

  const [editing, setEditing] = React.useState<boolean>(false)
  const toggleEditing = (): void => {
    setEditing(!editing)
  }
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)
  const toggleConfirmDelete = (): void => {
    setConfirmDelete(!confirmDelete)
  }
  const [deleting, setDeleting] = React.useState<boolean>(false)

  const doDelete = (): void => {
    setDeleting(true)
    choiceService
      .delete(`${choice?._id}`)
      .then(() => {
        choice && onDelete && onDelete(choice)
      })
      .catch((error) => {
        addToast(error.message, { appearance: 'error' })
      })
      .finally(() => {
        setDeleting(false)
      })
  }

  const formConfig = {
    initialValues: {
      title: choice?.title || '',
      question_id: choice?.question_id || question?._id,
      index: choice?.index || index || 0,
      status: choice?.status || 'active',
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('Title field is required.')
        .min(1, 'Title must be at least 1 characters.'),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)

      const finalValues: any = {
        ...values,
      }

      if (type === 'update') {
        finalValues._id = choice?._id
      }

      choiceService[type](finalValues)
        .then((choiceId) => {
          if (type === 'create') {
            finalValues._id = choiceId
          }

          addToast(
            `Choice successfully ${type === 'create' ? 'created' : 'updated'}.`,
            {
              appearance: 'success',
            }
          )
          toggleEditing()
          onUpdate && onUpdate(finalValues)
          resetForm()
        })
        .catch((error) => {
          addToast(error.message, { appearance: 'error' })
          throw error
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  }

  return (
    <>
      {!editing && !choice && (
        <Button
          aria-label="Add field"
          leftIcon={<FiPlusCircle />}
          variant="outline"
          color="gray.600"
          my={4}
          size="xs"
          justifyContent="left"
          onClick={toggleEditing}
        >
          Add choice
        </Button>
      )}

      {!editing && choice && (
        <HStack align="center" justify="flex-start" mt={4}>
          <IconButton
            icon={<FiEdit2 />}
            size="xs"
            color="gray.800"
            aria-label="Edit Question"
            onClick={toggleEditing}
            disabled={deleting}
          />
          <IconButton
            icon={<FiTrash2 />}
            size="xs"
            color="critical.600"
            aria-label="Delete choice"
            onClick={toggleConfirmDelete}
            disabled={deleting}
          />
          <Text>
            {choice.title}
            {choice.status === 'inactive' && (
              <Tag ml={2} colorScheme="critical" title="Disabled">
                Disabled
              </Tag>
            )}
          </Text>
        </HStack>
      )}

      {editing && (
        <Formik
          enableReinitialize={true}
          initialValues={formConfig.initialValues}
          validationSchema={formConfig.validationSchema}
          onSubmit={formConfig.onSubmit}
        >
          {({ handleSubmit, ...formik }) => (
            <Form onSubmit={handleSubmit}>
              <VStack
                spacing={2}
                align="stretch"
                mt={4}
                p={5}
                borderRadius="md"
                bgColor="gray.50"
              >
                {/* Title */}
                <FormStack label="Title" orientation="vertical" isRequired>
                  <FormikField name="title" type="text" />
                </FormStack>

                {/* Status */}
                {type === 'update' && (
                  <FormStack label="Status" orientation="vertical" isRequired>
                    <FormikRadioGroup
                      name="status"
                      options={[
                        { value: 'active', label: 'Active' },
                        { value: 'inactive', label: 'Inactive' },
                      ]}
                    />
                  </FormStack>
                )}

                <HStack pt={5}>
                  <Button
                    size="xs"
                    type="button"
                    colorScheme="gray"
                    isDisabled={formik.isSubmitting}
                    onClick={() => {
                      formik.resetForm()
                      toggleEditing()
                    }}
                  >
                    Discard
                  </Button>
                  <Button
                    size="xs"
                    type="submit"
                    colorScheme="primary"
                    isDisabled={
                      formik.isSubmitting || !formik.dirty || !formik.isValid
                    }
                    isLoading={formik.isSubmitting}
                  >
                    {type === 'create' ? 'Add' : 'Update'}
                  </Button>
                </HStack>
              </VStack>
            </Form>
          )}
        </Formik>
      )}

      <Alert
        isOpen={confirmDelete}
        header="Delete choice?"
        description={
          <VStack align="start">
            <Text>You are about to delete:</Text>
            <Text fontStyle="italic" fontWeight="semibold" pl={5}>
              {choice?.title}
            </Text>
            <Text>This action may affect previous record.</Text>
            <Text fontWeight="semibold">Are you sure you want to delete?</Text>
          </VStack>
        }
        onClose={toggleConfirmDelete}
        buttons={[
          {
            label: 'Not sure',
            props: {
              size: 'sm',
            },
            isClose: true,
          },
          {
            label: 'Yes, delete',
            props: {
              size: 'sm',
              colorScheme: 'critical',
              onClick: () => {
                toggleConfirmDelete()
                doDelete()
              },
              autoFocus: false,
            },
          },
        ]}
      />
    </>
  )
}

ChoiceForm.defaultProps = {}

export default ChoiceForm
