import React from 'react'
import { SET_SCORE_BOARD } from '../actionTypes'

interface IStoreState {
  wins: number
  losses: number
  draws: number
}

interface IAction {
  type: string
  payload: IStoreState
}

const initialState: IStoreState = {
  wins: 0,
  losses: 0,
  draws: 0,
}

export const scoreBoardReducer: React.Reducer<IStoreState, IAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_SCORE_BOARD:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
