import React from 'react'

import cn from 'classnames'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { ErrorBox, Sidebar } from 'src/core/components'
import useAuth from 'src/core/hooks/useAuth'
import useOnline from 'src/core/hooks/useOnline'
import useShortcuts from 'src/core/hooks/useShortcuts'
import { IStoreState } from 'src/bootstrap/store/types'
import 'src/core/assets/sass/app.scss'

import RouteSwitcher, { SuspenseLoader } from './RouteSwitcher'
import { IRoute } from './routes'

interface AppProps {
  routes: IRoute[]
}
const App: React.FC<AppProps> = (props) => {
  useShortcuts()

  const isOnline = useOnline()
  useAuth(isOnline)

  const { isLoading, isAuthenticated, sidebarOpen } = useSelector(
    (state: IStoreState) => {
      return {
        isLoading: state.global.isLoading,
        isAuthenticated: !!state.auth.token,
        sidebarOpen: state.config.sidebarOpen,
      }
    }
  )

  const mainClasses = cn({
    'page-wrapper': true,
    'with-mini-sidebar': isAuthenticated && !sidebarOpen,
    'with-sidebar': isAuthenticated && sidebarOpen,
  })

  return (
    <BrowserRouter>
      <div className="app">
        {!isOnline ? (
          <ErrorBox type="network" my={150} />
        ) : (
          <>
            <Sidebar />
            <main className={mainClasses}>
              {isLoading ? (
                <SuspenseLoader />
              ) : (
                <RouteSwitcher routes={props.routes} />
              )}
            </main>
          </>
        )}
      </div>
    </BrowserRouter>
  )
}

export default App
