import { coreLang } from 'src/core/lang'

const lang = {
  core: coreLang,
}

export const trans = (string: string): string | null => {
  if (!string) return null

  const parts = string.split('.')

  if (parts.length === 2) {
    return lang?.[parts[0]]?.[parts[1]] || null
  } else {
    return null
  }
}
