import React from 'react'

import { Badge, Button } from '@chakra-ui/react'

import { ErrorBox, Pagination } from 'src/core/components'
import { Table, Thead, Tbody, Tr, Td, Th } from 'src/core/components/Table'
import { paginate, timestampToDate, timestampToTime } from 'src/core/helpers'

import { IInvite } from '../invite.type'

interface InvitesTableProps {
  invites: IInvite[]
  filters: any
  setFilters: (filters: any) => void
  setPage: (page: number) => void
}

export const InvitesTable: React.FC<InvitesTableProps> = ({
  invites,
  ...props
}) => {
  const { page, per_page: perPage } = props.filters
  const { setPage } = props

  const paginatedInvites = React.useMemo(() => {
    return invites ? paginate(invites, page, perPage) : []
  }, [page, perPage, invites])

  return (
    <>
      {!paginatedInvites.length ? (
        <ErrorBox summary="No invitation found" my={50} />
      ) : null}

      {paginatedInvites?.length ? (
        <>
          <Table isFit mt={2}>
            <InvitesThead />
            <Tbody>
              {paginatedInvites.map((invite, index) => (
                <InvitesTr
                  key={invite._id}
                  invite={invite}
                  index={index}
                  page={page}
                  perPage={perPage}
                />
              ))}
            </Tbody>
          </Table>

          <Pagination
            my={5}
            itemsPerPage={10}
            totalItems={invites.length}
            currentPage={page}
            onPageChange={setPage}
            justifyContent="center"
          />
        </>
      ) : null}
    </>
  )
}

const InvitesThead: React.FC = () => {
  return (
    <Thead>
      <Tr>
        <Th textAlign="center">#</Th>
        <Th>Invited</Th>
        <Th>Date</Th>
        <Th>Status</Th>
        <Th></Th>
      </Tr>
    </Thead>
  )
}

interface InvitesTrProps {
  invite: IInvite
  index: number
  page: number
  perPage: number
}
const InvitesTr: React.FC<InvitesTrProps> = ({ invite, ...props }) => {
  const openInvite = (invite: IInvite): void => {
    console.log(invite)
  }

  return (
    <Tr key={invite._id}>
      <Td textAlign="center">
        {props.index + 1 + (props.page - 1) * props.perPage}
      </Td>
      <Td>{invite.invitee}</Td>
      <Td>
        {timestampToDate(invite.created)}
        {` - `}
        {timestampToTime(invite.created)}
      </Td>
      <Td>
        <Badge
          colorScheme={invite.status === 'accepted' ? 'success' : 'warning'}
        >
          {invite.status}
        </Badge>
      </Td>
      <Td>
        <Button
          size="sm"
          variant="outline"
          onClick={() => openInvite(invite)}
          disabled={true}
        >
          Re-invite
        </Button>
      </Td>
    </Tr>
  )
}
