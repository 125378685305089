import { Role, dms_admin, dms_default, everyone } from './roles'

export interface IPermission {
  /** Unique permission identifier */
  id: string
  /** Roles tied to permission */
  roles: Role[]
}

export const permissions: IPermission[] = [
  // Config
  { id: 'update_config', roles: [dms_admin] },

  // Dashboard
  { id: 'view_dashboard_charts', roles: [dms_admin] },

  // Trees
  { id: 'view_trees', roles: [dms_admin] },
  { id: 'create_tree', roles: [dms_admin] },
  { id: 'update_tree', roles: [dms_admin] },

  // Branches
  { id: 'view_branches', roles: [dms_admin] },
  { id: 'create_branch', roles: [dms_admin] },
  { id: 'update_branch', roles: [dms_admin] },

  // Surveys
  { id: 'create_survey', roles: [dms_admin] },
  { id: 'update_survey', roles: [dms_admin] },

  // Notifications
  { id: 'send_notification', roles: [dms_admin] },

  // Reports
  { id: 'view_reports', roles: [dms_admin] },

  { id: 'default_action', roles: [dms_default] },
  { id: 'everyone_can', roles: [...everyone] },
]
