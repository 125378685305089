import React from 'react'

import { Box } from '@chakra-ui/react'
import { Layout } from 'src/core/components'
import SurveyForm from '../components/SurveyForm'

const SurveyCreate: React.FC = () => {
  return (
    <Layout
    //   header={{ title: `Locations ${filteredSurveys?.length}` }}
    // permission="create_tree"
    >
      <Box maxW="sm" marginX="auto">
        <SurveyForm type="create" />
      </Box>
    </Layout>
  )
}

export default SurveyCreate
