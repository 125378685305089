import React from 'react'

import { Box, Text } from '@chakra-ui/react'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import { Layout, ErrorBox, Row, Column } from 'src/core/components'
import UserPasswordForm from '../components/UserPasswordForm'

import { IStoreState } from 'src/bootstrap/store/types'
import { useHistory } from 'react-router'
import { route } from 'src/core/helpers'

const Profile: React.FC = () => {
  const user = useSelector((state: IStoreState) => state.user)
  const history = useHistory()

  return (
    <Layout
      header={{
        title: 'My Profile',
        backText: 'Dashboard',
        onBack: () => history.push(route('dashboard')),
      }}
    >
      {!user ? (
        <Box my={50}>
          <ErrorBox
            title="User profile not found"
            summary="Check the URL and try again."
          />
        </Box>
      ) : null}

      {user ? (
        <Row>
          <Column size={8}>
            <Card>
              <Card.Header>
                <Card.Title>Profile Details</Card.Title>
              </Card.Header>
              <Card.Body>
                <Box>
                  <Text fontWeight="medium">Name</Text>
                  <Text>{user.name}</Text>
                </Box>

                <Box mt={3}>
                  <Text fontWeight="medium">Phone</Text>
                  <Text>{user?.phone || '-'}</Text>
                </Box>

                <Box mt={3}>
                  <Text fontWeight="medium">Email</Text>
                  <Text>{user?.email || '-'}</Text>
                </Box>

                <Box mt={3}>
                  <Text fontWeight="medium">Role</Text>
                  <Text>{user?.role || '-'}</Text>
                </Box>

                <Box mt={3}>
                  <Text fontWeight="medium">Permissions</Text>
                  <Text>
                    <Text as="code" fontSize="sm" color="gray.600">
                      {user?.permissions?.join(', ')}
                    </Text>
                  </Text>
                </Box>
              </Card.Body>
            </Card>
          </Column>

          <Column size={4}>
            <Card>
              <Card.Header>
                <Card.Title>Change Password</Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                <UserPasswordForm user={user} />
              </Card.Body>
            </Card>
          </Column>
        </Row>
      ) : null}
    </Layout>
  )
}

export default Profile
