import { ComponentStyleConfig } from '@chakra-ui/theme'

export const Modal: ComponentStyleConfig = {
  parts: ['modal', 'content', 'header', 'footer', 'closeButton'],
  baseStyle: {
    header: {
      // borderBottom: '1px solid',
      borderColor: 'gray.100',
      fontSize: 'lg',
    },
    body: {
      padding: '24px',
    },
    footer: {
      borderTop: '1px solid',
      borderColor: 'gray.100',
    },
  },
}
