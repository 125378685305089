import React from 'react'
import { Select } from '@chakra-ui/react'

import { FilterMenu } from 'src/core/components'
import { BranchType } from '../branch.type'

interface IProps {
  filters: any
  setFilters: (filters: any) => void
  activeFilters: string[]
}

export const BranchesTableFilters: React.FC<IProps> = ({
  filters,
  setFilters,
  activeFilters,
}) => {
  const resetFilters = (): void => {
    setFilters({
      status: '*',
      type: '*',
    })
  }

  return (
    <>
      <FilterMenu
        toggleButtonContent={
          activeFilters.length
            ? `Filtered by: ${activeFilters.join(', ')}`
            : 'Add Filter'
        }
        resetButtonProps={{ onClick: resetFilters }}
      >
        <FilterMenu.Section label="Status">
          <Select
            size="sm"
            value={filters.status || ''}
            onChange={({ target }) => {
              setFilters({ status: target.value, page: 1 })
            }}
          >
            <option value="*" label="All" />
            <option value="active" label="Active" />
            <option value="inactive" label="Inactive" />
          </Select>
        </FilterMenu.Section>

        <FilterMenu.Section label="Type">
          <Select
            size="sm"
            value={filters.type || ''}
            onChange={({ target }) => {
              setFilters({ type: target.value, page: 1 })
            }}
          >
            <option value="*" label="All" />
            <option value={BranchType.Profile} label="Country" />
          </Select>
        </FilterMenu.Section>
      </FilterMenu>
    </>
  )
}
