import { defaultColors } from './defaultColors'

export const anambraColors = {
  body: defaultColors.body,
  black: defaultColors.black,
  white: defaultColors.white,
  primary: {
    50: '#e7f1ec',
    100: '#b6d4c7',
    200: '#9ec5b5',
    300: '#6da88f',
    400: '#3d8b6a',
    500: '#247d58',
    600: '#0C6E45',
    700: '#0a5837',
    800: '#074229',
    900: '#052c1c',
  },
  gray: {
    ...defaultColors.gray,
  },
  success: {
    ...defaultColors.success,
  },
  critical: {
    ...defaultColors.critical,
  },
  warning: {
    ...defaultColors.warning,
  },
  info: {
    ...defaultColors.info,
  },
  secondary: {
    ...defaultColors.secondary,
    dark: '#1C191A',
  },
}
