import React from 'react'

import { StackDivider, Button, VStack } from '@chakra-ui/react'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import * as Yup from 'yup'

import { FormikField, FormStack } from 'src/core/components'
import { useFetch, useRefactorObjects, useToast } from 'src/core/hooks'

import { branchService } from '../branch.service'
import {
  ITree,
  ITreeType,
  treeService,
  TreeServiceProps,
} from 'src/packages/tree'
import { IBranch } from '../branch.type'

interface BranchFormProps {
  type: 'create' | 'update'
  identifier: string
  identifierType: string
  branch?: IBranch
  onUpdate?: (payload?: IBranch) => void
}

const BranchForm: React.FC<BranchFormProps> = ({
  type,
  identifierType,
  identifier,
  branch,
  onUpdate,
}) => {
  const { addToast } = useToast()

  // Fetch trees
  const [trees] = useFetch<ITree[], TreeServiceProps>(treeService, 'fetch')

  const [treeType, setTreeType] = React.useState<ITreeType | undefined>(
    branch?.tree?.type
  )
  const treeTypes = Object.entries(ITreeType).map(([key, value]) => ({
    label: key.toUpperCase(),
    value,
  }))

  const treeOptions = useRefactorObjects(
    trees?.filter((tree) => tree.type === treeType) || []
  )

  const formConfig = {
    initialValues: {
      tree_type: branch?.tree
        ? {
            value: branch.tree.type,
            label: branch.tree.type.toUpperCase(),
          }
        : null,
      tree_id: branch?.tree
        ? {
            value: branch.tree._id,
            label: branch.tree.name,
          }
        : null,
    },
    validationSchema: Yup.object({
      tree_id: Yup.mixed().required('This field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const finalValues: any = {
        tree_id: values?.tree_id?.value,
      }

      if (type === 'update') {
        finalValues._id = branch?._id
        finalValues.identifier = branch?.identifier
        finalValues.type = branch?.type
      } else {
        finalValues.identifier = identifier
        finalValues.type = identifierType
      }

      branchService[type](finalValues)
        .then((branchId) => {
          setSubmitting(false)

          if (type === 'create') {
            finalValues._id = branchId
          }

          branchService
            .fetchById(finalValues._id)
            .then((data) => {
              if (type === 'update') {
                addToast('Branch successfully updated.', {
                  appearance: 'success',
                })
              } else {
                addToast('Branch successfully created.', {
                  appearance: 'success',
                })
              }
              onUpdate && onUpdate(data)
            })
            .catch((error) => {
              setSubmitting(false)
              addToast(error.message, { appearance: 'error' })
              throw error
            })
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={3}
            align="stretch"
            my={3}
          >
            <FormStack label="Type" orientation="vertical" isRequired>
              <FormikField
                as="autocomplete"
                name="tree_type"
                options={[...treeTypes]}
                placeholder="Select type"
                onBlur={() => {
                  formik.setFieldTouched('tree_type', true)
                }}
                onChange={(selected) => {
                  setTreeType(selected.value)
                  formik.setFieldValue('tree_type', selected)
                  formik.setFieldValue('tree_id', null)
                }}
                isDisabled={!treeTypes.length}
              />
            </FormStack>

            <FormStack label="Location" orientation="vertical" isRequired>
              <FormikField
                as="autocomplete"
                name="tree_id"
                options={[...treeOptions]}
                placeholder="Select location"
                onBlur={() => {
                  formik.setFieldTouched('tree_id', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('tree_id', selected)
                }}
                isDisabled={!treeOptions.length}
              />
            </FormStack>

            <Button
              type="submit"
              colorScheme="primary"
              mt={5}
              isDisabled={
                formik.isSubmitting || !formik.dirty || !formik.isValid
              }
              isLoading={formik.isSubmitting}
            >
              {type === 'create'
                ? identifierType === 'profile'
                  ? 'Join'
                  : 'Create'
                : 'Update'}
            </Button>
          </VStack>
        </Form>
      )}
    </Formik>
  )
}

BranchForm.defaultProps = {
  type: 'create',
}

export default BranchForm
