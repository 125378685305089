import React from 'react'
import { Layout, ErrorBox } from 'src/core/components'

const Error404: React.FC<any> = () => {
  return (
    <Layout>
      <ErrorBox
        title="Page not found!"
        summary="The page you're trying to access was not found. That's all we know."
        my={50}
      />
    </Layout>
  )
}

export default Error404
