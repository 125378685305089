import { CoreService, CoreServiceProps } from 'src/core/services'
import { IInvite } from './invite.type'
import pluralize from 'pluralize'

export class InviteService extends CoreService<IInvite> {
  constructor() {
    super('invite')
  }

  fetchByToken(token: string): Promise<IInvite> {
    const formData = new FormData()
    formData.append('token', token)

    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(`${this.model}/read_by_token`, formData, {
            headers: null,
          })
          .then(({ data }) => {
            if (data.code === 200 && data.data.length > 0) {
              resolve(data.data[0])
            } else
              reject({
                message:
                  data.message || 'This invite link has expired or invalid.',
              })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  fetchBySurvey(survey_id: string): Promise<IInvite[]> {
    const formData = new FormData()
    formData.append('survey_id', survey_id)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_${survey_id}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_by_survey`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  sendInvite(payload: {
    invitee: string
    type: string
    profile_id: string
    survey_id: string
  }): Promise<{
    [email: string]: {
      code: number
      message: boolean
      data: string
    }
  }> {
    const formData = new FormData()
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key])
    })

    return new Promise((resolve, reject) => {
      try {
        this.http.post(`${this.model}/share_to`, formData).then(({ data }) => {
          if (data.code === 200) {
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default InviteService
export const inviteService = new InviteService()
export type InviteServiceProps = CoreServiceProps | keyof InviteService
