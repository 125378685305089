import ConfigWizard from './pages/ConfigWizard.page'

import { IRoute } from 'src/bootstrap/routes'

export const configRoutes: IRoute[] = [
  {
    id: 'config_wizard',
    path: '/config-wizard',
    requiresAuth: true,
    component: ConfigWizard,
    exact: true,
  },
]

export default configRoutes
