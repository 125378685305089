import React from 'react'
import { useHistory } from 'react-router'
import { route } from 'src/core/helpers'

import { basicSearch } from 'src/core/helpers/search.helper'
import { useQueryParams } from 'src/core/hooks'
import { ISurvey, ISurveyVisibility } from '../survey.type'

export const useFilterSurveys = (surveys: ISurvey[]): any => {
  const history = useHistory()
  const [filters, setFilters] = React.useState<any>({
    status: useQueryParams('status', '*'),
    visibility: useQueryParams('visibility', '*'),
    page: Number(useQueryParams('page', 1)),
    per_page: Number(useQueryParams('per_page', 6)),
    search: useQueryParams('search', ''),
    location_type: useQueryParams('location_type', '*'),
    location: useQueryParams('location', '*'),
  })
  const [activeFilters, setActiveFilters] = React.useState<string[]>()

  const filteredSurveys = React.useMemo(() => {
    let filtered = surveys || []
    const active: string[] = []

    if (filtered.length) {
      // remove all deleted
      filtered = filtered.filter((survey) => {
        return survey.status !== 'deleted'
      })

      if (filters?.status !== '*') {
        filtered = filtered.filter((survey) => {
          return survey.status === filters.status
        })
        active.push(`Status (${filters?.status})`)
      }

      if (filters?.visibility !== '*') {
        filtered = filtered.filter((survey) => {
          return survey.visibility === filters.visibility
        })
        active.push(
          `${
            filters?.visibility === ISurveyVisibility.For
              ? 'Other Projects'
              : filters?.visibility === ISurveyVisibility.By
              ? 'My Projects'
              : filters?.visibility === ISurveyVisibility.Open
              ? 'Public Projects'
              : 'Uncategorized'
          }`
        )
      }

      if (filters.search) {
        filtered = filtered.filter((survey) => {
          return (
            basicSearch(survey.title as any, filters.search) ||
            basicSearch(survey.status as any, filters.search)
          )
        })
      }

      if (filters?.location_type !== '*') {
        filtered = filtered.filter((survey) => {
          return survey.locations
            ?.map((branch) => branch.tree?.type)
            .includes(filters.location_type)
        })

        active.push(
          `${
            filtered[0]?.locations?.find(
              (branch) => branch.tree?.type === filters?.location_type
            )?.tree?.type
          }`.toUpperCase()
        )
      }

      if (filters?.location !== '*') {
        filtered = filtered.filter((survey) => {
          return survey?.locations
            ?.map((branch) => branch.tree_id)
            .includes(filters.location)
        })

        active.push(
          `Location (${
            filtered[0]?.locations?.find(
              (branch) => branch.tree_id === filters?.location
            )?.tree?.name || '-'
          })`
        )
      }
    }

    setActiveFilters(active)
    return filtered
  }, [
    surveys,
    filters.search,
    filters.status,
    filters.visibility,
    filters.location_type,
    filters.location,
  ])

  React.useEffect(() => {
    const stopListening = history.listen((location) => {
      const query = new URLSearchParams(location.search)

      setFilters({
        status: query.get('status') || '*',
        visibility: query.get('visibility') || '*',
        page: Number(query.get('page')) || 1,
        per_page: Number(query.get('per_page')) || 6,
        search: query.get('search') || '',
        location_type: query.get('location_type') || '*',
        location: query.get('location') || '*',
      })
    })

    return () => {
      stopListening()
    }
  }, [history])

  const updateQueryParams = React.useCallback(
    ({ ...params }) => {
      history.push(
        route('survey', null, {
          ...params,
          page: params.page === 1 ? null : params.page,
        })
      )
    },
    [history]
  )

  return {
    filters,
    setFilters: (newFilters) => {
      updateQueryParams({ ...filters, ...newFilters, page: 1 })
    },
    setPage: (page) => {
      updateQueryParams({ ...filters, page })
    },
    filteredSurveys,
    activeFilters,
  }
}
