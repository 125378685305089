import { ITree } from '../tree'

export enum BranchType {
  Profile = 'profile',
  Metric = 'metric',
  Branch = 'survey',
}

export interface IBranch {
  _id?: string
  type: BranchType
  identifier: string
  tree_id: string

  tree?: ITree
  identifier_obj?: any

  created?: number
}
