import React from 'react'
import { SET_GLOBAL } from '../actionTypes'
import { StoreState } from '../types'

interface IAction {
  type: string
  payload: StoreState['global']
}

const initialState: StoreState['global'] = {
  isLoading: true,
  // eslint-disable-next-line
  version: require('../../../../package.json').version,
  env: process.env.REACT_APP_ENV || '',
}

export const globalReducer: React.Reducer<StoreState['global'], IAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_GLOBAL:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
