import React from 'react'

import { basicSearch } from 'src/core/helpers/search.helper'
import { IBranch } from '../branch.type'

export const useFilterBranches = (branches: IBranch[]): any => {
  const [filters, setFilters] = React.useState<any>({
    status: '*',
    visibility: '*',
    page: 1,
    per_page: 10,
    search: '',
  })
  const [activeFilters, setActiveFilters] = React.useState<string[]>()

  const filteredBranches = React.useMemo(() => {
    let filtered = branches || []
    const active: string[] = []

    if (filtered.length) {
      if (filters.search) {
        filtered = filtered.filter((branch) => {
          return (
            basicSearch(branch.identifier_obj?.status as any, filters.search) ||
            basicSearch(branch.tree?.name as any, filters.search)
          )
        })
      }
    }

    setActiveFilters(active)
    return filtered
  }, [branches, filters.search])

  return {
    filters,
    setFilters: (newFilters) => {
      setFilters({ ...filters, ...newFilters, page: 1 })
    },
    setPage: (page) => setFilters({ ...filters, page }),
    filteredBranches,
    activeFilters,
  }
}
