import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { FiPlus } from 'react-icons/fi'
import { IBranch } from 'src/packages/branch'
import BranchForm from 'src/packages/branch/components/BranchForm'
import { IUser, IUserProfile } from 'src/packages/user'
import { DashboardBranchContext } from '../../contexts'

interface IProps extends ButtonProps {
  title?: string
  profile: IUserProfile | IUser
  branch?: IBranch
}

const DashboardBranchButton: React.FC<IProps> = ({
  title,
  profile,
  branch,
  ...props
}) => {
  const { setBranches } = React.useContext(DashboardBranchContext)
  const [visible, setVisible] = React.useState<boolean>(false)
  const toggleVisible = (): any => {
    setVisible(!visible)
  }

  const onUpdate = (payload?: IBranch): void => {
    if (setBranches && payload) {
      setBranches((prev) => {
        const existingIndex = prev.findIndex((item) => item._id === payload._id)
        const next = [...prev]
        if (existingIndex > -1) {
          next[existingIndex] = payload
        } else {
          next.push(payload)
        }
        return next
      })
    }
    toggleVisible()
  }

  return (
    <>
      <Modal isOpen={visible} onClose={toggleVisible}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Add Location
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Text fontSize="lg" fontWeight="semibold">
              Member: {profile?.name}
            </Text>
            <BranchForm
              identifier={`${profile?._id}`}
              identifierType="profile"
              type={branch ? 'update' : 'create'}
              branch={branch}
              onUpdate={onUpdate}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Button
        size="sm"
        leftIcon={<FiPlus size={16} />}
        onClick={toggleVisible}
        {...props}
      >
        {title}
      </Button>
    </>
  )
}

DashboardBranchButton.defaultProps = {
  title: 'Create',
}

export default DashboardBranchButton
