import { combineReducers } from 'redux'
import { authReducer } from './auth/authReducer'
import { configReducer } from './config/configReducer'
import { globalReducer } from './global/globalReducer'
import { scoreBoardReducer } from './scoreBoard/scoreBoardReducer'
import { userReducer } from './user/userReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  config: configReducer,
  global: globalReducer,
  scoreBoard: scoreBoardReducer,
  user: userReducer,
})

export default rootReducer
