/**
 *
 * @param searchIn
 * @param searchFor
 */
export const basicSearch = (searchIn: string, searchFor: string): boolean => {
  if (searchIn && typeof searchIn === 'string') {
    return searchIn.search(new RegExp(searchFor, 'i')) >= 0
  }
  return false
}
