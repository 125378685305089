import pluralize from 'pluralize'

import cache from 'src/core/helpers/cache.helper'
import { chunkArray } from 'src/core/helpers/filters.helper'
import { http } from 'src/core/helpers/http.helper'

export class CoreService<T = any> {
  protected model: string
  protected http: any
  protected cache: any

  constructor(model: string) {
    this.model = model

    // Utils
    this.http = http
    this.cache = cache
  }

  fetch(): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http.get(`${this.model}/read_all`).then(({ data }) => {
            if (data.code === 200) {
              this.cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchById(_id: string): Promise<T> {
    return new Promise((resolve, reject) => {
      const cacheKey = `${this.model}_${_id}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http.get(`${this.model}/read/?_id=${_id}`).then(({ data }) => {
            if (data.code === 200 && data.data.length) {
              this.cache.set(cacheKey, data.data[0])
              resolve(data.data[0])
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByStatus(status: string): Promise<T[]> {
    const formData = new FormData()
    formData.append('status', status)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_${status}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_by_status`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  signup(payload: any): Promise<T> {
    const formData = new FormData()

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key])
    })

    return new Promise((resolve, reject) => {
      try {
        this.http.post(`${this.model}/signup`, formData).then(({ data }) => {
          if (data.code === 200) {
            this.cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  create(
    payload: any,
    options?: { bulk?: boolean; resetCache?: boolean }
  ): Promise<any> {
    options = { bulk: false, resetCache: true, ...options }
    const formData = new FormData()

    if (options.bulk && payload?.length) {
      payload.forEach((item) => {
        Object.keys(item).forEach((key) => {
          formData.append(`${key}[]`, item[key])
        })
      })
    } else {
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key])
      })
    }

    const endpoint = options.bulk ? 'create_bulk' : 'create'

    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(`${this.model}/${endpoint}`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              options && options.resetCache && this.cache.reset()
              resolve(data.data)
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  update(payload: T): Promise<any> {
    const formData = new FormData()

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key])
    })

    return new Promise((resolve, reject) => {
      try {
        this.http.post(`${this.model}/update`, formData).then(({ data }) => {
          if (data.code === 200) {
            this.cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  updateStatus(payload: { _id: string; status: string }): Promise<any> {
    const formData = new FormData()
    formData.append('_id', payload._id)
    formData.append('status', payload.status)

    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(`${this.model}/set_status`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              this.cache.reset()
              resolve(data.data)
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  private bulk(chunks, endpoint): Promise<any> {
    return new Promise((resolve) => {
      let current = 0
      let succeeded = 0
      let failed = 0

      for (const chunk of chunks) {
        const formData = new FormData()

        chunk.forEach((payload) => {
          Object.keys(payload).forEach((key) => {
            formData.append(`${key}[]`, payload[key])
          })
        })

        this.http
          .post(`${this.model}/${endpoint}`, formData)
          .then(() => {
            succeeded += chunk.length
          })
          .catch(() => {
            failed += 1 * chunk.length
          })
          .finally(() => {
            current++

            if (current === chunks.length) {
              this.cache.reset()
              resolve({ succeeded, failed })
            }
          })
      }
    })
  }

  createBulk(payloads: T[], chunkSize = 125): Promise<any> {
    const chunks = chunkArray<T>(payloads, chunkSize)
    return this.bulk(chunks, 'create_bulk')
  }

  updateBulk(payloads: T[], chunkSize = 125): Promise<any> {
    const chunks = chunkArray<T>(payloads, chunkSize)
    return this.bulk(chunks, 'update_bulk')
  }

  delete(_id: string): Promise<any> {
    const formData = new FormData()
    formData.append('_id', _id)

    return new Promise((resolve, reject) => {
      try {
        this.http.post(`${this.model}/delete`, formData).then(({ data }) => {
          if (data.code === 200) {
            this.cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export type CoreServiceProps = keyof CoreService
