import React from 'react'
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Text,
} from '@chakra-ui/react'

import {
  Card,
  CardBody,
  ErrorBox,
  ImageContainer,
  Pagination,
} from 'src/core/components'
import { paginate, route, timestampToDate } from 'src/core/helpers'

import { ISurvey, ISurveyType, ISurveyVisibility } from '../survey.type'
import { FiMessageCircle, FiHelpCircle } from 'react-icons/fi'
import InviteButton from 'src/packages/invite/components/InviteButton'
import { Link } from 'react-router-dom'

interface SurveyListProps {
  surveys: ISurvey[]
  filters: any
  setFilters: (filters: any) => void
  setPage: (page: number) => void
}

export const SurveyList: React.FC<SurveyListProps> = ({
  surveys,
  ...props
}) => {
  const { page, per_page: perPage } = props.filters
  const { setPage } = props

  const paginatedSurveys = React.useMemo(() => {
    return surveys ? paginate(surveys, page, perPage) : []
  }, [page, perPage, surveys])

  return (
    <>
      {!paginatedSurveys.length ? (
        <ErrorBox summary="No projects found" my={50} />
      ) : null}

      {paginatedSurveys?.length ? (
        <>
          <Grid
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(2, 1fr)',
              'repeat(3, 1fr)',
            ]}
            gap={5}
            mt={5}
          >
            {paginatedSurveys.map((survey, index) => (
              <GridItem key={survey._id}>
                <SurveyCard
                  key={survey._id}
                  survey={survey}
                  index={index}
                  page={page}
                  perPage={perPage}
                />
              </GridItem>
            ))}
          </Grid>

          <Pagination
            my={5}
            itemsPerPage={9}
            totalItems={surveys.length}
            currentPage={page}
            onPageChange={setPage}
            justifyContent="center"
          />
        </>
      ) : null}
    </>
  )
}

interface SurveyCardProps {
  survey: ISurvey
  index: number
  page: number
  perPage: number
}

const SurveyCard: React.FC<SurveyCardProps> = ({ survey }) => {
  return (
    <Card as={Flex} flexDirection="column" key={survey._id} height="full">
      <ImageContainer
        src={survey?.upload?.path}
        width={'100%'}
        height={180}
        backgroundSize="cover"
      />
      <CardBody
        as={Flex}
        flexDirection="column"
        flexGrow={1}
        justifyContent="flex-start"
      >
        <Box d="flex" alignItems="baseline">
          <Badge
            colorScheme={survey.status === 'active' ? 'success' : 'critical'}
          >
            {survey.status}
          </Badge>
          {`${survey?.visibility}` !== ISurveyVisibility.Open && (
            <SurveyTypeBadge surveyType={survey.type} />
          )}
          <VisibilityBadge visibility={survey.visibility} />
        </Box>

        <Box
          mt="5"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          noOfLines={3}
        >
          {survey.title}
          <Text
            color="gray.500"
            fontWeight="medium"
            letterSpacing="wide"
            fontSize="xs"
            ml="2"
            display="inline"
            textTransform="none"
          >
            {survey?.profile?.name
              ? '@' + survey?.profile?.name
              : '@Unknown Author'}{' '}
            &bull; {timestampToDate(survey?.created)}
          </Text>
        </Box>
        <Box mt="1" fontWeight="normal" as="p" lineHeight="tight" noOfLines={3}>
          {survey.description}
        </Box>
        <HStack
          mt={5}
          justify="start"
          wrap="wrap"
          gridRowGap={2}
          gridRowStart={5}
        >
          <Box>
            <Link
              to={route('survey_view', {
                id: survey._id,
                tab: 'invites',
              })}
            >
              <Button size="sm" colorScheme="primary">
                View Project
              </Button>
            </Link>
          </Box>
          <Box>
            <InviteButton size="sm" variant="outline" survey={survey} />
          </Box>
        </HStack>
        <Flex flexGrow={1}>
          <Center
            px={2}
            h="40px"
            color="gray.600"
            fontSize="14"
            alignSelf="flex-end"
          >
            <FiHelpCircle style={{ marginRight: 4 }} />
            {survey.question_count || '0'}
          </Center>
          <Center
            px={2}
            h="40px"
            color="gray.600"
            fontSize="14"
            alignSelf="flex-end"
          >
            <FiMessageCircle style={{ marginRight: 4 }} />
            {survey.answer_count && survey?.question_count
              ? Math.round(survey.answer_count / survey.question_count)
              : '0'}
          </Center>
        </Flex>
      </CardBody>
    </Card>
  )
}

const SurveyTypeBadge: React.FC<{ surveyType: ISurveyType }> = ({
  surveyType,
}) => {
  switch (surveyType) {
    case ISurveyType.Public:
      return (
        <Badge variant="solid" colorScheme="gray" ml={2}>
          Public
        </Badge>
      )
    case ISurveyType.Private:
      return (
        <Badge variant="solid" colorScheme="success" ml={2}>
          Private
        </Badge>
      )
    default:
      return null
  }
}

const VisibilityBadge: React.FC<{ visibility?: any }> = ({ visibility }) => {
  switch (visibility) {
    case ISurveyVisibility.For:
      return (
        <Badge variant="solid" colorScheme="purple" ml={2}>
          Other Projects
        </Badge>
      )
    case ISurveyVisibility.By:
      return (
        <Badge variant="solid" colorScheme="primary" ml={2}>
          Created by Me
        </Badge>
      )
    case ISurveyVisibility.Open:
      return (
        <Badge variant="solid" colorScheme="gray" ml={2}>
          Public
        </Badge>
      )

    default:
      return (
        <Badge variant="solid" colorScheme="success" ml={2}>
          Uncategorized
        </Badge>
      )
  }
}
