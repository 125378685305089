import React from 'react'
import { ErrorBox, Pagination } from 'src/core/components'
import { Table, Thead, Tbody, Tr, Td, Th } from 'src/core/components/Table'
import { paginate, timestampToDate } from 'src/core/helpers'
import { IBranch } from 'src/packages/branch'
import DashboardBranchButton from './DashboardBranchButton'

interface DashboardBranchesTableProps {
  branches: IBranch[]
  filters: any
  setFilters: (filters: any) => void
  setPage: (page: number) => void
}

export const DashboardBranchesTable: React.FC<DashboardBranchesTableProps> = ({
  branches,
  ...props
}) => {
  const { page, per_page: perPage } = props.filters
  const { setPage } = props

  const paginatedBranches = React.useMemo(() => {
    return branches ? paginate(branches, page, perPage) : []
  }, [page, perPage, branches])

  return (
    <>
      {!paginatedBranches.length ? (
        <ErrorBox summary="No location found" my={50} />
      ) : null}

      {paginatedBranches?.length ? (
        <>
          <Table isFit mt={2}>
            <DashboardBranchesThead />
            <Tbody>
              {paginatedBranches.map((branch, index) => (
                <BranchesTr
                  key={branch._id}
                  branch={branch}
                  index={index}
                  page={page}
                  perPage={perPage}
                />
              ))}
            </Tbody>
          </Table>

          <Pagination
            my={5}
            itemsPerPage={10}
            totalItems={branches.length}
            currentPage={page}
            onPageChange={setPage}
            justifyContent="center"
          />
        </>
      ) : null}
    </>
  )
}

const DashboardBranchesThead: React.FC = () => {
  return (
    <Thead>
      <Tr>
        <Th textAlign="center">#</Th>
        <Th>Date</Th>
        <Th>Location</Th>
        <Th>Type</Th>
        <Th>Actions</Th>
      </Tr>
    </Thead>
  )
}

interface DashboardBranchesTrProps {
  branch: IBranch
  index: number
  page: number
  perPage: number
}
const BranchesTr: React.FC<DashboardBranchesTrProps> = ({
  branch,
  ...props
}) => {
  return (
    <Tr key={branch._id}>
      <Td textAlign="center">
        {props.index + 1 + (props.page - 1) * props.perPage}
      </Td>
      <Td>{timestampToDate(branch?.created)}</Td>
      <Td>{branch?.tree?.name || '-'}</Td>
      <Td textTransform="uppercase">{branch.tree?.type}</Td>
      <Td>
        <DashboardBranchButton
          profile={branch.identifier_obj}
          title="Edit"
          branch={branch}
          leftIcon={undefined}
          variant="outline"
        />
      </Td>
    </Tr>
  )
}
