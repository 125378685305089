import React from 'react'

import { Button, Heading, useTheme } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/layout'
import { Formik, Form, FormikHelpers } from 'formik'
import { FiLock } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import { FormikField, FormStack, Card } from 'src/core/components'
import { route } from 'src/core/helpers'
import useToast from 'src/core/hooks/useToast'
import { userService } from '../services'
import { AuthLayout } from 'src/core/components/Layout/AuthLayout'
import { useHistory, useParams } from 'react-router'

interface IFormValues {
  hash: string
  hash_confirm: string
}

const ResetPassword: React.FC = () => {
  const { colors } = useTheme()
  const { addToast } = useToast()
  const history = useHistory()
  const { token } = useParams<any>()

  const formConfig = {
    initialValues: {
      hash: '',
      hash_confirm: '',
    } as IFormValues,
    validationSchema: Yup.object({
      hash: Yup.string()
        .required('Password is required.')
        .min(8, 'Password is too short. Enter a minimum of 8 characters.'),
      hash_confirm: Yup.string()
        .oneOf([Yup.ref('hash')], 'Passwords do not match')
        .required('Confirm password.'),
    }),
    onSubmit: (
      values,
      { setSubmitting, resetForm }: FormikHelpers<IFormValues>
    ) => {
      setSubmitting(true)

      userService
        .completeReset({ hash: values.hash, token })
        .then(() => {
          addToast('Successful. Login with your new password.', {
            appearance: 'success',
          })
          resetForm()
          history.push('auth_login')
        })
        .catch((error) => {
          addToast(error.message, { appearance: 'error' })
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  }

  React.useEffect(() => {
    if (!token) {
      history.replace('reset')
    }
  }, [token, history])

  return (
    <AuthLayout>
      <Card padding="10">
        <Flex flexDirection="column" mb={10}>
          <Heading
            as="h5"
            fontSize="xl"
            fontWeight="semibold"
            color="gray.900"
            mt="10px"
          >
            Choose a new password
          </Heading>
        </Flex>

        <Formik
          initialValues={formConfig.initialValues}
          validationSchema={formConfig.validationSchema}
          validateOnMount={true}
          onSubmit={formConfig.onSubmit}
        >
          {({ ...formik }) => (
            <Form onSubmit={formik.handleSubmit}>
              <FormStack orientation="vertical">
                <FormikField
                  name="hash"
                  type="password"
                  prepend={<FiLock color={colors.gray['600']} size={16} />}
                  placeholder="Password"
                />
              </FormStack>
              <FormStack orientation="vertical">
                <FormikField
                  name="hash_confirm"
                  type="password"
                  prepend={<FiLock color={colors.gray['600']} size={16} />}
                  placeholder="Confirm Password"
                />
              </FormStack>

              <Flex justifyContent="space-between" alignItems="center">
                <Button
                  type="submit"
                  colorScheme="primary"
                  mt={5}
                  isDisabled={formik.isSubmitting || !formik.isValid}
                  isLoading={formik.isSubmitting}
                >
                  Continue
                </Button>
              </Flex>

              <Flex flexDir="column" align="center" mt={10}>
                <Box>
                  <Link to={route('auth_login')}>Go back to Login</Link>
                </Box>
              </Flex>
            </Form>
          )}
        </Formik>
      </Card>
    </AuthLayout>
  )
}

export default ResetPassword
