import React from 'react'

import { basicSearch } from 'src/core/helpers/search.helper'
import { IInvite } from '../invite.type'

export const useFilterInvites = (invites: IInvite[]): any => {
  const [filters, setFilters] = React.useState<any>({
    status: '*',
    type: '*',
    page: 1,
    per_page: 10,
    search: '',
  })
  const [activeFilters, setActiveFilters] = React.useState<string[]>()

  const filteredInvites = React.useMemo(() => {
    let filtered = invites || []
    const active: string[] = []

    if (filtered.length) {
      if (filters?.status !== '*') {
        filtered = filtered.filter((invite) => {
          return invite.status === filters.status
        })
        active.push(`Status (${filters?.status})`)
      }

      if (filters?.type !== '*') {
        filtered = filtered.filter((invite) => {
          return invite.type === filters.type
        })
        active.push(`Type (${filters?.type})`)
      }

      if (filters.search) {
        filtered = filtered.filter((invite) => {
          return (
            basicSearch(invite.invitee as any, filters.search) ||
            basicSearch(invite.status as any, filters.search) ||
            basicSearch(invite.type as any, filters.search)
          )
        })
      }
    }

    setActiveFilters(active)
    return filtered
  }, [invites, filters.search, filters.status, filters.type])

  return {
    filters,
    setFilters: (newFilters) => {
      setFilters({ ...filters, ...newFilters, page: 1 })
    },
    setPage: (page) => setFilters({ ...filters, page }),
    filteredInvites,
    activeFilters,
  }
}
