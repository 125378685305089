import React from 'react'

import { Stack, Box, Flex, Button, IconButton, Spinner } from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import { FiTrash2 } from 'react-icons/fi'

import * as Yup from 'yup'

import { FormikField } from 'src/core/components'
import useToast from 'src/core/hooks/useToast'
import { ConfigService } from '../config.service'
import { IConfig } from '../config.type'

const configService = new ConfigService()

interface IProps {
  type?: string
  config?: IConfig
  addConfig?: (config: IConfig) => void
  updateConfig?: (config: IConfig) => void
  removeConfig?: (config: IConfig) => void
}

const ConfigForm: React.FC<IProps> = ({
  type,
  config,
  addConfig,
  updateConfig,
  removeConfig,
}) => {
  const { addToast } = useToast()

  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)

  const formConfig = {
    initialValues: {
      key: config?.key || '',
      value: config?.value || '',
    },
    validationSchema: Yup.object({
      key: Yup.string().required('Key field is required.'),
      value: Yup.string().required('Value field is required.'),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)

      const finalValues: any = {
        ...values,
      }

      if (type === 'update') {
        finalValues._id = config?._id
      }

      configService[type || ''](finalValues)
        .then((_id) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast(`${values.key} value successfully updated.`, {
              appearance: 'success',
            })
            updateConfig && updateConfig(finalValues)
          } else {
            addToast(`${values.key} value successfully created.`, {
              appearance: 'success',
            })
            addConfig && addConfig({ ...values, _id })
            resetForm()
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  const deleteConfig = (): void => {
    if (!config) return
    setIsDeleting(true)
    configService
      .delete(config?._id || '')
      .then(() => {
        addToast(`${config.key} has been deleted.`, { appearance: 'success' })
        removeConfig && removeConfig(config)
      })
      .catch((error) => {
        addToast(error.message, { appearance: 'error' })
      })
      .finally(() => {
        setIsDeleting(false)
      })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          {/* Key */}
          <Stack
            direction={['column', 'column', 'row']}
            justifyContent="stretch"
          >
            <Box flex={2} mb={[2, 0]}>
              <FormikField
                size="sm"
                name="key"
                type="text"
                placeholder={type === 'create' ? 'New Key *' : 'Key *'}
              />
            </Box>
            <Box size={2}>
              <FormikField
                size="sm"
                name="value"
                type="text"
                placeholder={type === 'create' ? 'New Value *' : 'Value *'}
              />
            </Box>
            <Box size={1}>
              <Flex>
                <Button
                  type="submit"
                  size="sm"
                  variant="outline"
                  isDisabled={
                    formik.isSubmitting ||
                    !formik.dirty ||
                    !formik.isValid ||
                    isDeleting
                  }
                  width="100%"
                  isLoading={formik.isSubmitting}
                >
                  {type === 'create' ? 'Add' : 'Update'}
                </Button>

                {type === 'update' && (
                  <>
                    {isDeleting ? (
                      <Flex ml={2}>
                        <Spinner size="sm" color="primary.600" />
                      </Flex>
                    ) : (
                      <IconButton
                        aria-label="Delete"
                        size="sm"
                        variant="ghost"
                        ml={2}
                        colorScheme="critical"
                        icon={<FiTrash2 size={16} />}
                        isDisabled={formik.isSubmitting || isDeleting}
                        onClick={deleteConfig}
                      />
                    )}
                  </>
                )}
              </Flex>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

ConfigForm.defaultProps = {
  type: 'create',
}

export default ConfigForm
