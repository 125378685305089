import React from 'react'

import { Spinner, Flex, Box } from '@chakra-ui/react'
import { SearchInput } from 'src/core/components'
import { useSelector } from 'react-redux'
import { IStoreState } from 'src/bootstrap/store/types'
import { useMounted, useToast } from 'src/core/hooks'
import { branchService, IBranch } from 'src/packages/branch'
import { useFilterBranches } from 'src/packages/branch/hooks/useFilterBranches'
import { BranchesTableFilters } from 'src/packages/branch/components/BranchesTableFilter'
import SurveyBranchCreateButton from '../components/branch/SurveyBranchButton'
import { ISurvey } from '../survey.type'
import { SurveyBranchesTable } from '../components/branch/SurveyBranchesTable'
import { SurveyBranchContext } from '../contexts'

const SurveyBranches: React.FC<{ survey: ISurvey }> = ({ survey }) => {
  const user = useSelector((state: IStoreState) => state.user)
  const isMounted = useMounted()
  const { addToast } = useToast()

  const [isLoading, setLoading] = React.useState(false)
  const [branches, setBranches] = React.useState<IBranch[]>([])

  const { filters, setFilters, setPage, activeFilters, filteredBranches } =
    useFilterBranches(branches)

  React.useEffect(() => {
    isMounted.current && setLoading(true)
    branchService
      .fetchByIdentifier(`${survey._id}`, 'survey')
      .then((data) => {
        isMounted.current && setBranches(data)
      })
      .catch((err) => {
        addToast(err.message, { appearance: 'error' })
      })
      .finally(() => {
        isMounted.current && setLoading(false)
      })
  }, [user._id, isMounted, addToast, survey._id])

  return (
    <>
      <SurveyBranchContext.Provider
        value={{
          branches,
          setBranches,
        }}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <SearchInput
              placeholder="Search location"
              value={filters.search}
              onChange={(search) => setFilters({ search, page: 1 })}
              isDisabled={isLoading || !filteredBranches}
            />

            <Box ml={2}>
              <BranchesTableFilters
                filters={filters}
                setFilters={setFilters}
                activeFilters={activeFilters}
              />
            </Box>
          </Flex>
          <SurveyBranchCreateButton survey={survey} />
        </Flex>

        {isLoading ? (
          <Flex justifyContent="center" py={50}>
            <Spinner color="primary.600" />
          </Flex>
        ) : (
          <SurveyBranchesTable
            branches={filteredBranches}
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        )}
      </SurveyBranchContext.Provider>
    </>
  )
}

export default SurveyBranches
