/**
 * Genrate random ref ID
 *
 * @param length
 */
export const generateRefId = (length: number): string => {
  let result = ''
  const characters = 'ABCDEFGHJKPQRSTUWYZ123456789'
  const charactersLength = characters.length

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const secureRandomString = (length = 10): any => {
  if (window.crypto) {
    const arr = new Uint32Array(length)
    window.crypto.getRandomValues(arr)
    return arr[0]
  } else {
    let text = ''
    const possible =
      'ABCDEFGHJKMNOPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz0123456789'
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
  }
}
