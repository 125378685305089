import React from 'react'
import { Select } from '@chakra-ui/react'

import { Autocomplete, FilterMenu } from 'src/core/components'
import { ISurveyVisibility } from '../survey.type'
import { ITreeType } from 'src/packages/tree'

interface IProps {
  filters: any
  setFilters: (filters: any) => void
  activeFilters: string[]
  locationOptions?: any[]
  locationTypes?: ITreeType[]
}

export const SurveysTableFilters: React.FC<IProps> = ({
  filters,
  setFilters,
  activeFilters,
  locationOptions,
  locationTypes,
}) => {
  const resetFilters = (): void => {
    setFilters({
      status: '*',
      visibility: '*',
      location_type: '*',
      location: '*',
    })
  }

  return (
    <>
      <FilterMenu
        toggleButtonContent={
          activeFilters.length
            ? `Filtered by: ${activeFilters.join(', ')}`
            : 'Add Filter'
        }
        resetButtonProps={{ onClick: resetFilters }}
      >
        <FilterMenu.Section label="Status">
          <Select
            size="sm"
            value={filters.status || ''}
            onChange={({ target }) => {
              setFilters({ status: target.value, page: 1 })
            }}
          >
            <option value="*" label="All" />
            <option value="active" label="Active" />
            <option value="inactive" label="Inactive" />
          </Select>
        </FilterMenu.Section>

        <FilterMenu.Section label="Visibility">
          <Select
            size="sm"
            value={filters.visibility || ''}
            onChange={({ target }) => {
              setFilters({ visibility: target.value, page: 1 })
            }}
          >
            <option value="*" label="All" />
            <option value={ISurveyVisibility.By} label="My Projects" />
            <option value={ISurveyVisibility.For} label="Other Projects" />
            <option value={ISurveyVisibility.Open} label="Public Projects" />
          </Select>
        </FilterMenu.Section>

        {locationTypes && locationTypes?.length > 1 && (
          <FilterMenu.Section label="Location Type">
            <Select
              size="sm"
              value={filters.location_type || ''}
              onChange={({ target }) => {
                setFilters({ location_type: target.value, page: 1 })
              }}
            >
              {['*', ...locationTypes]?.map((locationType, index) => {
                return (
                  <option
                    key={index}
                    value={locationType}
                    label={
                      Object.entries(ITreeType).find(
                        ([, value]) => value === locationType
                      )?.[0] || 'All'
                    }
                  />
                )
              })}
            </Select>
          </FilterMenu.Section>
        )}

        <FilterMenu.Section label={`Location`}>
          <Autocomplete
            size="sm"
            value={
              locationOptions?.find(
                (option) => option.value === filters.location
              ) || { label: 'All', value: '*' }
            }
            onChange={({ value }) => {
              setFilters({ location: value, page: 1 })
            }}
            options={
              locationOptions
                ? [{ label: 'All', value: '*' }, ...locationOptions]
                : []
            }
            name="location"
          />
        </FilterMenu.Section>
      </FilterMenu>
    </>
  )
}
