import React from 'react'

import {
  Input,
  InputLeftElement,
  InputGroup,
  InputRightElement,
  Flex,
  useTheme,
  FlexProps,
} from '@chakra-ui/react'
import { FiSearch, FiX } from 'react-icons/fi'

import useDebounce from 'src/core/hooks/useDebounce'

type CustomFlexProps = Omit<FlexProps, 'onChange'>

interface IProps extends CustomFlexProps {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  isDisabled?: boolean
  delay?: number
}
export const SearchInput: React.FC<IProps> = ({
  value,
  onChange,
  placeholder,
  isDisabled,
  delay,
  width,
  ...props
}) => {
  const { colors } = useTheme()
  const [searchTerm, setSearchTerm] = React.useState<string>(value)
  const debouncedSearchTerm = useDebounce(searchTerm, delay)

  const searchTermRef = React.useRef(debouncedSearchTerm)

  React.useEffect(() => {
    if (searchTermRef.current !== debouncedSearchTerm) {
      searchTermRef.current = debouncedSearchTerm
      onChange(debouncedSearchTerm)
    }
  }, [debouncedSearchTerm, onChange])

  return (
    <Flex alignItems="center" {...props}>
      <InputGroup size="sm">
        <InputLeftElement size="sm" pointerEvents="none">
          <FiSearch size={16} color={colors.gray[isDisabled ? '500' : '600']} />
        </InputLeftElement>
        <Input
          size="sm"
          type="text"
          name="searchTerm"
          value={searchTerm || ''}
          className={`search w-${width}`}
          placeholder={placeholder}
          onChange={({ target }) => {
            setSearchTerm(target.value)
          }}
          isDisabled={isDisabled}
        />

        {/* Reset for search term only */}
        {!!searchTerm && (
          <InputRightElement
            size="sm"
            pointerEvents="all"
            cursor="default"
            transition="transform 300ms"
            _hover={{ transform: 'scale(1.2)' }}
            onClick={() => setSearchTerm('')}
          >
            <FiX size={16} color={colors.gray['500']} />
          </InputRightElement>
        )}
      </InputGroup>
    </Flex>
  )
}

SearchInput.defaultProps = {
  placeholder: 'Search...',
  isDisabled: false,
  delay: 750,
  width: 'auto',
}

export default SearchInput
