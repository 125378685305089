import React from 'react'

import { Button, Spinner, Flex, Box } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { Layout, SearchInput } from 'src/core/components'
import { route } from 'src/core/helpers/route.helper'

import { useFilterSurveys } from '../hooks/useFilterSurveys'
import { ISurvey, ISurveyForProfile } from '../survey.type'
import { SurveysTableFilters } from '../components/SurveysTableFilter'
import { surveyService } from '../survey.service'
import { SurveyList } from '../components/SurveyList'
import { useSelector } from 'react-redux'
import { IStoreState } from 'src/bootstrap/store/types'
import { useMounted, useRefactorObjects, useToast } from 'src/core/hooks'
import { sortBy, uniq, uniqBy } from 'lodash'
import { ITree, ITreeType } from 'src/packages/tree'

const Surveys: React.FC = () => {
  const user = useSelector((state: IStoreState) => state.user)
  const isMounted = useMounted()
  const { addToast } = useToast()

  const [isLoading, setLoading] = React.useState(false)
  const [surveys, setSurveys] = React.useState<ISurveyForProfile>()

  const [aggregateSurveys, aggregateLocations, aggregateLocationTypes] =
    React.useMemo<[ISurvey[], ITree[], ITreeType[]]>(() => {
      const output: ISurvey[] = []
      const locations: ITree[] = []
      const locationTypes: ITreeType[] = []
      if (surveys) {
        Object.entries(surveys).forEach(
          ([visibility, surveyArray]: [any, ISurvey[]]) => {
            surveyArray.forEach((survey: ISurvey) => {
              output.push({
                ...survey,
                visibility,
              })
              survey?.locations?.forEach((branch) => {
                branch['name'] = branch.tree?.name
                branch.tree && locations.push(branch.tree)
                branch.tree?.type && locationTypes.push(branch.tree?.type)
              })
            })
          }
        )
      }
      return [
        sortBy(output, 'created').reverse(),
        uniqBy(locations, '_id'),
        uniq(locationTypes),
      ]
    }, [surveys])

  const { filters, setFilters, setPage, activeFilters, filteredSurveys } =
    useFilterSurveys(aggregateSurveys)

  const locations = useRefactorObjects(aggregateLocations)

  React.useEffect(() => {
    isMounted.current && setLoading(true)
    surveyService
      .fetchForProfile(`${user._id}`)
      .then((data) => {
        isMounted.current && setSurveys(data)
      })
      .catch((err) => {
        addToast(err.message, { appearance: 'error' })
      })
      .finally(() => {
        isMounted.current && setLoading(false)
      })
  }, [user._id, isMounted, addToast])

  return (
    <Layout
    //   header={{ title: `Surveys ${filteredSurveys?.length}` }}
    //   permission="view_surveys"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <SearchInput
            placeholder="Search projects"
            value={filters.search}
            onChange={(search) => setFilters({ search, page: 1 })}
            isDisabled={isLoading || !filteredSurveys}
          />

          <Box ml={2}>
            <SurveysTableFilters
              filters={filters}
              setFilters={setFilters}
              activeFilters={activeFilters}
              locationOptions={locations}
              locationTypes={aggregateLocationTypes}
            />
          </Box>
        </Flex>

        <Link to={route('survey_setup')}>
          <Button
            size="sm"
            leftIcon={<FiPlus size={16} />}
            colorScheme="primary"
          >
            Create Template
          </Button>
        </Link>
      </Flex>

      {isLoading ? (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      ) : (
        <SurveyList
          surveys={filteredSurveys}
          filters={filters}
          setFilters={setFilters}
          setPage={setPage}
        />
      )}
    </Layout>
  )
}

export default Surveys
