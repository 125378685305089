import React, { Component } from 'react'
import { Spinner, Flex } from '@chakra-ui/react'
import { Route, Switch } from 'react-router-dom'
import { ErrorBox } from 'src/core/components'
import ProtectedRoute from './ProtectedRoute'
import { IRoute } from './routes'

export const SuspenseLoader: React.FC = () => (
  <Flex justifyContent="center" my={150}>
    <Spinner color="primary.600" />
  </Flex>
)

interface ErrorBoundaryProps {
  fallback: React.ReactNode
}
export class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state = { hasError: false, error: null }
  static getDerivedStateFromError(error): { hasError: boolean; error: Error } {
    return {
      hasError: true,
      error,
    }
  }

  render(): React.ReactNode {
    return this.state.hasError ? this.props.fallback : this.props.children
  }
}

interface RouterSwitcherProps {
  routes: IRoute[]
}
export const RouteSwitcher: React.FC<RouterSwitcherProps> = (props) => (
  <React.Suspense fallback={<SuspenseLoader />}>
    <ErrorBoundary fallback={<ErrorBox type="suspense" my={150} />}>
      <Switch>
        {/* Load routes */}
        {props.routes.map((route) => {
          const routeProps = {
            path: route.path,
            component: route.component,
            exact: route.exact,
          }

          return !route.requiresAuth ? (
            <Route key={route.id} {...routeProps} />
          ) : (
            <ProtectedRoute key={route.id} {...routeProps} />
          )
        })}
      </Switch>
    </ErrorBoundary>
  </React.Suspense>
)

export default RouteSwitcher
