import { IChoice } from '../choice'
import { IUpload } from '../upload'

export enum IQuestionType {
  SingleEntry = 'single-entry',
  MultiChoice = 'multi-choice',
}

export interface IQuestion {
  _id?: string
  title: string
  type: IQuestionType
  parent_id: string
  upload_id: string
  upload?: IUpload
  index: number
  status: 'active' | 'inactive'

  choices?: IChoice[]
}
