import React from 'react'

import { Box, Flex, Spinner } from '@chakra-ui/react'
import { ErrorBox, Layout } from 'src/core/components'
import BranchForm from '../components/BranchForm'
import { useMounted } from 'src/core/hooks'
import { IBranch } from '../branch.type'
import { branchService } from '../branch.service'
import { useParams } from 'react-router'

const BranchUpdate: React.FC = () => {
  const isMounted = useMounted()
  const { id: param_id }: any = useParams()

  const [isLoading, setLoading] = React.useState<boolean>(false)
  const [branch, setBranch] = React.useState<IBranch>()
  const [error, setError] = React.useState<any>()

  React.useEffect(() => {
    if (param_id) {
      setLoading(true)
      setError(undefined)
      branchService
        .fetchById(param_id)
        .then((data) => {
          isMounted.current && setBranch(data)
        })
        .catch((error) => {
          isMounted.current && setError(error)
        })
        .finally(() => {
          isMounted.current && setLoading(false)
        })
    } else {
      setError(undefined)
      setLoading(false)
    }
  }, [param_id, isMounted])

  return (
    <Layout
      header={{ title: `${branch?.identifier_obj.name || 'Branch'}` }}
      // permission="update_branch"
    >
      {isLoading ? (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      ) : branch ? (
        <Box maxW="sm" marginX="auto">
          <BranchForm
            type="update"
            branch={branch}
            identifier={branch.identifier}
            identifierType={branch.type}
          />
        </Box>
      ) : error ? (
        <ErrorBox summary={`${error?.message}`} />
      ) : (
        <ErrorBox summary={`Project not found.`} />
      )}
    </Layout>
  )
}

export default BranchUpdate
