import { CoreService, CoreServiceProps } from 'src/core/services'

export class ProfileService extends CoreService {
  constructor() {
    super('profile')
  }
}

export default ProfileService
export const profileService = new ProfileService()
export type ProfileServiceProps = CoreServiceProps | keyof ProfileService
