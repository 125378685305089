import React from 'react'

import { VStack, StackDivider, Button, Divider } from '@chakra-ui/react'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import {
  FormikRadioGroup,
  FormikField,
  FormStack,
  ImageUploader,
} from 'src/core/components'
import { useMounted, usePermission, useToast } from 'src/core/hooks'
import { route } from 'src/core/helpers'

import { surveyService } from '../survey.service'
import { ISurvey, ISurveyType } from '../survey.type'
import { IStoreState } from 'src/bootstrap/store/types'
import { useSelector } from 'react-redux'
import { uploadService } from 'src/packages/upload'
import Alert from 'src/core/components/Alert/Alert'

interface SurveyFormProps {
  type: 'create' | 'update'
  survey?: ISurvey
  onUpdate?: (payload: ISurvey) => void
}

const DeleteSurvey: React.FC<{ survey: ISurvey }> = ({ survey }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const onClose = (): void => setIsOpen(false)
  const isMounted = useMounted()
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false)
  const history = useHistory()
  const { toast } = useToast()

  const doDelete = (): void => {
    setSubmitting(true)
    onClose()
    surveyService
      .updateStatus({ _id: `${survey._id}`, status: 'deleted' })
      .then(() => {
        toast({
          description: 'Template deleted successfully.',
          status: 'success',
        })
        history.push(route('survey'))
      })
      .catch((error) => {
        toast({
          description: error.message,
          status: 'error',
        })
      })
      .finally(() => {
        isMounted.current && setSubmitting(false)
      })
  }

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        colorScheme="critical"
        mt={5}
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
      >
        Delete Template
      </Button>

      <Alert
        isOpen={isOpen}
        onClose={onClose}
        header={`Delete ${survey.title}`}
        description={`
              Are you sure? All entries and data related to this template will
              be deleted. You can't undo this action afterwards.`}
        buttons={[
          {
            label: 'Close',
            isClose: true,
          },
          {
            label: 'Delete Template',
            props: {
              ml: 3,
              colorScheme: 'critical',
              onClick: doDelete,
            },
          },
        ]}
      />
    </>
  )
}

const SurveyForm: React.FC<SurveyFormProps> = ({ type, survey, onUpdate }) => {
  const history = useHistory()
  const { userCan } = usePermission()
  const { addToast } = useToast()
  const user = useSelector((state: IStoreState) => state.user)

  const isOwner = React.useMemo<boolean>(() => {
    if (user?._id === survey?.profile_id) {
      return true
    }
    return false
  }, [user, survey])

  const formConfig = {
    initialValues: {
      title: survey?.title || '',
      type: survey?.type || ISurveyType.Public,
      description: survey?.description,
      status: survey?.status || 'active',
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('Title field is required.')
        .min(3, 'Title must be at least 3 characters.'),
      description: Yup.string()
        .required('Description field is required.')
        .min(3, 'Description must be at least 3 characters.'),
      type: Yup.string().required('Type field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const finalValues: any = {
        ...values,
      }

      // while we figure out location linking to survey, drop this
      delete finalValues.location

      if (type === 'update') {
        finalValues._id = survey?._id
        finalValues.upload_id = survey?.upload_id
        finalValues.profile_id = survey?.profile_id
      }

      if (type === 'create') {
        finalValues.profile_id = user._id
        finalValues.upload_id = '-'
      }

      surveyService[type](finalValues)
        .then((surveyId) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Project successfully updated.', {
              appearance: 'success',
            })
            onUpdate && onUpdate({ ...survey, ...finalValues })
          } else {
            finalValues._id = surveyId
            addToast('Project successfully created.', {
              appearance: 'success',
            })
            if (onUpdate) {
              onUpdate(finalValues)
            } else {
              history.push(route('survey_update', { id: surveyId }))
            }
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
    onSubmitImage: (image): Promise<ISurvey> => {
      return new Promise((resolve, reject) => {
        const finalValues: any = {
          title: survey?.title,
          description: survey?.description,
          type: survey?.type,
          upload_id: survey?.upload_id,
          status: survey?.status,
          profile_id: survey?.profile_id,
        }

        if (type === 'update') {
          finalValues._id = survey?._id
        }

        uploadService
          .upload(image, null)
          .then((upload_id) => {
            finalValues.upload_id = upload_id
            surveyService
              .update(finalValues)
              .then(() => {
                surveyService
                  .fetchById(`${survey?._id}`)
                  .then((data) => {
                    onUpdate && onUpdate(data)
                    resolve(data)
                  })
                  .catch((error) => {
                    addToast(error.message, { appearance: 'error' })
                    reject(error)
                    throw error
                  })
              })
              .catch((error) => {
                addToast(error.message, { appearance: 'error' })
                reject(error)
                throw error
              })
          })
          .catch((error) => {
            addToast(error.message, { appearance: 'error' })
            reject(error)
            throw error
          })
      })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Image */}
            {type === 'update' && (
              <ImageUploader
                uploadPath={survey?.upload?.path}
                doUpload={formConfig.onSubmitImage}
              />
            )}

            {/* Status */}
            <FormStack label="Status" orientation="vertical" isRequired>
              <FormikRadioGroup
                name="status"
                options={[
                  { value: 'active', label: 'Active' },
                  { value: 'inactive', label: 'Inactive' },
                ]}
              />
            </FormStack>

            {/* Name */}
            <FormStack label="Name" orientation="vertical" isRequired>
              <FormikField name="title" type="text" />
            </FormStack>

            {/* Description */}
            <FormStack label="Description" orientation="vertical" isRequired>
              <FormikField name="description" type="textarea" />
            </FormStack>

            {/* Type */}
            <FormStack label="Type" orientation="vertical" isRequired>
              <FormikField as="select" name="type">
                <option>Select type</option>
                <option value={ISurveyType.Public}>Public</option>
                <option value={ISurveyType.Private}>Private</option>
              </FormikField>
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={5}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            isLoading={formik.isSubmitting}
          >
            {type === 'create' ? 'Create Template' : 'Update Template'}
          </Button>
          {survey && (isOwner || userCan('update_survey')) && (
            <>
              <Divider mt={10} />
              <DeleteSurvey survey={survey} />
            </>
          )}
        </Form>
      )}
    </Formik>
  )
}

SurveyForm.defaultProps = {
  type: 'create',
}

export default SurveyForm
