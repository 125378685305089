import { CoreService, CoreServiceProps } from 'src/core/services'
import { IBranch } from './branch.type'
import pluralize from 'pluralize'

export class BranchService extends CoreService<IBranch> {
  constructor() {
    super('branch')
  }

  createOrUpdate(type: string, payload: IBranch): Promise<void> {
    console.log(type, payload)
    return new Promise((resolve) => resolve())
  }

  fetchByIdentifier(identifier: string, type: string): Promise<IBranch[]> {
    const formData = new FormData()
    formData.append('identifier', identifier)
    formData.append('type', type)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_${identifier}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_by_identifier`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export default BranchService
export const branchService = new BranchService()
export type BranchServiceProps = CoreServiceProps | keyof BranchService
