import { CoreService, CoreServiceProps } from 'src/core/services'

export class ConfigService extends CoreService {
  constructor() {
    super('config')
  }
}

export type ConfigServiceProps = CoreServiceProps
export default ConfigService
