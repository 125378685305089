import {
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  StackDirection,
} from '@chakra-ui/react'
import { useField } from 'formik'
import React from 'react'

type Props = {
  name: string
  label?: string
  options: { label: string; value: string }[]
  isRequired?: boolean
  direction?: StackDirection
}

export const FormikRadioGroup: React.FC<Props> = ({
  label,
  isRequired,
  options,
  direction = 'row',
  ...props
}) => {
  const [field] = useField(props)
  return (
    <>
      {!!label && (
        <FormLabel mb={0} isRequired={isRequired}>
          {label}
        </FormLabel>
      )}

      <RadioGroup {...field} id={props.name} {...props}>
        <Stack spacing={5} direction={direction}>
          {options.map(({ label, value }, index) => (
            <Radio
              key={value}
              {...field}
              value={value}
              colorScheme="primary"
              ml={index ? 3 : 0}
            >
              {label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </>
  )
}

export default FormikRadioGroup
