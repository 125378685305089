import React from 'react'
import { ErrorMessage, useField } from 'formik'
import FormField, { FormFieldProps } from '../Form/FormField'

export const FormikField: React.FC<FormFieldProps> = (props) => {
  const [field, meta, helper] = useField(props as any)

  let formikProps = {
    ...field,
    isInvalid: !!meta.touched && !!meta.error,
  }

  if (props.as === 'autocomplete') {
    formikProps.onBlur = () => {
      helper.setTouched(true)
    }
    formikProps.onChange = (selected) => {
      helper.setValue(selected)
    }
  }

  formikProps = {
    ...formikProps,
    ...props,
  }

  return (
    <>
      <FormField {...formikProps} />
      <ErrorMessage
        name={props.name}
        component="span"
        className="invalid-feedback"
      />
    </>
  )
}

export default FormikField
