import React from 'react'

import {
  Spinner,
  Flex,
  Text,
  VStack,
  Avatar,
  Button,
  Box,
} from '@chakra-ui/react'
import { Link, useHistory, useParams } from 'react-router-dom'

import { ErrorBox, Layout } from 'src/core/components'

import { IInvite } from '../invite.type'
import { inviteService } from '../invite.service'
import { useMounted } from 'src/core/hooks'
import { useSelector } from 'react-redux'
import { IStoreState } from 'src/bootstrap/store/types'
import { route } from 'src/core/helpers'
import { FiFolder } from 'react-icons/fi'
import { AcceptInviteButton } from '../components'
import Signup from 'src/packages/user/pages/Signup.page'

const Invite: React.FC = () => {
  const history = useHistory()
  const { token }: any = useParams()
  const { user, auth } = useSelector((state: IStoreState) => state)
  const isAuthenticated = !!auth.token
  const isMounted = useMounted()

  const [isLoading, setLoading] = React.useState<boolean>(false)
  const [invite, setInvite] = React.useState<IInvite>()
  const [error, setError] = React.useState<any>()
  const [isNewUser, setNewUser] = React.useState<boolean>(false)

  const hasJoined = React.useMemo(() => {
    return invite?.status === 'accepted' && invite?.invitee === user.email
  }, [invite, user])

  const readToken = React.useCallback((): void => {
    setLoading(true)
    setError(null)
    setNewUser(false)
    if (token) {
      inviteService
        .fetchByToken(token)
        .then((data) => {
          isMounted.current && setInvite(data)
          if (!isAuthenticated) {
            if (data.invitee_obj) {
              // goto login
              history.push(
                route('auth_login', null, {
                  return_url: `invitation/${data.token}`,
                  email: data.invitee_obj.email,
                })
              )
            } else {
              // show signup form
              isMounted.current && setNewUser(true)
            }
          }
        })
        .catch((err) => {
          isMounted.current && setError(err)
        })
        .finally(() => {
          isMounted.current && setLoading(false)
        })
    } else {
      isMounted.current && setError({ message: 'Invalid invite link.' })
      isMounted.current && setLoading(false)
    }
  }, [token, isMounted, history, isAuthenticated])

  React.useEffect(() => {
    readToken()
  }, [isMounted, token, readToken, isAuthenticated, history])

  return (
    <Layout
      header={
        isAuthenticated
          ? { title: `Join ${invite?.survey.title ?? '...'}` }
          : undefined
      }
      // permission="default_action"
    >
      <>
        {isLoading ? (
          <Flex justifyContent="center" py={50}>
            <Spinner color="primary.600" />
          </Flex>
        ) : error ? (
          <Flex>
            <ErrorBox summary={error.message} title="Oops!" />
          </Flex>
        ) : isNewUser ? (
          <Box>
            <VStack mb={10}>
              <Text>
                <b>{invite?.profile?.name}</b> invited you to join
              </Text>
              <Avatar
                src={invite?.survey?.upload?.path}
                icon={<FiFolder />}
                size="lg"
                bgColor="gray.50"
              />
              <Text fontSize="2xl">{invite?.survey?.title}</Text>
            </VStack>
            <Box maxW="sm" marginX="auto">
              <Signup />
            </Box>
          </Box>
        ) : (
          <VStack>
            <VStack>
              {hasJoined ? (
                <Text>
                  <b>You</b> have joined
                </Text>
              ) : (
                <Text>
                  <b>{invite?.profile.name}</b> invited you to join
                </Text>
              )}
              <Avatar
                src={invite?.survey.upload?.path}
                icon={<FiFolder />}
                size="lg"
                bgColor="gray.50"
              />
              <Text fontSize="2xl">{invite?.survey.title}</Text>
            </VStack>
            {hasJoined ? (
              <Flex pt={5}>
                <Link
                  to={route('survey_view', {
                    id: invite?.survey._id,
                    tab: 'entries',
                  })}
                >
                  <Button size="sm" colorScheme="primary">
                    Open Project
                  </Button>
                </Link>
                <Link to={route('survey')}>
                  <Button
                    ml={2}
                    size="sm"
                    variant="outline"
                    colorScheme="primary"
                  >
                    All Projects
                  </Button>
                </Link>
              </Flex>
            ) : (
              <AcceptInviteButton invite_id={`${invite?._id}`} />
            )}
          </VStack>
        )}
      </>
    </Layout>
  )
}

export default Invite
