import React from 'react'
import { Select } from '@chakra-ui/react'

import { FilterMenu } from 'src/core/components'

interface IProps {
  filters: any
  setFilters: (filters: any) => void
  activeFilters: string[]
}

export const InvitesTableFilters: React.FC<IProps> = ({
  filters,
  setFilters,
  activeFilters,
}) => {
  const resetFilters = (): void => {
    setFilters({
      status: '*',
      type: '*',
    })
  }

  return (
    <>
      <FilterMenu
        toggleButtonContent={
          activeFilters.length
            ? `Filtered by: ${activeFilters.join(', ')}`
            : 'Add Filter'
        }
        resetButtonProps={{ onClick: resetFilters }}
      >
        <FilterMenu.Section label="Status">
          <Select
            size="sm"
            value={filters.status || ''}
            onChange={({ target }) => {
              setFilters({ status: target.value, page: 1 })
            }}
          >
            <option value="*" label="All" />
            <option value="invited" label="Invited" />
            <option value="accepted" label="Accepted" />
          </Select>
        </FilterMenu.Section>

        <FilterMenu.Section label="Type">
          <Select
            size="sm"
            value={filters.type || ''}
            onChange={({ target }) => {
              setFilters({ type: target.value, page: 1 })
            }}
          >
            <option value="*" label="All" />
            <option value="email" label="Email" />
          </Select>
        </FilterMenu.Section>
      </FilterMenu>
    </>
  )
}
