import React from 'react'

import { Button, Spinner, Flex, Box } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { Layout, SearchInput } from 'src/core/components'
import useFetch from 'src/core/hooks/useFetch'
import usePermission from 'src/core/hooks/usePermission'
import { route } from 'src/core/helpers/route.helper'

import { useFilterTrees } from '../hooks/useFilterTrees'
import { TreesTable } from '../components/TreesTable'
import { ITree } from '../tree.type'
import { TreesTableFilters } from '../components/TreesTableFilter'
import { treeService } from '../tree.service'

const Locations: React.FC = () => {
  const { userCan } = usePermission()

  const [trees, isLoading] = useFetch<ITree[]>(
    treeService,
    'fetch',
    []
    // userCan('view_trees')
  )

  const { filters, setFilters, setPage, activeFilters, filteredTrees } =
    useFilterTrees(trees)

  return (
    <Layout
    //   header={{ title: `Locations ${filteredTrees?.length}` }}
    //   permission="view_trees"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <SearchInput
            placeholder="Search locations"
            value={filters.search}
            onChange={(search) => setFilters({ search, page: 1 })}
            isDisabled={isLoading || !filteredTrees}
          />

          <Box ml={2}>
            <TreesTableFilters
              filters={filters}
              setFilters={setFilters}
              activeFilters={activeFilters}
            />
          </Box>
        </Flex>

        {userCan('create_tree') && (
          <Link to={route('location_create')}>
            <Button
              size="sm"
              leftIcon={<FiPlus size={16} />}
              colorScheme="primary"
            >
              Create Location
            </Button>
          </Link>
        )}
      </Flex>

      {isLoading ? (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      ) : (
        <TreesTable
          trees={filteredTrees}
          filters={filters}
          setFilters={setFilters}
          setPage={setPage}
        />
      )}
    </Layout>
  )
}

export default Locations
