import React from 'react'

import { Text, Heading, Image } from '@chakra-ui/react'
import { Container, Box, Flex, SimpleGrid } from '@chakra-ui/layout'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { IStoreState } from 'src/bootstrap/store/types'
import { route } from 'src/core/helpers'

import dmsLogo from 'src/core/assets/images/dms-logo.svg'
import anambraLogo from 'src/core/assets/images/anambra-logo.png'
import kadunaLogo from 'src/core/assets/images/kaduna-logo.jpeg'
import collage from 'src/core/assets/images/login-image.png'
import sponsors from 'src/core/assets/images/dms-sponsor-logos.png'

export const AuthLayout: React.FC = (props) => {
  const query = new URLSearchParams(location.search)
  const returnUrl = query.get('return_url')
  const isAuthenticated = useSelector(
    (state: IStoreState) => !!state.auth.token
  )

  if (isAuthenticated) {
    return <Redirect to={returnUrl || route('survey')} />
  }

  return (
    <SimpleGrid
      columns={[1, 1, 2]}
      bgColor="gray.50"
      h="100vh"
      overflowY="hidden"
    >
      <Box
        bgImage={`url('${collage}')`}
        bgColor="primary.500"
        bgSize="contain"
      />
      <Box alignSelf="center" height="100%" overflowY="scroll">
        <Container maxW="386px" py="50px">
          <Flex flexDirection="column" alignItems="center" mb={30}>
            {process.env.REACT_APP_STATE === 'kaduna' ? (
              <Image src={kadunaLogo} width="120px" />
            ) : process.env.REACT_APP_STATE === 'anambra' ? (
              <Image src={anambraLogo} width="120px" />
            ) : (
              <Image src={dmsLogo} />
            )}

            <Heading
              mt={3}
              as="h4"
              fontSize="xl"
              fontWeight="semibold"
              color="primary.600"
              textTransform="capitalize"
            >
              {process.env.REACT_APP_STATE || ''} Data Management System
            </Heading>
          </Flex>

          {props.children}

          <Box
            p="20px"
            mt="30px"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="6px"
          >
            <Text
              fontSize="sm"
              color="gray.600"
              textAlign="center"
              letterSpacing="2px"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Powered By
            </Text>
            <Image src={sponsors} maxW="160px" margin="10px auto 0" />
          </Box>
        </Container>
      </Box>
    </SimpleGrid>
  )
}
