import React from 'react'

import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import differenceWith from 'lodash/differenceWith'

import useMounted from './useMounted'

export const useFetch = <T = any, S = string>(
  service: any,
  method: S,
  params: any[] | null = null,
  condition = true
): [T, boolean, any, React.Dispatch<T>] => {
  const isMounted = useMounted()

  params = !params?.length ? null : params

  const [data, setData] = React.useState<T | any>()
  const [error, setError] = React.useState<any>()
  const [loading, setLoading] = React.useState<boolean>(condition)

  const paramsRef = React.useRef<any[]>([])

  React.useEffect(() => {
    const fetchData = (): void => {
      setLoading(true)
      setError(null)
      setData(null)

      const call = params?.length
        ? service[method](...params)
        : service[method]()

      call
        .then((data) => {
          isMounted.current && setData(data)
        })
        .catch((error) => {
          isMounted.current && setError(error)
        })
        .finally(() => {
          isMounted.current && setLoading(false)
        })
    }

    /**
     * Deep check to see if params are different
     */
    if (params) {
      const paramsDifference = isEmpty(
        differenceWith(params, paramsRef.current, isEqual)
      )

      if (!paramsDifference) {
        paramsRef.current = params
        condition && fetchData()
      }
    } else {
      condition && fetchData()
    }
  }, [isMounted, condition, method, service, params])

  return [data, loading, error, setData]
}

export default useFetch
