import React from 'react'

import { Button, Heading, IconButton, useTheme } from '@chakra-ui/react'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { Formik, Form, FormikHelpers } from 'formik'
import { FiAtSign, FiXCircle } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import { FormikField, FormStack, Card } from 'src/core/components'
import { route } from 'src/core/helpers'
import useToast from 'src/core/hooks/useToast'
import { userService } from '../services'
import { AuthLayout } from 'src/core/components/Layout/AuthLayout'
import { useQueryParams } from 'src/core/hooks'

interface IFormValues {
  email: string
}

const Reset: React.FC = () => {
  const { colors } = useTheme()
  const { addToast } = useToast()
  const returnUrl = useQueryParams('return_url')
  const [showConfirmation, setConfirmation] = React.useState<boolean>(false)

  const formConfig = {
    initialValues: {
      email: '',
    } as IFormValues,
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Email field is required.')
        .email('Email must be valid.'),
    }),
    onSubmit: (
      values: IFormValues,
      { setSubmitting, resetForm }: FormikHelpers<IFormValues>
    ) => {
      setSubmitting(true)
      setConfirmation(false)
      userService
        .initializeReset(values.email)
        .then((data) => {
          addToast(
            data?.message ||
              `Password reset email sent to ${values.email}. Please check your inbox.`,
            { appearance: 'success' }
          )
          resetForm()
          setConfirmation(true)
        })
        .catch((error) => {
          addToast(error.message, { appearance: 'error' })
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  }

  return (
    <AuthLayout>
      <Card padding="10">
        <Flex flexDirection="column" mb={10}>
          <Heading
            as="h5"
            fontSize="xl"
            fontWeight="semibold"
            color="gray.900"
            mt="10px"
          >
            Reset your password
          </Heading>
          {showConfirmation && (
            <Flex mt={5}>
              <IconButton
                size="xs"
                variant="ghost"
                aria-label="close"
                colorScheme="critical"
                icon={<FiXCircle />}
                onClick={() => setConfirmation(false)}
              />

              <Text fontSize="sm" color="gray.600">
                Account recovery email sent to the email your provided. If you
                don’t see this email in your inbox within 15 minutes, look for
                it in your junk/spam mail folder. If you find it there, please
                mark it as &quot;<em>Not Junk/Spam</em>&quot;.
              </Text>
            </Flex>
          )}
        </Flex>

        <Formik
          initialValues={formConfig.initialValues}
          validationSchema={formConfig.validationSchema}
          validateOnMount={true}
          onSubmit={formConfig.onSubmit}
        >
          {({ ...formik }) => (
            <Form onSubmit={formik.handleSubmit}>
              <FormStack orientation="vertical">
                <FormikField
                  name="email"
                  type="email"
                  prepend={<FiAtSign color={colors.gray['600']} size={16} />}
                  placeholder="Email"
                />
              </FormStack>

              <Flex justifyContent="space-between" alignItems="center">
                <Button
                  type="submit"
                  colorScheme="primary"
                  mt={5}
                  isDisabled={formik.isSubmitting || !formik.isValid}
                  isLoading={formik.isSubmitting}
                >
                  Continue
                </Button>
              </Flex>

              <Flex flexDir="column" align="center" mt={10}>
                <Box>
                  <Link
                    to={route('auth_login', null, { return_url: returnUrl })}
                  >
                    Go back to Login
                  </Link>
                </Box>
              </Flex>
            </Form>
          )}
        </Formik>
      </Card>
    </AuthLayout>
  )
}

export default Reset
