import React from 'react'

import { Flex, Spinner } from '@chakra-ui/react'

import { ErrorBox } from 'src/core/components'

import { choiceService } from '../choice.service'
import { IChoice } from '../choice.type'
import { IQuestion } from 'src/packages/question/question.type'
import ChoiceForm from './ChoiceForm'

interface ChoiceFormListProps {
  question: IQuestion
}

const ChoiceFormList: React.FC<ChoiceFormListProps> = ({ question }) => {
  const [loadingChoices, setLoadingChoices] = React.useState<boolean>(false)
  const [error, setError] = React.useState<any>()
  const [choices, setChoices] = React.useState<IChoice[]>([])

  const onUpdate = (choiceUpdate: IChoice): void => {
    setChoices((prevChoices) => {
      const newChoices: IChoice[] = [...prevChoices]
      const affectedIndex = newChoices?.findIndex(
        (choice) => choice._id === choiceUpdate._id
      )
      if (affectedIndex > -1) {
        newChoices[affectedIndex] = choiceUpdate
      } else {
        newChoices.push(choiceUpdate)
      }
      return newChoices
    })
  }

  const onDelete = (choiceUpdate: IChoice): void => {
    setChoices((prevChoices) => {
      const newChoices: IChoice[] = [...prevChoices].filter(
        (choice) => choice._id !== choiceUpdate._id
      )
      return newChoices
    })
  }

  React.useEffect(() => {
    if (question) {
      setLoadingChoices(true)
      setError(null)
      choiceService
        .fetchByQuestion(question?._id || '')
        .then((data) => {
          setChoices(data)
        })
        .catch((err) => {
          setError(err)
        })
        .finally(() => {
          setLoadingChoices(false)
        })
    }
  }, [question])

  return (
    <>
      {loadingChoices ? (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      ) : error ? (
        <ErrorBox />
      ) : (
        choices.map((choice, index) => (
          <ChoiceForm
            key={choice._id}
            type="update"
            question={question}
            choice={choice}
            onUpdate={onUpdate}
            onDelete={onDelete}
            rowIndex={index + 1}
          />
        ))
      )}

      <ChoiceForm type="create" question={question} onUpdate={onUpdate} />
    </>
  )
}

ChoiceFormList.defaultProps = {}

export default ChoiceFormList
