import React from 'react'

import { Button, Spinner, Flex } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { ErrorBox, ExportButton, SearchInput } from 'src/core/components'
import { route } from 'src/core/helpers/route.helper'

import { useFilterProfileAnswers } from '../hooks/useFilterAnswers'
import { IAnswerProfileSummaryDict } from '../answer.type'
import { answerService } from '../answer.service'
import { useMounted, usePermission } from 'src/core/hooks'
import { ProfileAnswersTable } from '../components/ProfileAnswersTable'
import { ISurvey } from 'src/packages/survey/survey.type'
import { useSelector } from 'react-redux'
import { IStoreState } from 'src/bootstrap/store/types'
import { useAnswerSummaryTable } from '../hooks/useAnswerSummaryTable'

const ProfileAnswers: React.FC<{ survey: ISurvey }> = ({ survey }) => {
  const survey_id = survey._id
  const { user } = useSelector((state: IStoreState) => state)
  const { userCan } = usePermission()
  const isMounted = useMounted()

  const [isLoading, setLoading] = React.useState(false)
  const [hasError, setError] = React.useState<any>(null)
  const [profileAnswersDict, setProfileAnswersDict] =
    React.useState<IAnswerProfileSummaryDict>({})

  const answerSummaryTable = useAnswerSummaryTable(profileAnswersDict)

  const { filters, setFilters, setPage, filteredAnswers } =
    useFilterProfileAnswers(answerSummaryTable)

  const isOwner = React.useMemo(() => {
    return user?._id === survey.profile?._id
  }, [user, survey])

  const fetchData = React.useCallback((): void => {
    setLoading(true)
    setError(null)
    answerService
      .fetchBySurveyProfileSummary(`${survey_id}`)
      .then((data) => {
        isMounted.current && setProfileAnswersDict(data)
      })
      .catch((error) => {
        isMounted.current && setError(error)
      })
      .finally(() => {
        isMounted.current && setLoading(false)
      })
  }, [survey_id, isMounted])

  React.useEffect(() => {
    survey_id && fetchData()
  }, [survey_id, fetchData])

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <SearchInput
            placeholder="Search entries"
            value={filters.search}
            onChange={(search) => setFilters({ search, page: 1 })}
            isDisabled={isLoading || !filteredAnswers}
          />
        </Flex>

        <Flex alignItems="center">
          <Link to={route('submit_answer', { id: survey_id })}>
            <Button
              size="sm"
              leftIcon={<FiPlus size={16} />}
              colorScheme="primary"
            >
              Create Entry
            </Button>
          </Link>
          {(isOwner || userCan('update_survey')) && answerSummaryTable.body && (
            <ExportButton
              filename={`dms_export_${survey.title}`}
              exportData={answerSummaryTable.body}
              isDisabled={!answerSummaryTable.body?.length}
              ml={2}
            />
          )}
        </Flex>
      </Flex>

      {isLoading ? (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      ) : hasError ? (
        <ErrorBox summary={hasError?.message} my={50} />
      ) : (
        <>
          <ProfileAnswersTable
            profileAnswers={{
              header: answerSummaryTable.header,
              body: filteredAnswers,
            }}
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </>
      )}
    </>
  )
}

export default ProfileAnswers
