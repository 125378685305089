import React from 'react'

import { Flex, SimpleGrid, Spinner } from '@chakra-ui/react'

import { Column, ErrorBox } from 'src/core/components'

import { questionService } from '../question.service'
import { IQuestion } from '../question.type'
import { ISurvey } from 'src/packages/survey/survey.type'
import QuestionForm from './QuestionForm'
import { useMounted, useToast } from 'src/core/hooks'
import { sortBy } from 'lodash'

interface QuestionFormListProps {
  survey: ISurvey
  columns?: number[]
}

const QuestionFormList: React.FC<QuestionFormListProps> = ({
  survey,
  columns,
}) => {
  const { toast } = useToast()
  const isMounted = useMounted()
  const [loadingQuestions, setLoadingQuestions] = React.useState<boolean>(false)
  const [movingIndex, setMovingIndex] = React.useState<boolean>(false)
  const [error, setError] = React.useState<any>()
  const [questions, setQuestions] = React.useState<IQuestion[]>([])

  const sortedQuestions = React.useMemo<IQuestion[]>(() => {
    return sortBy(questions, 'index')
  }, [questions])

  const onUpdate = (questionUpdate: IQuestion): void => {
    setQuestions((prevQuestions) => {
      const newQuestions: IQuestion[] = [...prevQuestions]
      const affectedIndex = newQuestions?.findIndex(
        (question) => question._id === questionUpdate._id
      )
      if (affectedIndex > -1) {
        newQuestions[affectedIndex] = questionUpdate
      } else {
        newQuestions.push(questionUpdate)
      }

      return newQuestions
    })
  }

  const onDelete = (questionUpdate: IQuestion): void => {
    setQuestions((prevQuestions) => {
      const newQuestions: IQuestion[] = prevQuestions.filter(
        (question) => question._id !== questionUpdate._id
      )
      return newQuestions
    })
  }

  const fetchQuestions = React.useCallback((): void => {
    setLoadingQuestions(true)
    setError(null)
    questionService
      .fetchBySurvey(survey?._id || '')
      .then((data) => {
        isMounted.current && setQuestions(data)
      })
      .catch((err) => {
        isMounted.current && setError(err)
      })
      .finally(() => {
        isMounted.current && setLoadingQuestions(false)
      })
  }, [isMounted, survey._id])

  const moveIndex = React.useCallback(
    (payload: IQuestion, index: number, direction: 'up' | 'down'): void => {
      setMovingIndex(true)
      const proposedIndex = direction === 'up' ? index - 1 : index + 1
      const oldIndex = index

      let newPayload = Object.assign({}, payload)
      delete newPayload['created']
      delete newPayload['modified']
      delete newPayload['upload']
      delete newPayload.choices

      let displaced = Object.assign({}, sortedQuestions[proposedIndex])
      delete displaced['created']
      delete displaced['modified']
      delete displaced['upload']
      delete displaced.choices

      displaced = { ...displaced, index: oldIndex }
      newPayload = { ...newPayload, index: proposedIndex }

      Promise.all([
        questionService.update(displaced),
        questionService.update(newPayload),
      ])
        .then(() => {
          newPayload.index = proposedIndex
          isMounted.current &&
            setQuestions(
              sortedQuestions.map((question) => {
                if (question._id === newPayload._id) {
                  return { ...question, ...newPayload }
                }
                if (question._id === displaced._id) {
                  return { ...question, ...displaced }
                }
                return question
              })
            )
        })
        .catch((err) => {
          toast({
            description: err.message,
            status: 'error',
          })
        })
        .finally(() => {
          isMounted.current && setMovingIndex(false)
        })
    },
    [isMounted, sortedQuestions, toast]
  )

  React.useEffect(() => {
    if (survey) {
      fetchQuestions()
    }
  }, [survey, fetchQuestions])

  return (
    <>
      {loadingQuestions ? (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      ) : error ? (
        <ErrorBox />
      ) : (
        <SimpleGrid columns={columns || [1, 1, 2, 2]} columnGap={5} rowGap={5}>
          {sortedQuestions.map((question, index) => (
            <Column key={question._id}>
              <QuestionForm
                index={index}
                questionCount={questions.length}
                type="update"
                survey={survey}
                question={question}
                onUpdate={onUpdate}
                onDelete={onDelete}
                rowIndex={index + 1}
                movingIndex={movingIndex}
                moveIndex={moveIndex}
              />
            </Column>
          ))}
        </SimpleGrid>
      )}

      <QuestionForm
        index={questions.length}
        questionCount={questions.length}
        type="create"
        survey={survey}
        onUpdate={onUpdate}
      />
    </>
  )
}

QuestionFormList.defaultProps = {}

export default QuestionFormList
