import { ComponentClass, FC } from 'react'

import Error404 from 'src/core/pages/404.page'
import answerRoutes from 'src/packages/answer/answer.route'
import branchRoutes from 'src/packages/branch/branch.route'

import { configRoutes } from 'src/packages/config'
import { dashboardRoutes } from 'src/packages/dashboard'
import { inviteRoutes } from 'src/packages/invite'
import surveyRoutes from 'src/packages/survey/survey.route'
import treeRoutes from 'src/packages/tree/tree.route'
import { userRoutes } from 'src/packages/user'

export interface IRoute {
  id: string
  path: string
  component: ComponentClass<any, any> | FC<any>
  requiresAuth?: boolean
  exact?: boolean
}

let prepRoutes: IRoute[] = []

const registerRouteGroup = (routeGroup: IRoute[]): void => {
  if (routeGroup?.length) {
    prepRoutes = [...prepRoutes, ...routeGroup]
  }
}

registerRouteGroup(configRoutes)
registerRouteGroup(dashboardRoutes)
registerRouteGroup(userRoutes)
registerRouteGroup(treeRoutes)
registerRouteGroup(surveyRoutes)
registerRouteGroup(answerRoutes)
registerRouteGroup(inviteRoutes)
registerRouteGroup(branchRoutes)

export const routes: IRoute[] = [
  ...prepRoutes,
  {
    id: 'error_404',
    path: '*',
    component: Error404,
  },
]
