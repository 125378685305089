import { CoreService, CoreServiceProps } from 'src/core/services'
import { IQuestion } from './question.type'
import pluralize from 'pluralize'

export class QuestionService extends CoreService<IQuestion> {
  constructor() {
    super('question')
  }

  fetchBySurvey(parent_id: string): Promise<IQuestion[]> {
    const formData = new FormData()
    formData.append('parent_id', parent_id)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_${parent_id}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_by_parent`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  moveIndex(payload: {
    questionId: string
    proposedIndex: number
  }): Promise<void> {
    const { questionId, proposedIndex } = payload

    const formData = new FormData()
    formData.append('_id', questionId)
    formData.append('proposed_index', `${proposedIndex}`)

    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(`${this.model}/move_index`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              this.cache.reset()
              resolve()
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default QuestionService
export const questionService = new QuestionService()
export type QuestionServiceProps = CoreServiceProps | keyof QuestionService
