import { ComponentStyleConfig } from '@chakra-ui/theme'

export const Tabs: ComponentStyleConfig = {
  parts: ['tabs', 'tabList', 'tab'],
  baseStyle: {
    tab: {
      mr: '15px !important',
      pl: '0 !important',
      pr: '0 !important',
      fontWeight: 'medium',
      color: 'gray.600',
    },
  },
  sizes: {
    md: {
      tab: {
        fontSize: '14px',
        borderBottom: '0',
      },
      _selected: {
        color: 'black',
      },
      _active: {
        bg: 'transparent',
      },
    },
  },
  variants: {
    line: {
      tab: {
        transition: 'all 400ms',
        _hover: {
          color: 'body',
        },
        _focus: {
          bg: 'transparent',
          boxShadow: 'none',
        },
        _selected: {
          color: 'body',
          borderColor: 'primary.600',
        },
      },
    },
  },
  defaultProps: {
    colorScheme: 'primary',
  },
}
