import React from 'react'

import { Spinner, Flex } from '@chakra-ui/react'
import { ListGroup } from 'react-bootstrap'

import { Layout, SearchInput, ErrorBox } from 'src/core/components'
import ConfigForm from '../components/ConfigForm'

import useFetch from 'src/core/hooks/useFetch'

import { ConfigService, ConfigServiceProps } from '../config.service'
import { basicSearch } from 'src/core/helpers/search.helper'

const configService = new ConfigService()

const ConfigWizard: React.FC = () => {
  const [search, setSearch] = React.useState<string>('')

  const [list, isLoading, , setList] = useFetch<any, ConfigServiceProps>(
    configService,
    'fetch'
  )

  const filteredList = React.useMemo(() => {
    let filtered = list || []

    if (filtered?.length) {
      if (search) {
        filtered = filtered.filter((config) => {
          return basicSearch(config.key, search)
        })
      }
    }

    return filtered
  }, [list, search])

  return (
    <Layout header={{ title: 'Config Wizard' }} permission="update_config">
      <ListGroup>
        <ListGroup.Item>
          <SearchInput
            placeholder="Search keys..."
            value={search}
            onChange={setSearch}
            isDisabled={isLoading || !list?.length}
          />
        </ListGroup.Item>

        {isLoading && (
          <Flex justifyContent="center" py={50}>
            <Spinner color="primary.600" />
          </Flex>
        )}

        {!isLoading && !filteredList?.length ? (
          <ErrorBox summary="No config found" my={50} />
        ) : null}

        {!isLoading && filteredList?.length
          ? filteredList.map((config) => (
              <ListGroup.Item key={config._id}>
                <ConfigForm
                  type="update"
                  config={config}
                  updateConfig={(config) => {
                    const tempList = list.map((item) => {
                      return item._id === config._id
                        ? { ...item, ...config }
                        : item
                    })
                    setList(tempList)
                  }}
                  removeConfig={(config) => {
                    const tempList = list.filter((item) => {
                      return item._id !== config._id
                    })
                    setList(tempList)
                  }}
                />
              </ListGroup.Item>
            ))
          : null}

        <ListGroup.Item className="odd">
          <ConfigForm
            type="create"
            addConfig={(config) => {
              setList([...list, config])
            }}
          />
        </ListGroup.Item>
      </ListGroup>
    </Layout>
  )
}

export default ConfigWizard
