import React from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
} from '@chakra-ui/react'

interface IProps {
  isOpen: boolean
  onClose: () => void
  header?: string
  description: string | React.ReactNode
  buttons?: { label: string; props?: ButtonProps; isClose?: boolean }[]
}

const Alert: React.FC<IProps> = (props) => {
  const { isOpen, onClose, header, description, buttons } = props
  const cancelRef = React.useRef<any>()

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogCloseButton />
          {!!header && (
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {header}
            </AlertDialogHeader>
          )}
          <AlertDialogBody>{description}</AlertDialogBody>
          {props?.buttons?.length && (
            <AlertDialogFooter>
              {buttons?.map((button, index) => (
                <Button
                  key={index}
                  ref={button.isClose ? cancelRef : undefined}
                  onClick={button.isClose ? onClose : button?.props?.onClick}
                  ml={3}
                  {...button.props}
                >
                  {button.label}
                </Button>
              ))}
            </AlertDialogFooter>
          )}
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default Alert
