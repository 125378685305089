import React from 'react'

import { Box } from '@chakra-ui/layout'

import { Header, HeaderProps } from './Header'
import { ErrorBox } from '../ErrorBox'
import usePermission from 'src/core/hooks/usePermission'
import { useTheme } from '@chakra-ui/system'
import { Navbar } from './Navbar'

export interface LayoutProps {
  header?: HeaderProps // deprecated
  headerProps?: HeaderProps
  permission?: string
  hasGutter?: boolean
}

/**
 * Default page layout.
 */
export const Layout: React.FC<LayoutProps> = ({
  header,
  permission,
  hasGutter,
  children,
}) => {
  const { sizes } = useTheme()
  const { userCan } = usePermission()

  return (
    <>
      {!header?.title && <Navbar />}
      {!permission || (permission && userCan(permission)) ? (
        <>
          {header?.title && <Header {...header} />}
          <Box p={hasGutter ? sizes.gutter.md : '0'} width="100%" bg="white">
            {children}
          </Box>
        </>
      ) : (
        <ErrorBox type="authorization" my={150} reference={permission} />
      )}
    </>
  )
}

Layout.defaultProps = {
  hasGutter: true,
}

export default Layout
