import React from 'react'
import {
  Box,
  Flex,
  Spinner,
  Text,
  SimpleGrid,
  Button,
  Image,
  Heading,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react'
import {
  ResponsiveContainer,
  PieChart,
  Legend,
  Pie,
  Cell,
  Tooltip,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
} from 'recharts'

import {
  ErrorBox,
  Column,
  Card,
  CardHeader,
  CardBody,
} from 'src/core/components'

import ReactToPrint from 'react-to-print'

import useMounted from 'src/core/hooks/useMounted'
import { answerService } from '../../../answer/answer.service'
import { useSurveySummaryData } from '../../hooks/useSurveySummaryData'
import { ISurvey, ISurveySummary } from 'src/packages/survey/survey.type'
import { IQuestionType } from 'src/packages/question'
import { FiDownload } from 'react-icons/fi'

import dmsLogo from 'src/core/assets/images/dms-logo.svg'
import anambraLogo from 'src/core/assets/images/anambra-logo.png'
import kadunaLogo from 'src/core/assets/images/kaduna-logo.jpeg'

const pageDimensions = {
  marginTop: '0.5in',
  marginRight: '0.5in',
  marginBottom: '0.5in',
  marginLeft: '0.5in',
}

const getPageMargins = (): string => {
  return `
    @page { 
      margin: 
      ${pageDimensions.marginTop} 
      ${pageDimensions.marginRight} 
      ${pageDimensions.marginBottom} 
      ${pageDimensions.marginLeft} !important;
      background-color: red;
    }
    .pdf-header {
      height: 0;
      overflow: hidden;
    }
    @media print {
      .pdf-header {
        height: auto;
        overflow: visible;
        margin-bottom: 30px;
      }
    }
  `
}

export const SurveySummaryGraphs: React.FC<{ survey: ISurvey }> = ({
  survey,
}) => {
  const isMounted = useMounted()
  const gridRef: any = React.useRef<any>()

  const [isLoading, setIsLoading] = React.useState(true)
  const [summaryData, setSummaryData] = React.useState<ISurveySummary>()
  const summaryChartData = useSurveySummaryData(summaryData)
  const [isProfileLocation, setProfileLocation] = React.useState<boolean>(true)

  React.useEffect(() => {
    const fetchAnswers = (): void => {
      setIsLoading(true)

      answerService
        .fetchBySurveySummary(`${survey._id}`, isProfileLocation)
        .then((data) => {
          isMounted.current && setSummaryData(data)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    fetchAnswers()
  }, [isMounted, survey._id, isProfileLocation])

  const answerChartColors = [
    '#d8003c',
    '#FFBB28',
    '#FF8042',
    '#0088FE',
    '#00C49F',
    '#148e55',
  ]

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }): any => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x > cx ? x + 10 : x - 10}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(1)}%`}
      </text>
    )
  }

  return (
    <>
      {isLoading ? (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      ) : summaryChartData && summaryChartData.length ? (
        <>
          <Flex align="center" justify="space-between">
            <Box>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="analysis-type-toggle" mb="0">
                  Use Profile Location
                </FormLabel>
                <Switch
                  id="analysis-type-toggle"
                  isChecked={isProfileLocation}
                  onChange={() => {
                    setProfileLocation((prev) => !prev)
                  }}
                />
              </FormControl>
            </Box>
            <Box>
              <ReactToPrint
                trigger={() => {
                  return (
                    <Button size="sm" leftIcon={<FiDownload />}>
                      Export PDF
                    </Button>
                  )
                }}
                content={() => gridRef.current}
              />
            </Box>
          </Flex>
          <div
            ref={(ref) => {
              gridRef.current = ref
            }}
          >
            <style>{getPageMargins()}</style>

            <Flex
              className="pdf-header"
              flexDirection="column"
              alignItems="center"
            >
              {process.env.REACT_APP_STATE === 'kaduna' ? (
                <Image src={kadunaLogo} width="120px" />
              ) : process.env.REACT_APP_STATE === 'anambra' ? (
                <Image src={anambraLogo} width="80px" />
              ) : (
                <Image src={dmsLogo} />
              )}

              <Heading
                mt={3}
                as="h4"
                fontSize="xl"
                fontWeight="semibold"
                color="primary.600"
                textTransform="capitalize"
              >
                {process.env.REACT_APP_STATE || ''} Data Management System
              </Heading>

              <Heading
                mt={1}
                as="h5"
                fontSize="lg"
                fontWeight="semibold"
                color="dark.600"
              >
                {survey?.title}
              </Heading>
            </Flex>

            <SimpleGrid columns={2} columnGap={5}>
              {summaryChartData.map((chartData, index) => (
                <Column size={8} key={index} mt={5}>
                  <Card>
                    <CardHeader>
                      <Text fontWeight="medium">
                        {chartData.question.title}
                      </Text>
                    </CardHeader>
                    <CardBody>
                      {!isLoading && chartData.stat.length ? (
                        <>
                          {chartData.question.type ===
                          IQuestionType.MultiChoice ? (
                            <ResponsiveContainer height={300}>
                              <PieChart onMouseEnter={() => ({})}>
                                <Legend verticalAlign="top" />
                                <Tooltip
                                  formatter={(value) => {
                                    return `${value}%`
                                  }}
                                />
                                <Pie
                                  data={chartData.stat}
                                  isAnimationActive={false}
                                  dataKey="value"
                                  nameKey="label"
                                  legendType="circle"
                                  innerRadius="35%"
                                  outerRadius="70%"
                                  fill="#8884d8"
                                  label={renderCustomizedLabel}
                                  labelLine={false}
                                >
                                  {chartData.stat.map((stat, index) => (
                                    <Cell
                                      key={`cell-${index}`}
                                      fill={
                                        stat.label === 'unknown'
                                          ? '#9da5b0'
                                          : answerChartColors[
                                              index % answerChartColors.length
                                            ]
                                      }
                                    />
                                  ))}
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>
                          ) : (
                            <ResponsiveContainer width="100%" height={300}>
                              <BarChart
                                width={730}
                                height={250}
                                data={chartData.stat}
                                margin={{
                                  left: 10,
                                  bottom: 50,
                                }}
                              >
                                <Legend wrapperStyle={{ bottom: 0 }} />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip />
                                <Bar
                                  dataKey="value"
                                  dataName="label"
                                  name="Entry"
                                  fill="#8884d8"
                                />
                                <XAxis
                                  dataKey="label"
                                  angle={-45}
                                  textAnchor="end"
                                  tick={{
                                    fontSize: '14px',
                                  }}
                                />
                                <YAxis
                                  label={{
                                    value: 'No. of Occurence',
                                    angle: -90,
                                    fontSize: '12px',
                                    position: 'left',
                                  }}
                                  tick={{
                                    top: 100,
                                  }}
                                  dataKey="value"
                                />
                              </BarChart>
                            </ResponsiveContainer>
                          )}
                        </>
                      ) : null}

                      {isLoading && (
                        <Flex justifyContent="center" py={50}>
                          <Spinner color="primary.600" />
                        </Flex>
                      )}
                    </CardBody>
                  </Card>
                </Column>
              ))}
            </SimpleGrid>
          </div>
        </>
      ) : (
        <Column my={50}>
          <ErrorBox
            title="No data to display"
            summary="Submit entries to start showing graphs and charts."
          />
        </Column>
      )}
    </>
  )
}
