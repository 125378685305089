import { IUser } from '../types/user.type'
import cache from 'src/core/helpers/cache.helper'
import { http, setToken } from 'src/core/helpers/http.helper'
import { permissions } from 'src/bootstrap/permissions'

export class UserService {
  /**
   * Filter out user permissions, map to retain only permisison ID
   */
  private _setPermissions = (userRole: string): string[] => {
    return permissions
      .filter((permission: any) => {
        return permission.roles.includes(userRole)
      })
      .map((permission: any) => permission.id)
  }

  /**
   *
   * @param credentials
   * @returns
   */
  private doLoginDelegate = (credentials: {
    email: string
    hash: string
    remember: string
  }): Promise<IUser> => {
    const formData = new FormData()
    formData.append('email', credentials.email)
    formData.append('hash', credentials.hash)
    formData.append('remember', credentials.remember)

    return new Promise((resolve, reject) => {
      try {
        http.post(`administrator/login_delegate`, formData).then(({ data }) => {
          if (data.code === 200) {
            const { token } = data.data
            localStorage.setItem('token', JSON.stringify(token))

            const user = data.data.profile
            const role = data.data.role
            const _permissions = this._setPermissions(role)
            setToken(token)
            resolve({ ...user, role, permissions: _permissions, token })
          } else {
            reject({ message: data.message })
          }
        })
      } catch (error) {
        reject({ message: 'An unexpected error has occurred' })
        throw error
      }
    })
  }

  /**
   * User Login
   * @param credentials email<email>, hash<string>, remember<boolean>
   * @returns IUserProfile
   */
  public doLogin = (credentials: {
    email: string
    hash: string
    remember: string
  }): Promise<IUser> => {
    return this.doLoginDelegate(credentials)
  }

  /**
   * User Signup
   * @param credentials email<email>, hash<string>, remember<boolean>
   * @returns IUserProfile
   */
  public doSignup = (credentials: {
    email: string
    hash: string
    phone: string
    name: string
    role: 'default'
    remember: string
  }): Promise<IUser> => {
    const form = new FormData()
    form.append('email', credentials.email)
    form.append('hash', credentials.hash)
    form.append('phone', credentials.phone)
    form.append('name', credentials.name)
    form.append('role', credentials.role)
    form.append('remember', credentials.remember)
    form.append('status', 'active')

    return new Promise((resolve, reject) => {
      http.post('/profile/signup', form).then(({ data }) => {
        if (data.code === 200 && data.message) {
          this.doLoginDelegate({
            email: credentials.email,
            hash: credentials.hash,
            remember: credentials.remember,
          })
            .then((user) => {
              resolve(user)
            })
            .catch((error) => reject(error))
        } else
          reject({
            message: data.message || 'An unexpected error has occurred',
          })
      })
    })
  }

  /**
   * Revoke user auth token and logout
   */
  public doLogout = (): Promise<void> => {
    const token = localStorage.getItem('token')
    return new Promise((resolve) => {
      http
        .get(`user_session/invalidate?token=${JSON.parse(token || '')}`)
        .finally(() => {
          cache.reset()
          resolve()
        })
    })
  }

  /**
   * Fetch user (with token) and assign user permissions
   */
  fetchUser = (token: string): Promise<IUser> => {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get('current_user')
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .get(`administrator/read_delegate/?token=${token}`)
            .then(({ data }) => {
              if (data.code === 200) {
                const user = data.data.profile
                const role = data.data.role
                const _permissions = this._setPermissions(role)

                cache.set('current_user', {
                  ...user,
                  role,
                  permissions: _permissions,
                })
                resolve({ ...user, role, permissions: _permissions })
              } else {
                reject({ message: data.message })
              }
            })
        } catch (error) {
          reject({ message: 'An unexpected error has occurred' })
          throw error
        }
      }
    })
  }

  /**
   * Change user password.
   * Type 'update' doese not require old password,
   * 'change' requires ir
   *
   * @param type
   * @param credentials
   */
  public updatePassword(
    type: 'change' | 'update',
    credentials: any
  ): Promise<void> {
    const formData = new FormData()
    formData.append('_id', credentials.user_id)

    if (type === 'update') {
      formData.append('hash', credentials.hash_new)
    } else if (type === 'change') {
      formData.append('hash_old', credentials.hash_old)
      formData.append('hash_new', credentials.hash_new)
    }

    return new Promise((resolve, reject) => {
      try {
        http.post(`user/${type}_auth`, formData).then(({ data }) => {
          if (data.code === 200) resolve()
          else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occurred!' })
        throw error
      }
    })
  }

  /**
   * Initialize Password Reset
   * @param email
   */
  initializeReset(email: string): Promise<any> {
    const formData = new FormData()
    formData.append('email', email)

    return new Promise((resolve, reject) => {
      try {
        http.post(`profile/reset_auth`, formData).then(({ data }) => {
          if (data.code === 200) resolve(data)
          else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occurred!' })
        throw error
      }
    })
  }

  completeReset(payload: { token: string; hash: string }): Promise<boolean> {
    const formData = new FormData()
    formData.append('token', payload.token)
    formData.append('hash', payload.hash)
    return new Promise((resolve, reject) => {
      try {
        http
          .post(`profile/reset_auth_followthrough`, formData)
          .then(({ data }) => {
            if (data.code === 200 && data.message === true) resolve(true)
            else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occurred!' })
        throw error
      }
    })
  }
}

export const userService = new UserService()
export default UserService
