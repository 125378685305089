import React from 'react'
import { useHistory } from 'react-router'

import { Box, Button, Divider, HStack, Icon, Text } from '@chakra-ui/react'
import { Layout } from 'src/core/components'
import SurveyForm from '../components/SurveyForm'
import { FiCheckCircle, FiChevronRight } from 'react-icons/fi'
import { ISurvey } from '../survey.type'
import QuestionFormList from 'src/packages/question/components/QuestionFormList'
// import BranchForm from 'src/packages/branch/components/BranchForm'
import { route } from 'src/core/helpers'

const SurveySetup: React.FC = () => {
  const history = useHistory()
  const [step, setStep] = React.useState(0)
  const [survey, setSurvey] = React.useState<ISurvey>()

  const completeSetup = (branchId?: string): void => {
    if (branchId) {
      history.push(route('survey', { id: survey?._id, tab: 'invites' }))
    }
  }

  const activeStep = React.useCallback<any>(
    (currentStep: number) => {
      return {
        color: step > currentStep - 1 ? `gray.900` : 'gray.500',
        fontWeight: step > currentStep - 1 ? 'semibold' : 'medium',
      }
    },
    [step]
  )

  return (
    <Layout
      header={{
        title: `New Project`,
        backText: step === 0 ? 'Projects' : undefined,
        onBack:
          step === 0
            ? () => {
                history.goBack()
              }
            : undefined,
      }}
      // permission="create_tree"
    >
      <Box maxW="sm" marginX="auto">
        <HStack gridGap="2" divider={<FiChevronRight color="gray.500" />}>
          <Text {...activeStep(0)}>1. Project Info</Text>
          <Text {...activeStep(1)}>2. Data Fields</Text>
          <Text {...activeStep(2)}>3. Publish</Text>
        </HStack>

        {step === 0 && (
          <>
            <Text fontSize="md" lineHeight="tall" my={3}>
              Setup a new project with details about the project - title,
              description and status.
            </Text>
            <Divider />
            <SurveyForm
              type="create"
              onUpdate={(survey_payload) => {
                setSurvey(survey_payload)
                setStep(1)
              }}
            />
          </>
        )}

        {step === 1 && survey && (
          <>
            <Text fontSize="md" lineHeight="tall" my={3}>
              Create data fields for this project.
            </Text>
            <Divider />
            <QuestionFormList survey={survey} columns={[1]} />
            <Divider my={5} />
            <Button
              variant="solid"
              colorScheme="primary"
              onClick={() => setStep(2)}
            >
              Next
            </Button>
          </>
        )}

        {step === 2 && (
          <Box textAlign="center">
            {/* <Text fontSize="md" lineHeight="tall" my={3}>
              Make this survey available to a location of choice.
            </Text>
            <Divider />
            <BranchForm
              identifier={survey?._id || ''}
              identifierType="survey"
              type="create"
              onUpdate={completeSetup}
            /> */}
            <Text align="center" pt={10} color="success.500">
              <Icon as={FiCheckCircle} boxSize={16} alignSelf="center" />
            </Text>
            <Text
              align="center"
              fontSize="xl"
              fontWeight="semibold"
              lineHeight="tall"
              my={3}
            >
              Bravo!
            </Text>
            <Text
              align="center"
              fontSize="md"
              color="gray.600"
              lineHeight="tall"
              my={3}
            >
              You have successfully created a project. Start inputting entries
              to experience awesomeness. You can also invite other people by
              sharing your project.
            </Text>
            <Button
              variant="outline"
              colorScheme="primary"
              onClick={() => {
                completeSetup('no-id')
              }}
            >
              Start
            </Button>
          </Box>
        )}
      </Box>
    </Layout>
  )
}

export default SurveySetup
