import { ComponentStyleConfig } from '@chakra-ui/theme'

export const Input: ComponentStyleConfig = {
  parts: ['field', 'icon'],
  baseStyle: {
    icon: {
      color: 'gray.300',
    },
    field: {
      _placeholder: {
        color: 'gray.600',
      },
    },
  },
  sizes: {
    sm: {
      field: {
        borderRadius: 'md',
        fontSize: 'md',
        height: '34px',
      },
    },
  },
  variants: {
    outline: {
      field: {
        // transition: 'all 400ms',
        _focus: {
          // borderColor: 'primary.600',
          boxShadow: 'var(--chakra-shadows-outline)',
        },
      },
    },
  },
}

export const Select: ComponentStyleConfig = {
  parts: ['select', 'field', 'icon'],
  baseStyle: {
    icon: {
      color: 'gray.400',
    },
  },
  sizes: {
    sm: {
      field: {
        borderRadius: 'md',
        fontSize: 'md',
        size: 'md',
      },
    },
  },
  variants: {
    outline: {
      field: {
        _focus: {
          // borderColor: 'primary.600',
          boxShadow: 'var(--chakra-shadows-outline)',
        },
      },
    },
  },
}

export const Switch: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: 'primary',
  },
}

export const Checkbox: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: 'primary',
  },
}
