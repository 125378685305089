import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import React from 'react'
import { FiPlus } from 'react-icons/fi'
import { IBranch } from 'src/packages/branch'
import BranchForm from 'src/packages/branch/components/BranchForm'
import { ISurvey } from 'src/packages/survey/survey.type'
import { SurveyBranchContext } from '../../contexts'

interface IProps extends ButtonProps {
  title?: string
  survey: ISurvey
  branch?: IBranch
}

const SurveyBranchButton: React.FC<IProps> = ({
  title,
  survey,
  branch,
  ...props
}) => {
  const { setBranches } = React.useContext(SurveyBranchContext)
  const [visible, setVisible] = React.useState<boolean>(false)
  const toggleVisible = (): any => {
    setVisible(!visible)
  }

  const onUpdate = (payload?: IBranch): void => {
    if (setBranches && payload) {
      setBranches((prev) => {
        const existingIndex = prev.findIndex((item) => item._id === payload._id)
        const next = [...prev]
        if (existingIndex > -1) {
          console.log(next[existingIndex], payload)
          next[existingIndex] = payload
        } else {
          next.push(payload)
        }
        return next
      })
    }
    toggleVisible()
  }

  return (
    <>
      <Modal isOpen={visible} onClose={toggleVisible}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Create Member: {survey?.title}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <BranchForm
              identifier={`${survey?._id}`}
              identifierType="survey"
              type={branch ? 'update' : 'create'}
              branch={branch}
              onUpdate={onUpdate}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Button
        size="sm"
        leftIcon={<FiPlus size={16} />}
        onClick={toggleVisible}
        {...props}
      >
        {title}
      </Button>
    </>
  )
}

SurveyBranchButton.defaultProps = {
  title: 'Create',
}

export default SurveyBranchButton
