import React from 'react'

import { HStack, Text, SimpleGrid, FormLabel } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/layout'

import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import * as Yup from 'yup'

import {
  FormikRadioGroup,
  FormikField,
  FormStack,
  ImageContainer,
  Column,
} from 'src/core/components'
import { useToast } from 'src/core/hooks'

import { answerService } from '../answer.service'
import {
  IQuestion,
  IQuestionType,
  questionService,
} from 'src/packages/question'
import { ISurvey } from 'src/packages/survey/survey.type'
import { useSelector } from 'react-redux'
import { IStoreState } from 'src/bootstrap/store/types'
import AnswerConfirmationButton from './AnswerConfirmationButton'
import { sortBy } from 'lodash'
import { FiEdit3, FiCheckCircle } from 'react-icons/fi'

interface SubmitAnswerFormProps {
  survey: ISurvey
  onSubmit?: (payload: any) => void
}

const SubmitAnswerForm: React.FC<SubmitAnswerFormProps> = ({
  survey,
  onSubmit,
}) => {
  const { addToast } = useToast()
  const user = useSelector((state: IStoreState) => state.user)
  const [questions, setQuestions] = React.useState<IQuestion[]>()

  const formConfig = React.useMemo(() => {
    const initialValues: any = {}
    const validationSchema: any = {}

    if (questions?.length) {
      questions.forEach((question) => {
        if (question._id) {
          initialValues[question?._id] = ''
          validationSchema[question?._id] =
            question.type !== IQuestionType.MultiChoice
              ? Yup.string()
                  .matches(
                    /^[a-zA-Z0-9\s]+$/,
                    'Only alphanumeric characters are allowed'
                  )
                  .required('This field is required')
              : Yup.string().required('This field is required')
        }
      })
    }

    return {
      initialValues,
      validationSchema: Yup.object(validationSchema),
      onSubmit: (values, { setSubmitting, ...formik }) => {
        setSubmitting(true)

        const answers: {
          title: string
          question_id: string
        }[] = []

        Object.keys(values).forEach((question_id) => {
          answers.push({
            title: values[question_id],
            question_id,
          })
        })

        const finalValues: any = {
          survey_id: survey._id,
          profile_id: user._id,
          answers,
        }

        answerService
          .submit(finalValues)
          .then(() => {
            setSubmitting(false)
            addToast('Entry successfully submitted.', {
              appearance: 'success',
            })
            onSubmit && onSubmit(finalValues)
            formik.resetForm()
          })
          .catch((error) => {
            setSubmitting(false)
            addToast(error.message, { appearance: 'error' })
            throw error
          })
      },
    }
  }, [questions, addToast, onSubmit, survey?._id, user?._id])

  React.useEffect(() => {
    if (survey) {
      questionService
        .fetchBySurvey(`${survey._id}`)
        .then((data) => {
          setQuestions(data.filter((question) => question.status === 'active'))
        })
        .catch((error) => {
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    }
  }, [survey, addToast])

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ ...formik }) => (
        <Form>
          <Box>
            <ImageContainer
              src={survey?.upload?.path}
              width="100%"
              height={240}
              backgroundSize="cover"
              rounded
            />
          </Box>
          <Box my={5}>
            <Text fontSize="2xl">{survey.title}</Text>
            <Text fontSize="md" color="gray.600">
              {survey.description}
            </Text>
          </Box>
          <SimpleGrid maxW="600px" columns={1} columnGap={5} rowGap={50}>
            {questions?.map((question) => (
              <Column key={question._id}>
                <HStack gridGap={5} align="start">
                  <ImageContainer src={question?.upload?.path} width={120} />
                  {question.type === IQuestionType.MultiChoice &&
                  question?.choices?.length ? (
                    <FormStack orientation="vertical" isRequired>
                      <FormLabel>
                        <Flex alignItems="center">
                          <Text color="primary.600">
                            <FiCheckCircle size={16} />
                          </Text>
                          <Text ml={2}>{question.title}</Text>
                          <Text ml={1} color="red.500">
                            *
                          </Text>
                        </Flex>
                      </FormLabel>
                      <FormikRadioGroup
                        name={`${question?._id}`}
                        options={question?.choices
                          ?.filter((choice) => choice.status === 'active')
                          .map((choice) => {
                            return {
                              value: choice._id || choice.title,
                              label: choice.title,
                            }
                          })}
                        direction="column"
                      />
                    </FormStack>
                  ) : (
                    <FormStack orientation="vertical" isRequired>
                      <FormLabel>
                        <Flex alignItems="center">
                          <Text color="primary.600">
                            <FiEdit3 size={16} />
                          </Text>
                          <Text ml={2}>{question.title}</Text>
                          <Text ml={1} color="red.500">
                            *
                          </Text>
                        </Flex>
                      </FormLabel>
                      <FormikField name={`${question._id}`} type="text" />
                    </FormStack>
                  )}
                </HStack>
              </Column>
            ))}
          </SimpleGrid>

          <AnswerConfirmationButton
            colorScheme="primary"
            mt={5}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            isLoading={formik.isSubmitting}
            values={formik.values}
            survey={survey}
            questions={sortBy(questions, 'index') || []}
            callback={formik.handleSubmit}
          />
        </Form>
      )}
    </Formik>
  )
}

export default SubmitAnswerForm
