export const defaultColors = {
  body: '#171C34',
  black: '#262626',
  white: '#ffffff',
  primary: {
    50: '#EFF4FC',
    100: '#CEDEF7',
    200: '#ADC9F2',
    300: '#8cb3ed',
    400: '#7CA8EA',
    500: '#6B9DE8',
    600: '#5b92e5',
    700: '#4975b7',
    800: '#4975b7',
    900: '#243a5c',
  },
  gray: {
    // 50: '#F7F8F9',
    50: '#F2F3F5',
    100: '#ECEDEF',
    200: '#E0E3E6',
    300: '#CCD0D5',
    400: '#99A1AC',
    500: '#808A97',
    600: '#666E79',
    700: '#4D535B',
    800: '#33373C',
  },
  success: {
    50: '#F0FFF5',
    100: '#C6F7D5',
    200: '#9AE6B4',
    500: '#38A169',
    600: '#2F855A',
  },
  critical: {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    500: '#E53E3E',
    600: '#C53030',
  },
  warning: {
    50: '#FFFAF0',
    100: '#FEEBC8',
    200: '#FBD38D',
    500: '#DD6B20',
    600: '#C05621',
  },
  info: {
    50: '#EBF8FF',
    100: '#BEE3F8',
    200: '#90CDF4',
    500: '#3182CE',
    600: '#2B6CB0',
  },
  secondary: {
    accent: '#23C9FB',
    dark: '#171C34',
  },
}

export type ColorProps = typeof defaultColors
