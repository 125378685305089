import React from 'react'

import { Badge, Button } from '@chakra-ui/react'
import { useHistory } from 'react-router'

import { ErrorBox, Pagination } from 'src/core/components'
import { Table, Thead, Tbody, Tr, Td, Th } from 'src/core/components/Table'
import { paginate, route } from 'src/core/helpers'

import { ITree } from '../tree.type'
import { usePermission } from 'src/core/hooks'

interface TreesTableProps {
  trees: ITree[]
  filters: any
  setFilters: (filters: any) => void
  setPage: (page: number) => void
}

export const TreesTable: React.FC<TreesTableProps> = ({ trees, ...props }) => {
  const { page, per_page: perPage } = props.filters
  const { setPage } = props

  const paginatedTrees = React.useMemo(() => {
    return trees ? paginate(trees, page, perPage) : []
  }, [page, perPage, trees])

  return (
    <>
      {!paginatedTrees.length ? (
        <ErrorBox summary="No location found" my={50} />
      ) : null}

      {paginatedTrees?.length ? (
        <>
          <Table isFit mt={2}>
            <TreesThead />
            <Tbody>
              {paginatedTrees.map((tree, index) => (
                <TreesTr
                  key={tree._id}
                  tree={tree}
                  index={index}
                  page={page}
                  perPage={perPage}
                />
              ))}
            </Tbody>
          </Table>

          <Pagination
            my={5}
            itemsPerPage={10}
            totalItems={trees.length}
            currentPage={page}
            onPageChange={setPage}
            justifyContent="center"
          />
        </>
      ) : null}
    </>
  )
}

const TreesThead: React.FC = () => {
  const { userCan } = usePermission()
  return (
    <Thead>
      <Tr>
        <Th textAlign="center">#</Th>
        <Th>Name</Th>
        <Th>Type</Th>
        <Th>Parent</Th>
        <Th>Status</Th>
        {userCan('update_tree') && <Th>Actions</Th>}
      </Tr>
    </Thead>
  )
}

interface TreesTrProps {
  tree: ITree
  index: number
  page: number
  perPage: number
}
const TreesTr: React.FC<TreesTrProps> = ({ tree, ...props }) => {
  const history = useHistory()
  const { userCan } = usePermission()

  const openTree = (tree): void =>
    history.push(
      route('location_update', {
        id: tree._id,
      })
    )

  return (
    <Tr key={tree._id}>
      <Td textAlign="center">
        {props.index + 1 + (props.page - 1) * props.perPage}
      </Td>
      <Td>{tree.name}</Td>
      <Td textTransform="uppercase">{tree.type}</Td>
      <Td>{tree?.parent_obj?.name || '-'}</Td>
      <Td>
        <Badge colorScheme={tree.status === 'active' ? 'success' : 'critical'}>
          {tree.status}
        </Badge>
      </Td>
      {userCan('update_tree') && (
        <Td>
          <Button size="sm" variant="outline" onClick={() => openTree(tree)}>
            Edit Location
          </Button>
        </Td>
      )}
    </Tr>
  )
}
