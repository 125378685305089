import { CoreService, CoreServiceProps } from 'src/core/services'
import { IChoice } from './choice.type'
import pluralize from 'pluralize'

export class ChoiceService extends CoreService<IChoice> {
  constructor() {
    super('choice')
  }

  fetchByQuestion(question_id: string): Promise<IChoice[]> {
    const formData = new FormData()
    formData.append('question_id', question_id)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_${question_id}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_by_question`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export default ChoiceService
export const choiceService = new ChoiceService()
export type ChoiceServiceProps = CoreServiceProps | keyof ChoiceService
