import React from 'react'
import {
  IAnswerProfileSummary,
  IAnswerProfileSummaryDict,
} from '../answer.type'
import { timestampToDate, timestampToTime } from 'src/core/helpers'
import { IQuestionType } from 'src/packages/question'
import { IAnswerProfileTable } from '..'
import { groupBy, map } from 'lodash'

export const useAnswerSummaryTable = (
  summaryDict: IAnswerProfileSummaryDict
): IAnswerProfileTable => {
  return React.useMemo(() => {
    const profileSummaryArray: Record<string, string>[] = []
    let serial = 1
    Object.values(summaryDict).forEach(
      (profileAnswer: IAnswerProfileSummary) => {
        const { answers, profile } = profileAnswer

        map(groupBy(answers, 'sid'), (grouped_answers) => {
          const patch: any = {
            [`#`]: serial,
            [`Date`]: null,
            [`Entered by`]: profile.name,
          }
          grouped_answers.forEach((answer) => {
            if (!patch.date) {
              patch[`Date`] = `${timestampToDate(
                answer.created
              )} @${timestampToTime(answer.created)}`
            }
            patch[answer.question?.title || 'unknown'] =
              answer?.question?.choices &&
              answer?.question?.type === IQuestionType.MultiChoice
                ? answer.question?.choices.find(
                    (choice) => choice._id === answer.title
                  )?.title
                : answer.title
          })
          profileSummaryArray.push(patch)
          serial++
        })
      }
    )

    const header: string[] = []
    profileSummaryArray.forEach((data) => {
      const keys = Object.keys(data)
      keys.forEach((new_key) => {
        if (!header.includes(new_key)) {
          header.push(new_key)
        }
      })
    })

    return { header: header, body: profileSummaryArray }
  }, [summaryDict])
}
