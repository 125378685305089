import React from 'react'

import { Box, Flex, Spinner } from '@chakra-ui/react'
import { ErrorBox, Layout } from 'src/core/components'
import SubmitAnswerForm from '../components/SubmitAnswerForm'
import { useMounted } from 'src/core/hooks'
import { useHistory, useParams } from 'react-router'
import { surveyService } from 'src/packages/survey'
import { ISurvey } from 'src/packages/survey/survey.type'
import { route } from 'src/core/helpers'

const SubmitAnswer: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const { id: param_id }: any = useParams()

  const [isLoading, setLoading] = React.useState<boolean>(false)
  const [survey, setSurvey] = React.useState<ISurvey>()
  const [error, setError] = React.useState<any>()

  React.useEffect(() => {
    if (param_id) {
      setLoading(true)
      setError(undefined)
      surveyService
        .fetchById(param_id)
        .then((data) => {
          isMounted.current && setSurvey(data)
        })
        .catch((err) => {
          isMounted.current && setError(err)
        })
        .finally(() => {
          isMounted.current && setLoading(false)
        })
    } else {
      setError(undefined)
      setLoading(false)
    }
  }, [param_id, isMounted])

  return (
    <Layout
      header={{
        title: `Submit Entry`,
        backText: `${survey?.title ?? '...'}`,
        onBack: () => {
          history.push(
            route('survey_view', { id: survey?._id, tab: 'entries' })
          )
        },
      }}
      // permission="update_answer"
    >
      {isLoading ? (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      ) : survey ? (
        <Box>
          <SubmitAnswerForm survey={survey} />
        </Box>
      ) : error ? (
        <ErrorBox summary={`${error?.message}`} />
      ) : (
        <ErrorBox summary={`Question not found.`} />
      )}
    </Layout>
  )
}

export default SubmitAnswer
