import { CoreService, CoreServiceProps } from 'src/core/services'
import { IAnswer, IAnswerProfileSummaryDict } from './answer.type'
import pluralize from 'pluralize'
import { ISurveySummary } from '../survey/survey.type'

export class AnswerService extends CoreService<IAnswer> {
  constructor() {
    super('answer')
  }

  submit(payload: {
    survey_id: string
    profile_id: string
    answers: {
      title: string
      question_id: string
    }[]
  }): Promise<any> {
    const formData = new FormData()

    formData.append('survey_id', payload.survey_id)
    formData.append('profile_id', payload.profile_id)

    payload.answers.forEach((item) => {
      Object.keys(item).forEach((key) => {
        formData.append(`${key}[]`, item[key])
      })
    })

    return new Promise((resolve, reject) => {
      try {
        this.http.post(`${this.model}/submit`, formData).then(({ data }) => {
          if (data.code === 200) {
            this.cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  fetchBySurvey(survey_id: string): Promise<IAnswer[]> {
    const formData = new FormData()
    formData.append('survey_id', survey_id)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_${survey_id}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_by_survey`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchBySurveyProfileSummary(
    survey_id: string
  ): Promise<IAnswerProfileSummaryDict> {
    const formData = new FormData()
    formData.append('survey_id', survey_id)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(
        this.model
      )}_survey_profile_summary_${survey_id}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_by_survey_profile_summary`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchBySurveySummary(
    survey_id: string,
    use_profile_locations: boolean
  ): Promise<ISurveySummary> {
    const formData = new FormData()
    formData.append('survey_id', survey_id)
    formData.append('use_profile_locations', `${use_profile_locations}`)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(
        this.model
      )}_${survey_id}_survey_summary_${use_profile_locations}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_by_survey_summary`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export default AnswerService
export const answerService = new AnswerService()
export type AnswerServiceProps = CoreServiceProps | keyof AnswerService
