import React from 'react'

import { Stack, Spinner, Flex, Box, useTheme } from '@chakra-ui/react'
import { Card } from 'react-bootstrap'

import { ErrorBox } from 'src/core/components'
import { Layout, LayoutProps } from 'src/core/components/Layout/Layout'
import { UserPasswordForm } from 'src/packages/user'

interface EditorPageProps extends LayoutProps {
  type?: 'create' | 'update'
  model?: any
  /**
   * Models with a user_id property usually
   * require a password change form
   */
  isUserModel?: boolean
  isLoading?: boolean
}
/**
 * Template for editor pages
 * An editor page is a combination of create and edit pages.
 *
 * @props EditorPageProps
 */
export const EditorPageTemplate: React.FC<EditorPageProps> = ({
  type,
  header,
  model,
  isLoading,
  permission,
  isUserModel,
  children: modelForm,
}) => {
  const { sizes } = useTheme()
  const showPasswordForm = isUserModel && type === 'update'

  return (
    <Layout header={header} permission={permission}>
      <Stack direction={['column', 'column', 'row']} spacing={sizes.gutter.md}>
        <Box flex={(showPasswordForm ? 8 : 12) / 12}>
          <Card>
            <Card.Body className="pt-0">
              {isLoading ? (
                <Flex justifyContent="center" py={50}>
                  <Spinner color="primary.600" />
                </Flex>
              ) : null}

              {type === 'create' || (!isLoading && model) ? modelForm : null}

              {type === 'update' && !isLoading && !model ? (
                <Box py={50}>
                  <ErrorBox summary="Model not found" />
                </Box>
              ) : null}
            </Card.Body>
          </Card>
        </Box>
        {showPasswordForm ? (
          <Box flex={4 / 12}>
            <Card>
              <Card.Header>
                <Card.Title>Change Password</Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                <UserPasswordForm user={model} />
              </Card.Body>
            </Card>
          </Box>
        ) : null}
      </Stack>
    </Layout>
  )
}

EditorPageTemplate.defaultProps = {
  type: 'create',
}

export default EditorPageTemplate
