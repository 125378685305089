import { IRoute } from 'src/bootstrap/routes'
import TreeCreate from './pages/TreeCreate.page'
import Trees from './pages/Trees.page'
import TreeUpdate from './pages/TreeUpdate.page'

export const treeRoutes: IRoute[] = [
  {
    id: 'location',
    path: '/locations',
    requiresAuth: true,
    component: Trees,
    exact: true,
  },
  {
    id: 'location_create',
    path: '/locations/create',
    requiresAuth: true,
    component: TreeCreate,
    exact: true,
  },
  {
    id: 'location_update',
    path: '/locations/:id/update',
    requiresAuth: true,
    component: TreeUpdate,
    exact: true,
  },
]

export default treeRoutes
