import {
  Box,
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import { FiUpload } from 'react-icons/fi'
import { IQuestion, IQuestionType } from 'src/packages/question'
import { ISurvey } from 'src/packages/survey/survey.type'

interface IProps extends ButtonProps {
  values: any
  survey: ISurvey
  questions: IQuestion[]
  callback: () => void
}

const AnswerConfirmationButton: React.FC<IProps> = ({
  title,
  survey,
  values,
  questions,
  callback,
  ...props
}) => {
  const [visible, setVisible] = React.useState<boolean>(false)
  const toggleVisible = (): any => {
    setVisible(!visible)
  }

  const submit = (): void => {
    toggleVisible()
    callback()
  }

  return (
    <>
      <Modal isOpen={visible} onClose={toggleVisible}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottomColor="gray.100" borderBottomWidth="1px">
            Confirm Entries
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <VStack
              divider={<StackDivider borderColor="gray.100" />}
              spacing={5}
              align="stretch"
            >
              <VStack align="start">
                <Box>
                  <Text fontSize="sm" color="gray.600">
                    Project Title
                  </Text>
                  <Text fontSize="lg">{survey.title}</Text>
                </Box>
              </VStack>

              {/* entries */}
              <VStack align="start">
                {Object.keys(values).map((key) => {
                  return (
                    <Box key={key}>
                      <Text fontSize="sm" color="gray.600">
                        {
                          questions.find((question) => question._id === key)
                            ?.title
                        }
                      </Text>
                      <Text fontSize="lg">
                        {questions.find((question) => question._id === key)
                          ?.type === IQuestionType.MultiChoice
                          ? questions
                              .find((question) => question._id === key)
                              ?.choices?.find(
                                (choice) => choice._id === values[key]
                              )?.title
                          : values[key]}
                      </Text>
                    </Box>
                  )
                })}
              </VStack>
            </VStack>

            <Button
              type="submit"
              colorScheme="primary"
              mt={5}
              onClick={() => submit()}
            >
              Yes, Submit
            </Button>
            <Button
              ml={2}
              type="submit"
              colorScheme="gray"
              mt={5}
              onClick={toggleVisible}
            >
              Cancel
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Button
        size="md"
        leftIcon={<FiUpload />}
        onClick={toggleVisible}
        {...props}
      >
        {title}
      </Button>
    </>
  )
}

AnswerConfirmationButton.defaultProps = {
  title: 'Submit',
}

export default AnswerConfirmationButton
