import { IRoute } from 'src/bootstrap/routes'
import SurveyCreate from './pages/SurveyCreate.page'
import SurveySetup from './pages/SurveySetup.page'
import Surveys from './pages/Surveys.page'
import SurveyView from './pages/SurveyView.page'

export const surveyRoutes: IRoute[] = [
  {
    id: 'survey',
    path: '/',
    requiresAuth: true,
    component: Surveys,
    exact: true,
  },
  {
    id: 'survey_create',
    path: '/survey/create',
    requiresAuth: true,
    component: SurveyCreate,
    exact: true,
  },
  {
    id: 'survey_setup',
    path: '/survey/setup',
    requiresAuth: true,
    component: SurveySetup,
    exact: true,
  },
  {
    id: 'survey_view',
    path: '/survey/:id/:tab',
    requiresAuth: true,
    component: SurveyView,
    exact: true,
  },
]

export default surveyRoutes
