import { CoreService, CoreServiceProps } from 'src/core/services'
import { ITree } from './tree.type'

export class TreeService extends CoreService<ITree> {
  constructor() {
    super('tree')
  }
}

export default TreeService
export const treeService = new TreeService()
export type TreeServiceProps = CoreServiceProps | keyof TreeService
