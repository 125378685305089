import React from 'react'

import { VStack, StackDivider, Button } from '@chakra-ui/react'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'

import * as Yup from 'yup'

import { FormikField, FormStack } from 'src/core/components'
import useToast from 'src/core/hooks/useToast'
import { UserService } from '../services'

const userService = new UserService()

interface IProps {
  user: any
  type?: 'update' | 'change'
}

export const UserPasswordForm: React.FC<IProps> = ({ user, type }) => {
  const { addToast } = useToast()

  const formConfig = {
    initialValues: {
      hash_old: '',
      hash_new: '',
      hash_new_confirm: '',
    },
    validationSchema: Yup.object({
      hash_old:
        type === 'change'
          ? Yup.string().required('Password field is required')
          : Yup.string(),
      hash_new: Yup.string().required('Password field is required'),
      hash_new_confirm: Yup.string()
        .required('Password confirmation is required')
        .oneOf([Yup.ref('hash_new'), null], 'Passwords must match'),
    }),
    onSubmit: (values, { resetForm, setSubmitting }) => {
      setSubmitting(true)
      const finalValues: any = {
        user_id: user.user_id,
        hash_new: values.hash_new,
      }

      if (type === 'change') {
        finalValues.hash_old = values.hash_old
      }

      type &&
        userService
          .updatePassword(type, { ...finalValues })
          .then(() => {
            setSubmitting(false)
            resetForm()

            addToast('Password successfully updated.', {
              appearance: 'success',
            })
          })
          .catch((error) => {
            setSubmitting(false)
            addToast(error.message, { appearance: 'error' })
          })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={2}
            align="stretch"
            my={5}
          >
            {/* User new password */}
            <FormStack
              label="New Password"
              helperText="Set secure password"
              orientation="vertical"
              isRequired
            >
              <FormikField name="hash_new" type="password" />
            </FormStack>

            {/* User new password (Confirm) */}
            <FormStack
              label="Confirm Password"
              helperText="Confirm new password"
              orientation="vertical"
              isRequired
            >
              <FormikField name="hash_new_confirm" type="password" />
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            isLoading={formik.isSubmitting}
          >
            {type === 'change' ? 'Change Password' : 'Update Password'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

UserPasswordForm.defaultProps = {
  type: 'update',
}

export default UserPasswordForm
