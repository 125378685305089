import { IRoute } from 'src/bootstrap/routes'
import Invite from './pages/InviteLink.page'

export const inviteRoutes: IRoute[] = [
  {
    id: 'invitation',
    path: '/invitation/:token',
    requiresAuth: false,
    component: Invite,
    exact: true,
  },
]

export default inviteRoutes
