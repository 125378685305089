import { CoreService, CoreServiceProps } from 'src/core/services'
import {
  ISurvey,
  ISurveyForProfile,
  ISurveyTreeForProfile,
} from './survey.type'
import pluralize from 'pluralize'

class SurveyService extends CoreService {
  constructor() {
    super('survey')
  }

  fetchByProfile(profile_id: string): Promise<ISurvey[]> {
    const formData = new FormData()
    formData.append('profile_id', profile_id)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_${profile_id}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_by_profile`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchForProfile(profile_id: string): Promise<ISurveyForProfile> {
    const formData = new FormData()
    formData.append('profile_id', profile_id)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_for_profile_${profile_id}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_for_profile`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchTreeForProfile(profile_id: string): Promise<ISurveyTreeForProfile> {
    const formData = new FormData()
    formData.append('profile_id', profile_id)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_tree_for_profile_${profile_id}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/tree_for_profile`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export const surveyService = new SurveyService()
export type SurveyServiceProps = CoreServiceProps | keyof SurveyService
