import Login from './pages/Login.page'
import Profile from './pages/Profile.page'

import { IRoute } from 'src/bootstrap/routes'
import Reset from './pages/Reset.page'
import ResetPassword from './pages/ResetPassword.page'

export const userRoutes: IRoute[] = [
  {
    id: 'auth_login',
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    id: 'profile',
    path: '/profile',
    requiresAuth: true,
    component: Profile,
    exact: true,
  },
  {
    id: 'reset',
    path: '/reset',
    requiresAuth: false,
    component: Reset,
    exact: true,
  },
  {
    id: 'reset_send',
    path: '/reset/:token',
    requiresAuth: false,
    component: ResetPassword,
    exact: true,
  },
]

export default userRoutes
