import React from 'react'
import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react'

const defaultOptions: UseToastOptions = {
  position: 'bottom-right',
}

export const useToast = (): any => {
  const chakraToast = useChakraToast()

  const toast = React.useCallback(
    (options: UseToastOptions): void => {
      chakraToast({ ...defaultOptions, ...options })
    },
    [chakraToast]
  )

  /**
   * For backward compatibility with
   * react-toast-notification
   *
   * @param message
   * @param options - {appearance}
   */
  const addToast = React.useCallback(
    (
      message: string,
      options: {
        appearance?: UseToastOptions['status']
        autoDismiss: boolean
      }
    ): void => {
      chakraToast({
        ...defaultOptions,
        description: message,
        status: options?.appearance,
        duration: options?.autoDismiss === false ? null : 6000,
      })
    },
    [chakraToast]
  )

  const closeAllToasts = React.useCallback((): void => {
    chakraToast.closeAll()
  }, [chakraToast])

  return { toast, addToast, closeAllToasts }
}

export default useToast
