import React from 'react'

import {
  Box,
  Flex,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { ErrorBox, Layout } from 'src/core/components'
import SurveyForm from '../components/SurveyForm'
import { useMounted, usePermission, useToast } from 'src/core/hooks'
import { ISurvey } from '../survey.type'
import { surveyService } from '../survey.service'
import { useHistory, useParams } from 'react-router'
import { route } from 'src/core/helpers'
import QuestionFormList from 'src/packages/question/components/QuestionFormList'
import { SurveySummaryGraphs } from '../components/dashboard'
import ProfileAnswers from 'src/packages/answer/pages/ProfileAnswers.page'
import Invites from 'src/packages/invite/pages/Invites.page'
import { useSelector } from 'react-redux'
import { IStoreState } from 'src/bootstrap/store/types'
import SurveyBranches from './SurveyBranches.page'

const SurveyUpdate: React.FC = () => {
  const { user } = useSelector((state: IStoreState) => state)
  const history = useHistory()
  const { userCan } = usePermission()
  const isMounted = useMounted()
  const { toast } = useToast()
  const { id: param_id }: any = useParams()

  const [isLoading, setLoading] = React.useState<boolean>(false)
  const [survey, setSurvey] = React.useState<ISurvey>()
  const [error, setError] = React.useState<any>()

  const isOwner = React.useMemo<boolean>(() => {
    if (user?._id === survey?.profile_id) {
      return true
    }
    return false
  }, [user, survey])

  const canModifySurvey = userCan('update_survey')

  const tabIndices = React.useMemo(() => {
    return [
      {
        id: 'invites',
        path: 'invites',
        label: 'Invitations',
        show: isOwner || canModifySurvey,
      },
      {
        id: 'membership',
        path: 'membership',
        label: 'Project Locations',
        show: isOwner || canModifySurvey,
      },
      { id: 'dashboard', path: 'dashboard', label: 'Analysis', show: true },
      {
        id: 'data_fields',
        path: 'data_fields',
        label: 'Questions',
        show: isOwner || canModifySurvey,
      },
      { id: 'entries', path: 'entries', label: 'Data', show: true },
      {
        id: 'settings',
        path: 'settings',
        label: 'Settings',
        show: isOwner || canModifySurvey,
      },
    ]
  }, [isOwner, canModifySurvey])

  const [tabIndex, setTabIndex] = React.useState(-1)

  const handleTabChange = React.useCallback(
    (index): void => {
      const goto = route('survey_view', {
        id: survey?._id,
        tab: tabIndices[index].path,
      })
      if (goto !== 'undefined') {
        history.push(goto)
      } else {
        history.push('/error_404')
      }
    },
    [history, tabIndices, survey]
  )

  React.useEffect(() => {
    const evaluatePath = (): void => {
      const currentPath = location?.pathname.split('/')[3]
      const pathIndex = tabIndices.findIndex((tab) => tab.path === currentPath)
      if (pathIndex > -1) {
        setTabIndex(pathIndex)
      } else {
        setTabIndex(0)
      }
    }

    evaluatePath()
    const stopListening = history.listen(evaluatePath)

    return () => {
      stopListening()
    }
  }, [history, tabIndex, tabIndices])

  React.useEffect(() => {
    if (param_id) {
      setLoading(true)
      setError(undefined)
      surveyService
        .fetchById(param_id)
        .then((data) => {
          if (data?.status === 'deleted') {
            const msg = 'Project not found or no longer exists.'
            toast({
              description: msg,
              status: 'error',
            })
            setError({ message: msg })
          } else {
            isMounted.current && setSurvey(data)
          }
        })
        .catch((error) => {
          isMounted.current && setError(error)
        })
        .finally(() => {
          isMounted.current && setLoading(false)
        })
    } else {
      setError(undefined)
      setLoading(false)
    }
  }, [param_id, isMounted, toast])

  return (
    <Layout
      header={{
        title: survey?.title ? survey?.title : '...',
        backText: 'Projects',
        onBack: () => {
          history.push(route('survey'))
        },
      }}
      // permission="update_survey"
    >
      {isLoading ? (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      ) : survey ? (
        <Box>
          <Text fontSize={20} fontWeight="semibold">
            {survey.title}
          </Text>
          <Text fontSize={14} color="gray.600" mb="20px">
            {survey.description}
          </Text>
          <Tabs index={tabIndex} onChange={handleTabChange}>
            <TabList>
              {tabIndices
                .filter((tab) => tab.show)
                .map((tab, index) => (
                  <Tab key={index}>{tab.label}</Tab>
                ))}
            </TabList>

            <TabPanels>
              {/* invitees */}
              {(isOwner || canModifySurvey) && (
                <TabPanel>
                  <Invites survey={survey} />
                </TabPanel>
              )}

              {/* Membership */}
              {(isOwner || canModifySurvey) && (
                <TabPanel>
                  <SurveyBranches survey={survey} />
                </TabPanel>
              )}

              {/* dashboard */}
              <TabPanel>
                <SurveySummaryGraphs survey={survey} />
              </TabPanel>

              {/* data fields */}
              {(isOwner || canModifySurvey) && (
                <TabPanel>
                  <QuestionFormList survey={survey} columns={[1]} />
                </TabPanel>
              )}

              {/* entries */}
              <TabPanel>
                {survey._id && <ProfileAnswers survey={survey} />}
              </TabPanel>

              {/* settings */}
              {(isOwner || canModifySurvey) && (
                <TabPanel>
                  <SurveyForm
                    type="update"
                    onUpdate={(updatedSurvey) => setSurvey(updatedSurvey)}
                    survey={survey}
                  />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </Box>
      ) : error ? (
        <ErrorBox summary={`${error?.message}`} />
      ) : (
        <ErrorBox summary={`Project not found.`} />
      )}
    </Layout>
  )
}

export default SurveyUpdate
