import { useLocation } from 'react-router-dom'
import { routes } from 'src/bootstrap/routes'

/**
 * Search URL params
 */
export const useQuery = (): any => {
  return new URLSearchParams(useLocation().search)
}

/**
 * Build query string from object keys & values
 *
 * @param params
 */
export const buildQueryString = (params: any): any => {
  const params_array: any = []
  if (params && typeof params === 'object') {
    Object.keys(params).forEach((key) => {
      params[key] && params_array.push(`${key}=${params[key]}`)
    })
  }

  return params_array.join('&')
}

/**
 * Replace params (:param) in routes,
 * build query string from object
 *
 * @param route
 * @param params
 * @param query
 */
export const route = (route: string, params: any = {}, query?: any): string => {
  const found = routes.find(({ id }) => id === route)

  route = found ? found.path : 'undefined'

  if (route && params && Object.keys(params).length) {
    Object.keys(params).forEach((key) => {
      route = route.replace(new RegExp(`:${key}`, 'g'), params[key])
    })
  }

  if (query) route = `${route}?${buildQueryString(query)}`

  return route
}
