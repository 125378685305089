import React from 'react'
import { groupBy, map, sum } from 'lodash'
import { ISurveySummary } from 'src/packages/survey/survey.type'
import { IQuestion, IQuestionType } from 'src/packages/question'

export interface ISurveySummaryChartData {
  question: IQuestion
  stat: {
    abbr: string
    label: string
    value: number
  }[]
}

export const useSurveySummaryData = (
  summary?: ISurveySummary
): ISurveySummaryChartData[] => {
  const structuredData = React.useMemo<ISurveySummaryChartData[]>(() => {
    if (!summary) return []
    return map(summary, (summaryItem) => {
      const answersSet = groupBy(summaryItem.answers, 'title')
      const output: ISurveySummaryChartData = {
        question: summaryItem.question,
        stat: [],
      }

      if (summaryItem.ranges) {
        summaryItem.ranges.forEach((range) => {
          output.stat.push({
            value: range.count,
            label: range.title,
            abbr: range.title,
          })
        })
      } else {
        const total = sum(Object.values(answersSet).map((item) => item.length))
        Object.keys(answersSet).forEach((ansKeyTitle) => {
          const label =
            summaryItem.question.type === IQuestionType.MultiChoice
              ? summaryItem.question.choices?.find(
                  (choice) => choice._id === ansKeyTitle
                )?.title
              : ansKeyTitle
          const value = parseFloat(
            ((answersSet[ansKeyTitle].length / total) * 100).toFixed(1)
          )
          const abbr = `${label?.split('').slice(0, 8).join('')}`

          output.stat.push({
            value,
            label: label ? label : 'unknown',
            abbr,
          })
        })
      }

      return output
    })
  }, [summary])

  return structuredData
}
