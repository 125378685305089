import { IAnswer } from '../answer'
import { IBranch } from '../branch'
import { IQuestion } from '../question'
import { ITreeType } from '../tree'
import { IUpload } from '../upload'
import { IUserProfile } from '../user'

export enum ISurveyType {
  Public = 'public',
  Private = 'private',
}

export enum ISurveyVisibility {
  For = 'for',
  By = 'by',
  Open = 'open',
}

export interface ISurvey {
  _id?: string
  title: string
  description: string
  type: ISurveyType
  status: 'active' | 'inactive' | 'deleted'

  upload_id: string
  upload?: IUpload

  profile_id: string
  profile?: IUserProfile

  question_count?: number
  answer_count?: number

  visibility?: 'for' | 'by' | 'open' | ''

  locations?: IBranch[]

  created?: number
}

export interface ISurveySummary {
  [_id: string]: {
    question: IQuestion
    answers: IAnswer[]
    ranges?: {
      title: string
      count: number
      lower: any
      higher: any
    }[]
  }
}

export type ISurveyForProfile = Record<ISurveyVisibility, ISurvey[]>

interface ITreeForSurvey {
  _id: string
  parent: string
  type: ITreeType
  name: string
  status: 'active' | 'inactive'
  types?: Record<
    ITreeType,
    Record<string | 'entries' | 'entries_array', Record<string, ITreeForSurvey>>
  >
  surveys?: string[]
}

export interface ISurveyTreeForProfile {
  [tree_id: string]: ITreeForSurvey
}
