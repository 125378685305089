import React from 'react'

import { Box } from '@chakra-ui/react'
import { Layout } from 'src/core/components'
import BranchForm from '../components/BranchForm'

interface IProps {
  identifier: string
  identifierType: string
}

const BranchCreate: React.FC<IProps> = (props) => {
  return (
    <Layout
      header={{ title: `New Branch` }}
      // permission="create_tree"
    >
      <Box maxW="sm" marginX="auto">
        <BranchForm type="create" {...props} />
      </Box>
    </Layout>
  )
}

export default BranchCreate
