import React from 'react'

import { VStack, StackDivider, HStack, Button, Box } from '@chakra-ui/react'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { FormikRadioGroup, FormikField, FormStack } from 'src/core/components'
import { useToast, useRefactorObjects, useFetch } from 'src/core/hooks'
import { route } from 'src/core/helpers'

import { treeService, TreeServiceProps } from '../tree.service'
import { ITree, ITreeType } from '../tree.type'

interface TreeFormProps {
  type: 'create' | 'update'
  tree?: ITree
  onUpdate?: (payload: ITree) => void
}

const TreeForm: React.FC<TreeFormProps> = ({ type, tree, onUpdate }) => {
  const history = useHistory()
  const { addToast } = useToast()

  const [treeType, setTreeType] = React.useState<ITreeType | undefined>(
    tree?.type
  )
  const [parentTreeType, setParentTreeType] = React.useState<
    ITreeType | undefined
  >(tree?.parent_obj?.type)
  const typeOptions = Object.entries(ITreeType).map(([key, value]) => ({
    label: key.toUpperCase(),
    value,
  }))

  // Fetch trees
  const [trees] = useFetch<ITree[], TreeServiceProps>(treeService, 'fetch')
  const treeOptions = useRefactorObjects(
    trees?.filter((item) => item.type === parentTreeType)
  )

  const formConfig = {
    initialValues: {
      name: tree?.name || '',
      type: typeOptions?.find((option) => option.value === tree?.type) ?? null,
      parent_type:
        typeOptions?.find(
          (option) => option.value === tree?.parent_obj?.type
        ) ?? null,
      parent: tree?.parent_obj
        ? {
            value: tree.parent_obj._id,
            label: tree.parent_obj.name,
          }
        : tree?.parent === '-'
        ? {
            value: '-',
            label: 'None',
          }
        : null,
      status: tree?.status || 'active',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      type: Yup.mixed().required('Type field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const finalValues: any = {
        ...values,
        type: values.type.value,
        parent: values.parent?.value || '-',
      }

      delete finalValues.parent_type

      if (type === 'update') {
        finalValues._id = tree?._id
      }

      treeService[type](finalValues)
        .then((treeId) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Location successfully updated.', {
              appearance: 'success',
            })
            onUpdate && onUpdate(finalValues)
          } else {
            addToast('Location successfully created.', {
              appearance: 'success',
            })
            history.push(route('location_update', { id: treeId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack
            divider={<StackDivider borderColor="gray.100" />}
            spacing={5}
            align="stretch"
            my={5}
          >
            {/* Name */}
            <FormStack label="Name" orientation="vertical" isRequired>
              <FormikField name="name" type="text" />
            </FormStack>

            {/* Type */}
            <FormStack label="Type" orientation="vertical" isRequired>
              <FormikField
                as="autocomplete"
                name="type"
                options={typeOptions}
                placeholder={`Select type`}
                onBlur={() => {
                  formik.setFieldTouched('type', true)
                }}
                onChange={(selected) => {
                  setTreeType(selected.value)
                  setParentTreeType(undefined)
                  formik.setFieldValue('type', selected)
                  formik.setFieldValue('parent_type', null)
                  formik.setFieldValue('parent', null)
                }}
              />
            </FormStack>

            <HStack align="start" gridGap={3}>
              <Box>
                <FormStack label="Parent Type" orientation="vertical">
                  <FormikField
                    as="autocomplete"
                    name="parent_type"
                    options={typeOptions?.filter(
                      (type) => type.value !== treeType
                    )}
                    placeholder={`Select parent type`}
                    onBlur={() => {
                      formik.setFieldTouched('parent_type', true)
                    }}
                    onChange={(selected) => {
                      setParentTreeType(selected.value)
                      formik.setFieldValue('parent_type', selected)
                      formik.setFieldValue('parent', null)
                    }}
                  />
                </FormStack>
              </Box>
              <Box>
                {/* Location (Parent) */}
                <FormStack label="Parent" orientation="vertical">
                  <FormikField
                    as="autocomplete"
                    name="parent"
                    options={treeOptions}
                    placeholder={`Select location`}
                    onBlur={() => {
                      formik.setFieldTouched('parent', true)
                    }}
                    onChange={(selected) => {
                      formik.setFieldValue('parent', selected)
                    }}
                    isDisabled={!treeOptions.length || !parentTreeType}
                  />
                </FormStack>
              </Box>
            </HStack>

            {/* Status */}
            {type === 'update' && (
              <FormStack label="Status" orientation="vertical" isRequired>
                <FormikRadioGroup
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </FormStack>
            )}
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            mt={5}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            isLoading={formik.isSubmitting}
          >
            {type === 'create' ? 'Create Location' : 'Update Location'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

TreeForm.defaultProps = {
  type: 'create',
}

export default TreeForm
