import React from 'react'

/**
 * Watch for memory leaks on unmounted
 * components
 */

export const useMounted = (): any => {
  const isMounted = React.useRef<boolean>()

  React.useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  return isMounted
}

export default useMounted
