import { ComponentStyleConfig } from '@chakra-ui/theme'

export const Card: ComponentStyleConfig = {
  parts: ['card', 'header', 'body', 'footer'],
  baseStyle: {
    card: {
      bg: 'white',
      border: '1px solid',
      borderColor: 'gray.100',
      borderRadius: 'md',
    },
    header: {
      borderBottom: '1px solid',
      borderColor: 'gray.100',
      padding: '10px 20px',
    },
    body: {
      padding: '20px',
    },
    footer: {
      borderTop: '1px solid',
      borderColor: 'gray.100',
      padding: '20px',
    },
  },
}
