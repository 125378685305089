import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from './reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'config', 'scoreBoard'],
}

const persisitedReducer = persistReducer(persistConfig, rootReducer)

const bindMiddleware = (middleware): any => {
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(middleware))
  }
  return applyMiddleware(middleware)
}

export const store = createStore<any, any, any, any>(
  persisitedReducer,
  bindMiddleware(thunkMiddleware)
)

export const persistor = persistStore(store)
