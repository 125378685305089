import React from 'react'

import { Button, Heading, Checkbox, useTheme } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/layout'
import { Formik, Form, Field } from 'formik'
import { FiAtSign, FiLock, FiPhone, FiUser } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import { AUTH, SET_USER } from 'src/bootstrap/store/actionTypes'
import { FormikField, FormStack, Card } from 'src/core/components'
import { route } from 'src/core/helpers'
import useToast from 'src/core/hooks/useToast'

import { userService } from '../services'
import { IUser } from '../types'

const Signup: React.FC = () => {
  const { colors } = useTheme()
  const { addToast } = useToast()
  const dispatch = useDispatch()

  const formConfig = {
    initialValues: {
      email: '',
      hash: '',
      phone: '',
      name: '',
      role: 'default',
      remember: true,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Email field is required.')
        .email('Email must be valid.'),
      phone: Yup.string()
        .matches(/^[0][7-9][0-1][0-9]{8}$/, 'Phone number is invalid')
        .required('Phone field is required'),
      hash: Yup.string().required('Password field is required'),
      name: Yup.string().required('Name field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      userService
        .doSignup(values)
        .then((user: IUser) => {
          setSubmitting(false)
          dispatch({ type: SET_USER, payload: user })
          dispatch({ type: AUTH, payload: { token: user.token } })
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
        })
    },
  }

  return (
    <Card padding="10">
      <Flex flexDirection="column" mb={10}>
        <Heading as="h5" fontSize="xl" fontWeight="semibold" color="gray.600">
          Join here,
        </Heading>
        <Heading
          as="h5"
          fontSize="xl"
          fontWeight="semibold"
          color="gray.900"
          mt="10px"
        >
          Create an account
        </Heading>
      </Flex>

      <Formik
        initialValues={formConfig.initialValues}
        validationSchema={formConfig.validationSchema}
        validateOnMount={true}
        onSubmit={formConfig.onSubmit}
      >
        {({ ...formik }) => (
          <Form onSubmit={formik.handleSubmit}>
            <FormStack orientation="vertical">
              <FormikField
                name="name"
                type="name"
                prepend={<FiUser color={colors.gray['600']} size={16} />}
                placeholder="Name"
              />
            </FormStack>

            <FormStack orientation="vertical">
              <FormikField
                name="email"
                type="email"
                prepend={<FiAtSign color={colors.gray['600']} size={16} />}
                placeholder="Email"
              />
            </FormStack>

            <FormStack orientation="vertical">
              <FormikField
                name="phone"
                type="phone"
                prepend={<FiPhone color={colors.gray['600']} size={16} />}
                placeholder="Phone"
              />
            </FormStack>

            <FormStack orientation="vertical">
              <FormikField
                name="hash"
                type="password"
                prepend={<FiLock color={colors.gray['600']} size={16} />}
                placeholder="Password"
              />
            </FormStack>

            <Flex justifyContent="space-between" alignItems="center">
              <Field
                as={Checkbox}
                name="remember"
                isChecked={formik.values.remember}
                colorScheme="primary"
                mt={3}
              >
                Remember me
              </Field>

              <Button
                type="submit"
                colorScheme="primary"
                mt={5}
                isDisabled={formik.isSubmitting || !formik.isValid}
                isLoading={formik.isSubmitting}
              >
                Continue
              </Button>
            </Flex>

            <Flex flexDir="column" align="center" mt={10}>
              <Box>
                <Link
                  to={route('auth_login', null, {
                    return_url: location.pathname,
                  })}
                >
                  Already have an account? Login
                </Link>
              </Box>
              <Box mt="6px">
                <Link
                  to={route('reset', null, { return_url: location.pathname })}
                >
                  Forgot password?
                </Link>
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>
    </Card>
  )
}

export default Signup
