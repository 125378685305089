import { ComponentStyleConfig } from '@chakra-ui/theme'

export const Alert: ComponentStyleConfig = {
  parts: ['container'],
  baseStyle: {
    container: {
      borderRadius: 'md',
    },
  },
  variants: {
    container: {
      subtle: {
        borderRadius: 'md',
      },
    },
  },
}
