export enum ITreeType {
  Country = 'country',
  State = 'state',
  Ministry = 'ministry',
  LGA = 'lga',
  Ward = 'ward',
  Custom = 'custom',
}

export interface ITree {
  _id?: string
  name: string
  type: ITreeType
  parent: string
  parent_obj: ITree
  status: 'active' | 'inactive'
}
