import React from 'react'
import DefaultUpload from 'src/core/assets/images/default-upload.jpg'
import { useFetch } from 'src/core/hooks'
import { uploadService } from 'src/packages/upload'

interface IProps {
  src: any
  width?: number | string
  height?: number | string
  rounded?: boolean
  roundedCircle?: boolean
  backgroundSize?: 'cover' | 'contain'
  useHttpBase?: boolean
}
export const ImageContainer: React.FC<IProps> = ({
  src,
  width,
  height,
  rounded,
  roundedCircle,
  backgroundSize,
  useHttpBase,
}) => {
  const [baseUrl] = useFetch(uploadService, 'fetchHttpBase', null, useHttpBase)

  const imgSrc = React.useMemo<string>(() => {
    if (useHttpBase) {
      if (baseUrl && src) {
        return baseUrl + src
      } else return DefaultUpload
    } else return src ? src : DefaultUpload
  }, [useHttpBase, baseUrl, src])

  return (
    <div
      style={{
        backgroundImage: `url(${imgSrc})`,
        backgroundColor: '#fff',
        backgroundSize,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: typeof width == 'string' ? width : `${width}px`,
        height: height
          ? typeof height == 'string'
            ? height
            : `${height}px`
          : typeof width == 'string'
          ? width
          : `${width}px`,
        borderRadius: roundedCircle ? '100px' : rounded ? '6px' : '0',
        flexShrink: 0,
      }}
    ></div>
  )
}

ImageContainer.defaultProps = {
  width: 40,
  rounded: false,
  roundedCircle: false,
  backgroundSize: 'contain',
  useHttpBase: true,
}

export default ImageContainer
