import React from 'react'

import { Spinner, Flex, Box } from '@chakra-ui/react'

import { SearchInput } from 'src/core/components'

import { useFilterInvites } from '../hooks/useFilterInvites'
import { InvitesTable } from '../components/InvitesTable'
import { IInvite } from '../invite.type'
import { InvitesTableFilters } from '../components/InvitesTableFilter'
import { inviteService } from '../invite.service'
import InviteButton from '../components/InviteButton'
import { ISurvey } from 'src/packages/survey/survey.type'
import { useMounted } from 'src/core/hooks'

interface IProps {
  survey: ISurvey
}

const Invites: React.FC<IProps> = ({ survey }) => {
  const isMounted = useMounted()
  const [isLoading, setLoading] = React.useState<boolean>(false)
  const [invites, setInvites] = React.useState<IInvite[]>([])

  const { filters, setFilters, setPage, activeFilters, filteredInvites } =
    useFilterInvites(invites)

  React.useEffect(() => {
    if (survey._id) {
      isMounted.current && setLoading(true)
      inviteService
        .fetchBySurvey(survey._id)
        .then((data) => {
          isMounted.current && setInvites(data)
        })
        .finally(() => {
          isMounted.current && setLoading(false)
        })
    }
  }, [survey, isMounted])

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <SearchInput
            placeholder="Search invitation"
            value={filters.search}
            onChange={(search) => setFilters({ search, page: 1 })}
            isDisabled={isLoading || !filteredInvites}
          />

          <Box ml={2}>
            <InvitesTableFilters
              filters={filters}
              setFilters={setFilters}
              activeFilters={activeFilters}
            />
          </Box>
        </Flex>

        <InviteButton survey={survey} title="Invite" colorScheme="primary" />
      </Flex>

      {isLoading ? (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      ) : (
        <InvitesTable
          invites={filteredInvites}
          filters={filters}
          setFilters={setFilters}
          setPage={setPage}
        />
      )}
    </>
  )
}

export default Invites
