import React from 'react'

import { ErrorBox, Pagination } from 'src/core/components'
import { Table, Thead, Tbody, Tr, Td, Th } from 'src/core/components/Table'
import { paginate } from 'src/core/helpers'
import { IAnswerProfileTable } from '..'

interface ProfileAnswersTableProps {
  profileAnswers: IAnswerProfileTable
  filters: any
  setFilters: (filters: any) => void
  setPage: (page: number) => void
}

export const ProfileAnswersTable: React.FC<ProfileAnswersTableProps> = ({
  profileAnswers,
  ...props
}) => {
  const { page, per_page: perPage } = props.filters
  const { setPage } = props

  const paginatedAnswers = React.useMemo<Record<string, string>[]>(() => {
    return profileAnswers.body
      ? paginate(profileAnswers.body, page, perPage)
      : []
  }, [page, perPage, profileAnswers])

  return (
    <>
      {!paginatedAnswers.length ? (
        <ErrorBox summary="No entries found" my={50} />
      ) : null}

      {paginatedAnswers?.length ? (
        <>
          <Table isFit mt={2}>
            <ProfileAnswersThead header={profileAnswers.header} />
            <Tbody>
              {paginatedAnswers.map((profileAnswer, index) => (
                <ProfileAnswersTr
                  key={index}
                  header={profileAnswers.header}
                  profileAnswer={profileAnswer}
                  index={index}
                  page={page}
                  perPage={perPage}
                />
              ))}
            </Tbody>
          </Table>

          <Pagination
            my={5}
            itemsPerPage={10}
            totalItems={profileAnswers.body.length}
            currentPage={page}
            onPageChange={setPage}
            justifyContent="center"
          />
        </>
      ) : null}
    </>
  )
}

const ProfileAnswersThead: React.FC<{ header: string[] }> = ({ header }) => {
  return (
    <Thead>
      <Tr>
        {header.map((title, index) => (
          <Th key={index} textAlign={index === 0 ? 'center' : undefined}>
            {title}
          </Th>
        ))}
      </Tr>
    </Thead>
  )
}

interface ProfileAnswersTrProps {
  profileAnswer: Record<string, string>
  header: string[]
  index: number
  page: number
  perPage: number
}

const ProfileAnswersTr: React.FC<ProfileAnswersTrProps> = ({
  profileAnswer,
  header,
  ...props
}) => {
  return (
    <Tr>
      {header.map((key, index) => (
        <Td key={index} textAlign={index === 0 ? 'center' : undefined}>
          {index === 0
            ? props.index + 1 + (props.page - 1) * props.perPage
            : profileAnswer[key] || '-'}
        </Td>
      ))}
    </Tr>
  )
}
