import { IRoute } from 'src/bootstrap/routes'
import SubmitAnswer from './pages/SubmitAnswer.page'

export const answerRoutes: IRoute[] = [
  {
    id: 'submit_answer',
    path: '/submit-entry/:id',
    requiresAuth: true,
    component: SubmitAnswer,
    exact: true,
  },
]

export default answerRoutes
