import React from 'react'

import { Flex, Text, Tooltip } from '@chakra-ui/react'
import { fromUnixTime, getUnixTime } from 'date-fns'
import DatePicker from 'react-datepicker'

interface DateRangeInputProps {
  /** Unix timestamp */
  start: number
  /** Unix timestamp */
  end: number
  onChange: (start: number, end: number) => void
  showTimeSelect?: boolean
}

export const DateRangeInput: React.FC<DateRangeInputProps> = ({
  start,
  end,
  onChange,
  ...props
}) => {
  // const {colors} = useTheme
  const nowDate = Math.ceil(new Date().getTime() / 1000)
  const startDate = start ? fromUnixTime(start) : fromUnixTime(nowDate)
  const endDate = end ? fromUnixTime(end) : fromUnixTime(nowDate)

  return (
    <Flex w="100%">
      <DatePicker
        selected={startDate}
        onChange={(newStartDate: Date) => {
          const newEndDate = newStartDate > endDate ? newStartDate : endDate
          onChange(getUnixTime(newStartDate), getUnixTime(newEndDate))
        }}
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        placeholderText="Start date"
        dateFormat={props.showTimeSelect ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy'}
        customInput={<CustomInput date={startDate} />}
        {...props}
      />
      <DatePicker
        selected={endDate}
        onChange={(newEndDate: Date) => {
          onChange(startDate.getTime() / 1000, newEndDate.getTime() / 1000)
        }}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        maxDate={new Date()}
        isDisabled={!startDate}
        placeholderText="End date"
        dateFormat={props.showTimeSelect ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy'}
        customInput={
          <CustomInput date={endDate} isDisabled={!startDate} right />
        }
        {...props}
      />
    </Flex>
  )
}

interface CustomInputProps {
  date?: Date
  right?: boolean
  placeholder?: string
  isDisabled?: boolean
}
const CustomInput = React.forwardRef<any, CustomInputProps>(
  ({ value, onClick, ...props }: any, ref: any) => {
    return (
      <Flex
        bg={props.isDisabled ? 'gray.50' : '#ffffff'}
        border="1px solid"
        borderColor="gray.200"
        borderLeftWidth={props.right ? 0 : '1px'}
        borderRadius={props?.right ? '0 6px 6px 0' : '6px 0 0 6px'}
        alignItems="center"
        lineHeight={0}
        px="12px"
        width="100%"
        height="34px"
        _hover={{
          borderColor: 'gray.300',
        }}
        onClick={!props.isDisabled ? onClick : null}
        ref={ref}
      >
        <Tooltip label={value || props?.placeholder} aria-label="Date tooltip">
          <Text
            color={value ? 'black' : 'gray.500'}
            lineHeight="20px"
            overflow="scroll"
            noOfLines={1}
          >
            {value || props?.placeholder}
          </Text>
        </Tooltip>
      </Flex>
    )
  }
)
CustomInput.displayName = 'CustomInput'

export default DateRangeInput
