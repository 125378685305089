import React from 'react'

import { Box, Text, Flex, Spinner, Badge } from '@chakra-ui/react'
import classNames from 'classnames/bind'
import { useSelector } from 'react-redux'

import { IStoreState } from 'src/bootstrap/store/types'
import { ErrorBox } from 'src/core/components'
import { useFetch } from 'src/core/hooks'
import { surveyService, SurveyServiceProps } from 'src/packages/survey'

import { SidebarHeader } from './SidebarHeader'
import styles from './Sidebar.module.scss'
import { FiFileText, FiFolder } from 'react-icons/fi'
import {
  ISurveyTreeForProfile,
  ISurveyVisibility,
} from 'src/packages/survey/survey.type'
import { Link } from 'react-router-dom'
import { route } from 'src/core/helpers'

const cn = classNames.bind(styles)

export const Sidebar: React.FC = () => {
  const { user, auth, config } = useSelector((state: IStoreState) => state)
  const isAuthenticated = !!auth.token
  const sidebarOpen = config.sidebarOpen

  const [surveyTrees, isLoading] = useFetch<
    ISurveyTreeForProfile,
    SurveyServiceProps
  >(surveyService, 'fetchTreeForProfile', [user?._id], !!user._id)

  return (
    <>
      {isAuthenticated && (
        <>
          <aside className={cn({ sidebar: true, open: sidebarOpen })}>
            <SidebarHeader user={user} />

            <Box mt={3} height="100%" overflow="scroll">
              <>
                {/* <Text fontWeight="medium">
                  Recent Projects
                </Text> */}
                <Flex mt={1} px="15px" mb="5px">
                  <Box position="relative" top="1px" color="gray.600">
                    <FiFolder size={16} />
                  </Box>
                  <Box ml="6px">
                    <Link
                      to={route('survey', null, {
                        location_type: '*',
                        location: '*',
                      })}
                    >
                      <Text>All Projects</Text>
                    </Link>

                    {/* subfolders */}
                    {[
                      { vis: ISurveyVisibility.By, label: 'My Projects' },
                      { vis: ISurveyVisibility.For, label: 'Other Projects' },
                      { vis: ISurveyVisibility.Open, label: 'Public Projects' },
                    ].map((folder, index) => (
                      <Flex mt={1} key={index}>
                        <Box position="relative" top="1px" color="gray.600">
                          <FiFolder size={16} />
                        </Box>
                        <Box ml="6px">
                          <Link
                            to={route('survey', null, {
                              visibility: folder.vis,
                              location: '*',
                            })}
                          >
                            <Text>{folder.label}</Text>
                          </Link>
                        </Box>
                      </Flex>
                    ))}
                  </Box>
                </Flex>

                {isLoading ? (
                  <Flex justifyContent="center" py={50}>
                    <Spinner color="primary.600" />
                  </Flex>
                ) : null}

                {!isLoading &&
                surveyTrees &&
                !Object.keys(surveyTrees)?.length ? (
                  <ErrorBox summary="No projects found" my={50} />
                ) : null}

                {!isLoading &&
                surveyTrees &&
                Object.keys(surveyTrees)?.length ? (
                  <>
                    {Object.entries(surveyTrees).map(
                      ([parentIndex, parentTree]) => (
                        <Flex
                          key={parentIndex}
                          borderBottom="1px solid"
                          borderColor="gray.100"
                          py="8px"
                          px="15px"
                        >
                          <Box position="relative" top="1px" color="gray.600">
                            <FiFolder size={16} />
                          </Box>
                          <Box ml="6px">
                            <Link
                              to={route('survey', null, {
                                location: parentTree._id,
                              })}
                            >
                              <Text>{parentTree.name}</Text>
                            </Link>

                            {parentTree.types &&
                              Object.entries(parentTree.types).map(
                                ([childTreeIndex, childTree]) => (
                                  <Flex key={childTreeIndex} mt={1}>
                                    <Box
                                      position="relative"
                                      top="1px"
                                      color="gray.600"
                                    >
                                      <FiFolder size={16} />
                                    </Box>
                                    <Box ml="6px">
                                      <Link
                                        to={route('survey', null, {
                                          location_type: childTreeIndex,
                                          location: '*',
                                        })}
                                      >
                                        <Text textTransform="uppercase">
                                          {childTreeIndex}
                                        </Text>
                                      </Link>

                                      {/* entries - surveys */}
                                      {childTree.entries &&
                                        Object.entries(childTree.entries).map(
                                          ([childSurveyIndex, childSurvey]) => (
                                            <Flex key={childSurveyIndex} mt={1}>
                                              <Box
                                                position="relative"
                                                top="3px"
                                                color="gray.600"
                                              >
                                                <FiFileText size={16} />
                                              </Box>
                                              <Link
                                                to={route('survey', null, {
                                                  location: childSurvey._id,
                                                })}
                                              >
                                                <Text ml="4px">
                                                  {childSurvey.name}{' '}
                                                  <Badge
                                                    colorScheme="gray"
                                                    variant="solid"
                                                  >
                                                    {childSurvey?.surveys
                                                      ?.length || 0}
                                                  </Badge>
                                                </Text>
                                              </Link>
                                            </Flex>
                                          )
                                        )}
                                    </Box>
                                  </Flex>
                                )
                              )}
                          </Box>
                        </Flex>
                      )
                    )}
                  </>
                ) : null}
              </>

              <Box my={100}></Box>
            </Box>
          </aside>
        </>
      )}
    </>
  )
}

export default Sidebar
