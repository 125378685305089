import { Button, Flex } from '@chakra-ui/react'
import React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { route } from 'src/core/helpers'
import { useMounted, useToast } from 'src/core/hooks'
import { inviteService } from '../invite.service'

export const AcceptInviteButton: React.FC<{ invite_id: string }> = ({
  invite_id,
}) => {
  const history = useHistory()
  const isMounted = useMounted()
  const { addToast } = useToast()
  const [isLoading, setLoading] = React.useState<boolean>()
  const setStatus = (): void => {
    setLoading(true)
    inviteService
      .updateStatus({
        _id: invite_id,
        status: 'accepted',
      })
      .then(() => {
        addToast('Successful', { appearance: 'success' })
        history.push(route('survey'))
      })
      .catch((error) => {
        addToast(error.message, { appearance: 'error' })
      })
      .finally(() => {
        isMounted.current && setLoading(false)
      })
  }
  return (
    <Flex pt={5}>
      <Button
        size="sm"
        colorScheme="primary"
        isLoading={isLoading}
        disabled={isLoading}
        onClick={setStatus}
      >
        Accept
      </Button>
      <Link to={route('survey')}>
        <Button
          ml={2}
          size="sm"
          variant="outline"
          colorScheme="gray"
          disabled={isLoading}
        >
          Ignore
        </Button>
      </Link>
    </Flex>
  )
}
